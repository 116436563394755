import { AppShell } from '@mantine/core';
import styles from './Layout.module.scss';
import { EmptyPropsWithChildren } from '../../lib/types';
import { LayoutHeader } from '../LayoutHeader/LayoutHeader';
import { useEffect } from 'react';
import { AccountApi } from '../../api';
import i18n from '../../lib/i18n/i18n';

export const Layout = (props: EmptyPropsWithChildren | any) => {
  useEffect(() => {
    AccountApi.metadata().then((res) =>
      i18n.changeLanguage(res.metadata.language).catch((e) => Error(e)),
    );
  }, []);

  return (
    <AppShell className={styles.Layout} padding="md" header={<LayoutHeader />}>
      {props.children}
    </AppShell>
  );
};
