import styles from '../../components/JobOrder/JobOrderView.module.scss';
import { OptionHeader } from '../OptionHeader/OptionHeader';
import { Grid, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { JobOrderDto } from '../../pages/Tickets/Dto/JobOrderDto';
import { OptionHeaderInfoBox } from '../OptionHeaderInfoBox/OptionHeaderInfoBox';
import { getStatusJobOrderTask } from '../../pages/Tickets/parser/parserStatusJobOrderTask';
import { FooterPrice } from '../FooterPrice/FooterPrice';
import { addedCarriageSeparator } from '../../lib/utils';

interface JobOrder {
  data: JobOrderDto;
  numberOfJobOrder: number;
}

export function JobOrderView({ numberOfJobOrder, data }: JobOrder) {
  const { t } = useTranslation();
  const maxLenghtNote = 66;
  const actNumberOfJobOrder = numberOfJobOrder + 1;

  return (
    <>
      <OptionHeader
        icon={
          data.status && (
            <OptionHeaderInfoBox title={getStatusJobOrderTask(data.status)} />
          )
        }
        title={`${t('JOB_ORDER')} ${actNumberOfJobOrder} - ${data.code}`}
      />
      <Grid mt={'lg'} className={styles.GridSpace}>
        <Grid.Col span={2} mb={'md'}>
          <Text fz={'md'} className={styles.TextTitleJobOrderView}>
            {t('NUMBER_SERIAL')}
          </Text>
          <Text fz={'md'}>{data.serialN}</Text>
        </Grid.Col>
        <Grid.Col span={2} mb={'md'}>
          <Text fz={'md'} className={styles.TextTitleJobOrderView}>
            {t('MODEL')}
          </Text>
          <Text fz={'md'}>{data.model}</Text>
        </Grid.Col>
        <Grid.Col span={2} mb={'md'}>
          <Text fz={'md'} className={styles.TextTitleJobOrderView}>
            {t('INTERVENTION_TYPE')}
          </Text>
          <Text fz={'md'} className={styles.TextInterventionType}>
            {data.jobOrderType}
          </Text>
        </Grid.Col>
        {/*<Grid.Col span={2} mb={'md'}>*/}
        {/*    label={t('FAULT_TYPE')}*/}
        {/*</Grid.Col>*/}
        <Grid.Col span={2} mb={'md'} ml={100}>
          <Text fz={'md'} className={styles.TextTitleJobOrderView}>
            {t('EMAIL_CONTACT_PERSON')}
          </Text>
          <Text fz={'md'}>{data.emailRef}</Text>
        </Grid.Col>
        <Grid.Col span={2} mb={'md'} ml={100}>
          <Text fz={'md'} className={styles.TextTitleJobOrderView}>
            {t('PHONE_CONTACT_PERSON')}
          </Text>
          <Text fz={'md'}>{data.phoneRef}</Text>
        </Grid.Col>
        <Grid.Col span={6} mb={'md'}>
          <Text fz={'md'} className={styles.TextTitleJobOrderView}>
            {t('NOTE')}
          </Text>
          <div className={styles.ShowInTicketDetailNote}>
            {addedCarriageSeparator(data.note, '-', maxLenghtNote)}
          </div>
        </Grid.Col>
        <Grid.Col span={12}>
          {data.FF_Riparazione && (
            <div style={{ paddingBottom: 10 }}>
              <FooterPrice
                title={t('AMOUNT') + ' ' + t('INTERVENTION_TYPE1_REPAIR')}
                iconPrice={data.FF_HST_Repair + ' €'}
              />
            </div>
          )}
          {data.FF_ACCREDIA && (
            <div style={{ paddingBottom: 10 }}>
              <FooterPrice
                title={t('AMOUNT') + ' ' + t('INTERVENTION_TYPE2_CALIBRATION')}
                iconPrice={data.FF_HST_Accredia + ' €'}
              />
            </div>
          )}
          {data.FF_Calibrazione && (
            <FooterPrice
              title={
                t('AMOUNT') + ' ' + t('INTERVENTION_TYPE3_CALIBRATION_REPORT')
              }
              iconPrice={data.FF_HST_RDT_Calibra + ' €'}
            />
          )}
        </Grid.Col>
      </Grid>
    </>
  );
}
