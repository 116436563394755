import { Button, Group, Loader } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TicketApi, TicketDto } from '../../api';
import { Card } from '../../components/Card/Card';
import { useParams } from 'react-router-dom';
import { JobOrderView } from '../../components/JobOrder/JobOrderView';
import { parserJobOrderFromApiModel } from './parser/parserJobOrderTask';
import { OptionHeaderInfoBox } from '../../components/OptionHeaderInfoBox/OptionHeaderInfoBox';
import {
  parserStatusFromApiModel,
  statusCodeCRMOfTicket,
} from './parser/parserStatusTicket';
import { HeaderDetailPreventive } from '../../components/HeaderDetailPreventive/HeaderDetailPreventive';
import { getTotalTaxablePriceJobOrder } from '../../lib/crmUtils';
import { OptionHeader } from '../../components/OptionHeader/OptionHeader';
import { useDownloadItemGenerate } from '../../hooks/useDownloadItemGenerate';
import { TypeOfDownload, fileNameToDownload } from '../../lib/constantCrm';

export function TicketDetail() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    createDownloadItem,
    updateTicketStatusPostDownloadLabel,
    updateJobOrderStatusPostDownloadLabel,
  } = useDownloadItemGenerate();
  const [dataTicket, setDataTicket] = useState<TicketDto>();

  useEffect(() => {
    TicketApi.findOne({ id: id || '' }).then((ticket) => {
      setDataTicket(ticket);
    });
  }, [id]);

  const onDownloadFile = useCallback(
    (dataTicket: TicketDto, typeOfDownload: string) => {
      if (
        typeOfDownload == TypeOfDownload.ESTIMATE_LINK &&
        dataTicket.status !=
          statusCodeCRMOfTicket.AWAITING_ESTIMATE_DOWN_PAYMENT &&
        dataTicket.status != statusCodeCRMOfTicket.ESTIMATE_TO_GENERETE &&
        dataTicket.status != statusCodeCRMOfTicket.OPEN_TICKET &&
        dataTicket.status != statusCodeCRMOfTicket.OBSOLETE
      ) {
        createDownloadItem(
          dataTicket.FF_estimatelink || '',
          fileNameToDownload.TICKET_QUOTE,
        );
      } else if (
        typeOfDownload == TypeOfDownload.RMA_LINK &&
        dataTicket.status == statusCodeCRMOfTicket.RMA_GENERATED
      ) {
        createDownloadItem(
          dataTicket.FF_RMA_link || '',
          fileNameToDownload.TICKET_LABEL,
        );
        updateTicketStatusPostDownloadLabel(dataTicket.id.toString());
        updateJobOrderStatusPostDownloadLabel(dataTicket.jobOrdersTask);
      }
    },
    [],
  );

  return (
    <>
      {dataTicket ? (
        <Card title={t('TICKET') + ' ' + (dataTicket?.ticketId || '')}>
          <>
            {dataTicket.status && (
              <OptionHeaderInfoBox
                title={parserStatusFromApiModel(dataTicket.status)}
              />
            )}
          </>
          <>
            {dataTicket.FF_estimatelink &&
              dataTicket.status !=
                statusCodeCRMOfTicket.AWAITING_ESTIMATE_DOWN_PAYMENT &&
              dataTicket.status != statusCodeCRMOfTicket.ESTIMATE_TO_GENERETE &&
              dataTicket.status != statusCodeCRMOfTicket.OPEN_TICKET &&
              dataTicket.status != statusCodeCRMOfTicket.OBSOLETE && (
                <HeaderDetailPreventive
                  title={t('HEADER_DETAIL_PREVENTIVE_TITLE')}
                  icon={
                    <Button
                      onClick={() =>
                        onDownloadFile(dataTicket, TypeOfDownload.ESTIMATE_LINK)
                      }
                    >
                      {t('HEADER_DETAIL_PREVENTIVE_BUTTON')}
                    </Button>
                  }
                />
              )}
            {dataTicket.FF_RMA_link &&
              dataTicket.status == statusCodeCRMOfTicket.RMA_GENERATED && (
                <HeaderDetailPreventive
                  title={t('HEADER_DETAIL_LABEL_TITLE')}
                  icon={
                    <Button
                      onClick={() =>
                        onDownloadFile(dataTicket, TypeOfDownload.RMA_LINK)
                      }
                    >
                      {t('HEADER_DETAIL_LABEL_BUTTON')}
                    </Button>
                  }
                />
              )}
            {dataTicket?.jobOrdersTask.map((value, index) => (
              <JobOrderView
                key={index}
                data={parserJobOrderFromApiModel(value)}
                numberOfJobOrder={index}
              />
            ))}
            {dataTicket?.jobOrdersTask.length > 0 && (
              <OptionHeader
                title={t('FOOTER_TOTAL_PRICE')}
                icon={
                  getTotalTaxablePriceJobOrder(dataTicket?.jobOrdersTask) + ' €'
                }
              />
            )}
            {/*    <Button
              className={styles.ButtonBackToList}
              onClick={() => navigate(Paths.TICKETS, {})}
            >
              {t('BACK_TO_TICKET_LIST')}
            </Button>*/}
          </>
        </Card>
      ) : (
        <Card title={''}>
          <></>
          <Group position={'center'} m={250}>
            <Loader />
          </Group>
        </Card>
      )}
    </>
  );
}
