import { Title } from '@mantine/core';
import styles from './FooterPrice.module.scss';
import { ReactNode } from 'react';

interface FooterPrice {
  title: string;
  iconPrice?: ReactNode;
}

export function FooterPrice({ title, iconPrice }: FooterPrice) {
  return (
    <>
      <div className={styles.FooterPriceMainBlock}>
        <Title className={styles.FooterPriceTitle}>{title}</Title>
        <div>{iconPrice}</div>
      </div>
    </>
  );
}
