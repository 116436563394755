import { RollingStockDto } from '../../../api';
import { CreateInstrument } from '../dto/CreateInstrument';
import { FreeField, Instrument } from '../dto/Instrument';

type FieldType =
  | 'typeofshop'
  | 'purchasecountry'
  | 'purchasestore'
  | 'assignedto'
  | 'purchaseattach'
  | 'warrcheck'
  | 'enableCheckWarrInServiceRequest';

export function parserInstrumentFromApiModel(
  value: RollingStockDto,
): Instrument {
  const freeFields: Record<FieldType, FreeField> = {
    typeofshop: { freeId: 0, value: '' },
    purchasecountry: { freeId: 0, value: '' },
    purchasestore: { freeId: 0, value: '' },
    assignedto: { freeId: 0, value: '' },
    warrcheck: { freeId: 0, value: '' },
    enableCheckWarrInServiceRequest: { freeId: 0, value: '' },
    purchaseattach: { freeId: 0, value: '' },
  };

  function isFreeField(field: string): field is FieldType {
    return (field as FieldType) !== undefined;
  }

  for (const field of value.freeFieldsRolling) {
    if (isFreeField(field.code)) freeFields[field.code] = field;
  }

  const instrument = {
    id: value.id.toString(),
    updatedAt: value.lastModifiedDate,
    model: value.model || '',
    typeProduct: value.title,
    productId: value.productId,
    serialNumber: value.internalIdentification,
    purchaseDate: new Date(value.warrBuyDate),
    assignedTo: freeFields.assignedto,
    purchasePlace: freeFields.typeofshop,
    purchaseCountry: freeFields.purchasecountry,
    storeAddress: freeFields.purchasestore,
    warrcheck: freeFields.warrcheck,
    enableCheckWarrInServiceRequest: freeFields.enableCheckWarrInServiceRequest,
    purchaseAttach: freeFields.purchaseattach,
  };

  return instrument;
}
export function parseInstrumentToApiModel(values: CreateInstrument) {
  const freeFieldsRolling = [
    { ...values.assignedTo },
    { ...values.purchaseCountry },
    { ...values.purchasePlace },
    { ...values.storeAddress },
    { ...values.warrcheck },
    { ...values.enableCheckWarrInServiceRequest },
    { ...values.purchaseAttach },
  ];

  return {
    active: true,
    internalIdentification: values.serialNumber,
    title: values.typeProduct,
    warrBuyDate: values.purchaseDate,
    freeFieldsRolling,
    productId: values.productId,
  };
}
