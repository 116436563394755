import { Check, X } from 'tabler-icons-react';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';

export function useApiNotification() {
  const { t } = useTranslation();

  function showError(message?: string, showTitle = true) {
    showNotification({
      title: showTitle ? t('generic_api_error_title') : '',
      message: message ?? t('generic_api_error_message'),
      color: 'red',
      icon: <X size={18} />,
    });
  }

  function showSuccess(message?: string) {
    showNotification({
      title: t('generic_api_success_title'),
      message: message ?? t('generic_api_success_message'),
      color: 'green',
      icon: <Check size={18} />,
    });
  }
  return { showSuccess, showError };
}
