import { useEffect, useState } from 'react';
import { isNotBlank, removeLocaleLanguage } from '../../lib/utils';
import { Address } from '../Address/Address';
import { OptionHeader } from '../OptionHeader/OptionHeader';
import { useTranslation } from 'react-i18next';
import { CountriesAndCitiesApi } from '../../api';
import { OptionObject } from '../../lib/types';
import { useCountry } from '../Address/useCountry';

interface AddressProps {
  formAddress: any;
  showButtons?: boolean;
  onCancelAddressBilling?: () => void;
  onCancelAddressShippping?: () => void;
  onSaveAddressBilling?: () => void;
  onSaveAddressShippping?: () => void;
}

export function AddressDispatch({
  formAddress,
  onCancelAddressBilling = () => {},
  onCancelAddressShippping = () => {},
  onSaveAddressBilling = () => {},
  onSaveAddressShippping = () => {},
  showButtons = false,
}: AddressProps) {
  const { t } = useTranslation();
  const { listCountries } = useCountry();

  return (
    <>
      <OptionHeader title={t('ADDRESS_TO_BILLADDRESS')} />
      <Address
        key={'billAddress'}
        listCountries={listCountries}
        fieldName="billAddress"
        formAddress={formAddress}
        enableToUseLikeShippingAddress={true}
        disabledCountry={isNotBlank(formAddress.values.billAddress.state)}
        onCancel={() => onCancelAddressBilling()}
        onSave={() => onSaveAddressBilling()}
        showButtons={showButtons}
        titleConfirmButton={t('CONFIRM_BILL_ADDRESS')}
      />
      {!formAddress.values.isShippingAddress && (
        <>
          <OptionHeader title={t('ADDRESS_TO_SHIPPINGADDRESS')} />
          <Address
            key={'shippingAddress'}
            listCountries={listCountries}
            fieldName="shippingAddress"
            formAddress={formAddress}
            onCancel={() => onCancelAddressShippping()}
            onSave={() => onSaveAddressShippping()}
            showButtons={showButtons}
            titleConfirmButton={t('CONFIRM_SHIPPING_ADDRESS')}
          />
        </>
      )}
    </>
  );
}
