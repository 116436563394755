/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CatalogDto } from '../models/CatalogDto';
import type { GetListCatalogsDto } from '../models/GetListCatalogsDto';
import type { PaginatedCatalogDto } from '../models/PaginatedCatalogDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CatalogsApi {

    /**
     * @returns PaginatedCatalogDto 
     * @throws ApiError
     */
    public static findAll({
search,
size,
page,
}: {
search?: string,
size?: number,
page?: number,
}): CancelablePromise<PaginatedCatalogDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/catalogs',
            query: {
                'search': search,
                'size': size,
                'page': page,
            },
        });
    }

    /**
     * @returns GetListCatalogsDto 
     * @throws ApiError
     */
    public static search({
search,
}: {
search: string,
}): CancelablePromise<GetListCatalogsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/catalogs/search',
            query: {
                'search': search,
            },
        });
    }

    /**
     * @returns CatalogDto 
     * @throws ApiError
     */
    public static findOne({
id,
}: {
id: string,
}): CancelablePromise<CatalogDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/catalogs/{id}',
            path: {
                'id': id,
            },
        });
    }

}
