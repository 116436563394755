import { Navigate } from 'react-router-dom';
import Session from 'supertokens-auth-react/recipe/session';
import { Paths } from '../../hooks/useNavigationLinks';
import { ReactNode } from 'react';

interface AuthProps {
  children?: ReactNode;
}

export function Auth({ children }: AuthProps) {
  const sessionContext = Session.useSessionContext();
  if (sessionContext.loading) {
    return null;
  }
  if (
    sessionContext.doesSessionExist &&
    sessionContext.accessTokenPayload['st-ev'].v
  )
    return children ? <>{children}</> : <Navigate to="/" replace />;
  else return <Navigate to={Paths.SIGNIN} replace />;
}
