import { useEffect, useState } from 'react';
import { CancelablePromise, TicketResponseDto } from '../../api';
import {
  InterventionsTypeEnum,
  QueryTicketStatusMap,
  TicketStatusEnum,
} from './TicketEnums';
import { DateTime } from 'luxon';

export interface OffsetPaginated<T> {
  data: T[];
  total: number;
  page: number;
  size: number;
}

type Ticket = TicketResponseDto[number];

export type FindAllTicketApi = () => CancelablePromise<Ticket[]>;

function useCache(
  effect: () => void,
  beforeInvalidate: () => Promise<void> | void = () => {},
) {
  const [cacheReloadCounter, setCacheReloadCounter] = useState(0);
  useEffect(() => {
    effect();
  }, [cacheReloadCounter]);

  async function invalidateCache() {
    await beforeInvalidate();
    setCacheReloadCounter(cacheReloadCounter + 1);
  }

  return { invalidateCache };
}

export function useGetTickets(api: FindAllTicketApi, size = 10) {
  const [paginatedItems, setPaginatedItems] =
    useState<OffsetPaginated<Ticket> | null>(null);
  const [allItems, setAllItems] = useState<Ticket[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // const [search, setSearch] = useState<string | undefined>(undefined);
  const [jobOrderInterventionType, setJobOrderInterventionType] = useState<
    InterventionsTypeEnum | undefined
  >(undefined);
  const [searchSerialNumber, setSearchSerialNumber] = useState<
    string | undefined
  >(undefined);
  const [filterStatusTicket, setFilterStatusTicket] = useState<
    TicketStatusEnum[]
  >([]);

  async function findAll(page = 1) {
    if (!allItems) {
      setLoading(true);
      try {
        const result = await api();
        setLoading(false);
        setAllItems(result);
        setFilteredItems(result, page);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    } else {
      setFilteredItems(allItems, page);
    }
  }

  function setFilteredItems(items: Ticket[] | null, page: number) {
    if (items) {
      let filteredItems = [...items];
      if (page < 1) page = 1;

      if (searchSerialNumber) {
        filteredItems = filteredItems.filter(
          (t) =>
            t.jobOrdersTask.filter(
              (j) =>
                String(j.internalIdentification)
                  .toLowerCase()
                  .indexOf(searchSerialNumber.toLowerCase()) != -1,
            ).length != 0,
        );
      }
      if (jobOrderInterventionType) {
        filteredItems = filteredItems.filter(
          (t) =>
            t.jobOrdersTask.filter((j) => {
              switch (jobOrderInterventionType) {
                case InterventionsTypeEnum.FF_ACCREDIA:
                  return j.FF_ACCREDIA;
                case InterventionsTypeEnum.FF_Calibrazione:
                  return j.FF_Calibrazione;
                case InterventionsTypeEnum.FF_Riparazione:
                  return j.FF_Riparazione;
                default:
                  return false;
              }
            }).length != 0,
        );
      }
      if (filterStatusTicket && filterStatusTicket.length > 0) {
        filteredItems = filteredItems.filter((t) => {
          for (const f of filterStatusTicket) {
            const statuses: readonly number[] = QueryTicketStatusMap[f];
            if (statuses.includes(t.status)) return true;
          }
          return false;
        });
      } else {
        filteredItems = filteredItems.filter((t) => {
          const statuses: readonly number[] = QueryTicketStatusMap.OBSOLETE;
          return !statuses.includes(t.status);
        });
      }

      const total = Math.ceil(filteredItems.length / size);

      setPaginatedItems({
        data: filteredItems
          .sort(
            (a, b) =>
              DateTime.fromFormat(
                b.lastModifiedDate,
                "yyyy-MM-dd'T'HH:mm:ss",
              ).valueOf() -
              DateTime.fromFormat(
                a.lastModifiedDate,
                "yyyy-MM-dd'T'HH:mm:ss",
              ).valueOf(),
          )
          .slice((page - 1) * size, page * size),
        page: Math.min(page, total),
        size,
        total,
      });
    }
  }

  useEffect(() => {
    setFilteredItems(allItems, page);
  }, [filterStatusTicket, jobOrderInterventionType, searchSerialNumber]);

  const { invalidateCache } = useCache(
    () => findAll(),
    () => setAllItems(null),
  );

  const data = paginatedItems?.data ?? [];
  const page = paginatedItems?.page ?? 1;
  const total = paginatedItems?.total ?? 0;
  return {
    allData: allItems,
    data,
    page,
    size: paginatedItems?.size ?? size,
    total,
    hasNext: page >= total,
    loading,
    findAll,
    invalidateCache,
    next: () => findAll(page + 1),
    prev: () => findAll(page - 1),
    filter: {
      filterStatusTicket,
      jobOrderInterventionType,
      searchSerialNumber,
    },
    setFilterStatusTicket: (values: string[] | null) => {
      const statusValues: TicketStatusEnum[] = [];
      if (values)
        for (const value of values) {
          if (value && TicketStatusEnum[value as TicketStatusEnum])
            statusValues.push(value as TicketStatusEnum);
        }
      setFilterStatusTicket(statusValues);
    },
    setJobOrderInterventionType: (value: string | null) => {
      if (value && InterventionsTypeEnum[value as InterventionsTypeEnum])
        setJobOrderInterventionType(value as InterventionsTypeEnum);
      else setJobOrderInterventionType(undefined);
    },
    setSearchSerialNumber,
  };
}
