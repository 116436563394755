import React from 'react';
export default function PlusIconBlack() {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0.5C7.0522 0.5 6.68924 0.862953 6.68924 1.31075V7.18925H0.810754C0.362953 7.18925 0 7.5522 0 8C0 8.4478 0.362953 8.81075 0.810754 8.81075H6.68924V14.6892C6.68924 15.137 7.0522 15.5 7.5 15.5C7.9478 15.5 8.31075 15.137 8.31075 14.6892V8.81075H14.1892C14.637 8.81075 15 8.4478 15 8C15 7.5522 14.637 7.18925 14.1892 7.18925H8.31075V1.31075C8.31075 0.862953 7.9478 0.5 7.5 0.5Z"
        fill="#616E7B"
      />
    </svg>
  );
}
