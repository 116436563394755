import { useState } from 'react';
import { CancelablePromise } from '../api';

export interface OffsetPaginated<T> {
  data: T[];
  total: number;
  page: number;
  size: number;
}

export type OffsetPaginationQuery = { size?: number; page?: number };
export type OffsetPaginationApi<T> = (
  query: OffsetPaginationQuery,
) => CancelablePromise<OffsetPaginated<T>>;

export function useOffsetPagination<T>(api: OffsetPaginationApi<T>, size = 10) {
  const [paginatedItems, setPaginatedItems] =
    useState<OffsetPaginated<T> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  async function findAll(page = 1) {
    setLoading(true);
    try {
      const result = await api({ page, size });
      setLoading(false);
      setPaginatedItems(result);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const data = paginatedItems?.data ?? [];
  const page = paginatedItems?.page ?? 1;
  const total = paginatedItems?.total ?? 0;
  return {
    data,
    page,
    size: paginatedItems?.size ?? size,
    total,
    hasNext: page >= total,
    loading,
    findAll,
    next: () => findAll(page + 1),
    prev: () => findAll(page - 1),
  };
}
