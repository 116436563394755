import { Title } from '@mantine/core';
import styles from './OptionHeaderInfoBox.module.scss';
import { ReactNode } from 'react';

interface OptionHeaderInfoBox {
  title: string;
}

export function OptionHeaderInfoBox({ title }: OptionHeaderInfoBox) {
  return (
    <>
      <div className={styles.MainBlock}>
        <Title className={styles.OptionHeaderInfoBoxTitle}>{title}</Title>
      </div>
    </>
  );
}
