import React, { ReactNode, useState } from 'react';
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  useMantineTheme,
} from '@mantine/core';
import { Selector, ChevronDown, ChevronUp } from 'tabler-icons-react';
import { KeyOf, ValueOf } from '../../lib/types';
import styles from './Table.module.scss';
import { NoInstrumentsBox } from '../NoInstrumentsBox/NoInstrumentsBox';
import { useTranslation } from 'react-i18next';
import { Loader } from '../Loader/Loader';

export interface Header<T> {
  displayName?: ReactNode;
  field: KeyOf<T>;
  renderHeader?: (value: KeyOf<T>) => ReactNode;
  renderCell?: (
    value: ValueOf<T>,
    field: keyof T,
    row: T,
  ) => ReactNode | any | boolean;
  renderNested?: (value: T) => ReactNode | any | boolean;
}

interface Props<T> {
  isSorted?: boolean;
  headers?: Header<T>[];
  data: T[];
  numberOfSkeleton?: number;
  loading?: boolean;
  tableType?: string;
}

interface ThProps {
  children: ReactNode;
  isSorted: boolean;
  reversed?: boolean;
  sorted?: boolean;
  onSort(): void;
}

function Th({ children, isSorted = false, reversed, sorted, onSort }: ThProps) {
  const Icon = sorted ? (reversed ? ChevronUp : ChevronDown) : Selector;
  const theme = useMantineTheme();

  return (
    <th className={styles.control}>
      <UnstyledButton onClick={onSort}>
        <Group noWrap={false}>
          <Text weight={500} size="xs">
            {children}
          </Text>
          {isSorted && (
            <Center className={styles.icon}>
              <Icon size={14} stroke="1.5" fill={theme.colors.dark[9]} />
            </Center>
          )}
        </Group>
      </UnstyledButton>
    </th>
  );
}

export function TableSort<T>({
  data,
  headers,
  isSorted = false,
  numberOfSkeleton = 10,
  loading,
  tableType,
}: Props<T>) {
  const { t } = useTranslation();
  const tableHeaders = getTableHeadersOrDefault(headers);
  // const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState<keyof T | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  function getTableHeadersOrDefault<T>(headers?: Header<T>[]): Header<T>[] {
    if (headers) return headers;
    if (data.length == 0) return [];

    const tableHeaders: Header<T>[] = [];

    for (const field in data[0]) {
      tableHeaders.push({
        field: field as unknown as keyof T,
        displayName: field,
      });
    }

    return tableHeaders;
  }

  return (
    <>
      <ScrollArea mih={520}>
        <Table className={styles.Table}>
          <thead className={styles.HeaderTable}>
            <tr>
              {tableHeaders.map((header, i) => {
                const { field, displayName, renderHeader } = header;
                return (
                  <Th
                    key={String(field) + i}
                    isSorted={isSorted}
                    sorted={sortBy === field || isSorted}
                    reversed={reverseSortDirection}
                    onSort={() => console.log('Sort')}
                  >
                    {renderHeader
                      ? renderHeader(field)
                      : displayName ?? String(field)}
                  </Th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td colSpan={headers?.length}>
                  <Loader numberOfSkeleton={numberOfSkeleton} />
                </td>
              </tr>
            ) : data.length > 0 ? (
              data.map((row, i) => (
                <React.Fragment key={i}>
                  <tr key={i} className={styles.RowDataTable}>
                    {tableHeaders.map((header, j) => {
                      const { field, renderCell } = header;
                      return (
                        <td key={`${i}_${j}`}>
                          {renderCell
                            ? renderCell(row[field], field, row)
                            : String(row[field])}
                        </td>
                      );
                    })}
                  </tr>
                  {tableHeaders
                    .filter((x) => x.field == 'actions')
                    .map((header, index) => {
                      const { renderNested } = header;
                      {
                        return (
                          <React.Fragment key={index}>
                            {renderNested ? renderNested(row) : null}
                          </React.Fragment>
                        );
                      }
                    })}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={headers?.length}>
                  <NoInstrumentsBox
                    title={
                      tableType?.includes('ticket')
                        ? t('INFORMATION_BOX_TICKETS_TITLE')
                        : t('INFORMATION_BOX_INSTRUMENTS_TITLE')
                    }
                    subtitle={
                      tableType?.includes('ticket')
                        ? t('INFORMATION_BOX_TICKETS_SUBTITLE')
                        : t('INFORMATION_BOX_INSTRUMENTS_SUBTITLE')
                    }
                  ></NoInstrumentsBox>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollArea>
    </>
  );
}
