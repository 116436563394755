import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import { constants } from '../../constants';
import i18n from '../i18n/i18n';
import { supertokensTheme } from '../theme/theme';
import { Paths } from '../../hooks/useNavigationLinks';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';

// Note: if using axios, you need to add an interceptor.
// See https://supertokens.com/docs/emailpassword/quick-setup/frontend

SuperTokens.init({
  languageTranslations: {
    translationFunc: (...args) => i18n.t(...args),
  },
  appInfo: {
    appName: process.env.REACT_APP_NAME || '',
    apiDomain:
      process.env.REACT_APP_BACKEND_URL || constants.DEFAULT_BACKEND_URL,
    websiteDomain: process.env.PUBLIC_URL || constants.DEFAULT_WEB_URL,
    apiBasePath: '/api/v1/auth',
    websiteBasePath: '/auth',
  },
  recipeList: [
    EmailVerification.init({
      useShadowDom: false,
      palette: supertokensTheme.colors,
      mode: 'OPTIONAL',
      disableDefaultUI: true,
    }),
    EmailPassword.init({
      palette: supertokensTheme.colors,
      useShadowDom: false,
      signInAndUpFeature: {
        disableDefaultUI: true,
      },
    }),
    Session.init({}),
  ],
});
