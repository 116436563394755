import { Title } from '@mantine/core';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LayoutAuth.module.scss';

interface LayoutAuthProps {
  children?: ReactNode;
}

export function LayoutAuth({ children }: LayoutAuthProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.LayoutAuth}>
      <div className={styles.ContainerLeft}>
        <div className={styles.HeaderLogo}>
          <img className={styles.Logo} src="logo.jpg" alt={'ht logo'} />
        </div>
        <div className={styles.ContainerFrom}>{children}</div>
      </div>
      <div className={styles.ContainerRight}>
        <Title className={styles.TitleCover}>{t('Title_Cover')}</Title>
      </div>
    </div>
  );
}
