import { z } from 'zod';
import { useForm } from '@mantine/form';
import { CompaniesApi, UpdateCompanyDto } from '../../api';
import { useApiNotification } from '../../hooks/useApiNotification';
import { parserInfoAddressToCompanyDto } from './parser/parserAddressDispatch';
import { useAccount } from '../../hooks/useAccount';
import { isBlank } from '../../lib/utils';
import { useTranslation } from 'react-i18next';

export const AddressZ = z.object({
  state: z.string(),
  address: z.string(),
  city: z.string(),
  province: z.string(),
  labelProvince: z.string(),
  zipCode: z.string(),
});

export const AddressDispatchZ = z.object({
  billAddress: AddressZ,
  isShippingAddress: z.boolean(),
  FF_GT_SYNC: z.boolean().optional(),
  shippingAddress: AddressZ,
});

export const InfoAddressZ = z.object({
  infoAddress: AddressZ,
  isShippingAddress: z.boolean(),
  FF_GT_SYNC: z.boolean().optional(),
});

export type AddressDispatch = z.infer<typeof AddressDispatchZ>;
export type InfoAddress = z.infer<typeof InfoAddressZ>;

export function useUpdateAddressDispatch() {
  const { account } = useAccount();
  const { showSuccess, showError } = useApiNotification();
  const { t } = useTranslation();

  const form = useForm<InfoAddress>({
    validate: {
      infoAddress: {
        state: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
        address: (value) =>
          isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null,
        city: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
        province: (value) =>
          isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null,
        zipCode: (value) =>
          isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null,
      },
    },

    initialValues: {
      infoAddress: {
        state: '',
        address: '',
        city: '',
        province: '',
        labelProvince: '',
        zipCode: '',
      },
      isShippingAddress: false,
      FF_GT_SYNC: false,
    },
  });

  async function handleSubmit(
    values: InfoAddress,
    typeAddress: string,
  ): Promise<{ success: boolean }> {
    const changedField: UpdateCompanyDto = parserInfoAddressToCompanyDto(
      values,
      typeAddress,
    );

    try {
      await CompaniesApi.update({
        id: String(account?.companyId) || '0',
        requestBody: changedField,
      });
      showSuccess();
      return { success: true };
    } catch (error) {
      showError();
      return { success: false };
    }
  }

  return {
    form,
    onSubmit: form.onSubmit,
    handleSubmit,
  };
}
