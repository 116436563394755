import { CreateJobOrderTaskDto } from '../Dto/CreateJobOrderTaskDto';
import { JobOrderTaskDto } from '../../../api';
import { JobOrderDto } from '../Dto/JobOrderDto';
import { CreateJobOrder } from '../useUpsetJobOrder';
import { getLabelJobOrderType } from '../../../lib/crmUtils';
import { CreateJobOrderQuoteDto } from '../Dto/CreateJobOrderQuoteDto';
import { t } from 'i18next';
import { statusCodeCRMOfJobOrder } from './parserStatusJobOrderTask';

export function parserCreateJobOrderToApiModel(
  value: CreateJobOrder,
): CreateJobOrderTaskDto | JobOrderTaskDto {
  return {
    ticketId: value.ticketId,
    status: statusCodeCRMOfJobOrder.QUOTE_NOT_ACCEPTED,
    rollingStockIds: [value.rollingStockId || 0],
    FF_Calibrazione: value.jobOrderType.FF_Calibrazione,
    FF_Riparazione: value.jobOrderType.FF_Riparazione,
    FF_ACCREDIA: value.jobOrderType.FF_ACCREDIA,
    FF_assigneeemail: value.emailRef,
    FF_assigneephone: value.phoneRef,
    FF_DIFCHIAR: value.note,
    FF_HST_Repair: value.jobOrderType.FF_Riparazione ? value.repairPrice : 0,
    FF_HST_Accredia: value.jobOrderType.FF_ACCREDIA
      ? value.calibrationPrice
      : 0,
    FF_HST_RDT_Calibra: value.jobOrderType.FF_Calibrazione
      ? value.calibrationReportPrice
      : 0,
    internalIdentification: value.serialN,
    purchaseAttach: {
      deleted: false,
      value: '',
    },
    interventionAttach: value.interventionAttach,
  };
}

export function parserJobOrderFromApiModel(
  value: JobOrderTaskDto,
): JobOrderDto {
  return {
    code: value.code,
    emailRef: value.FF_assigneeemail,
    phoneRef: value.FF_assigneephone,
    faultType: '',
    jobOrderType: getLabelJobOrderType(value),
    model: value.model || '',
    rollingStockId: value.rollingStockIds[0] || 0,
    serialN: value.internalIdentification || '',
    status: value.status,
    FF_Calibrazione: value.FF_Calibrazione,
    FF_Riparazione: value.FF_Riparazione,
    FF_ACCREDIA: value.FF_ACCREDIA,
    FF_HST_Repair: value.FF_HST_Repair || 0,
    FF_HST_Accredia: value.FF_HST_Accredia || 0,
    FF_HST_RDT_Calibra: value.FF_HST_RDT_Calibra || 0,
    note: value.FF_DIFCHIAR,
  };
}

export function parserJobOrderToQuoteCustom(
  value: JobOrderTaskDto,
): CreateJobOrderQuoteDto {
  return {
    jobOrderNumber: value.code,
    serialNumber: value.internalIdentification || '',
    model: value.model || '',
    jobOrderType: {
      repair: value.FF_Riparazione ? t('INTERVENTION_TYPE1_REPAIR') : '',
      calibration: value.FF_ACCREDIA ? t('INTERVENTION_TYPE2_CALIBRATION') : '',
      calibrationReport: value.FF_Calibrazione
        ? t('INTERVENTION_TYPE3_CALIBRATION_REPORT')
        : '',
    },
    repairPrice: value.FF_HST_Repair?.toFixed(2) + ' €',
    calibrationPrice: value.FF_HST_Accredia?.toFixed(2) + ' €',
    calibrationReportPrice: value.FF_HST_RDT_Calibra?.toFixed(2) + ' €',
    percentIva: '22',
  };
}
