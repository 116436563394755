import React from 'react';

export default function Emoticon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2713 7.517L14.6174 2.16879C15.0948 1.69063 15.0948 0.91215 14.6171 0.433995C14.1538 -0.0292783 13.3456 -0.0292783 12.8823 0.433995L7.53616 5.78221L2.11175 0.358103C1.63428 -0.119368 0.856278 -0.119368 0.377438 0.358103C-0.101073 0.836257 -0.101073 1.61474 0.377438 2.09289L5.80154 7.5173L0.415236 12.906C-0.0632755 13.3841 -0.0632755 14.1626 0.415236 14.6408C0.646587 14.8727 0.954671 15 1.28274 15C1.60976 15 1.91785 14.8727 2.15024 14.641L7.53654 9.25262L12.8821 14.5985C13.1141 14.8295 13.4222 14.957 13.7492 14.957C14.0763 14.957 14.3844 14.8297 14.6169 14.598C15.0942 14.1199 15.0942 13.3414 14.6165 12.8632L9.2713 7.517Z"
        fill="#616E7B"
      />
    </svg>
  );
}
