import { useDownloadItemGenerate } from '../../hooks/useDownloadItemGenerate';
import { Button, Menu } from '@mantine/core';
import styles from './Tickets.module.scss';
import { Dots } from 'tabler-icons-react';
import { statusCodeCRMOfTicket } from './parser/parserStatusTicket';
import { fileNameToDownload } from '../../lib/constantCrm';
import { Ticket } from './Tickets';
import { useTranslation } from 'react-i18next';
import { JobOrderDto } from './Dto/JobOrderDto';
import { JobOrderTaskDto } from '../../api';

export const MenuChevronDown = ({ ticket }: { ticket: Ticket }) => {
  const { t } = useTranslation();
  const {
    createDownloadItem,
    updateTicketStatusPostDownloadLabel,
    updateJobOrderStatusPostDownloadLabel,
  } = useDownloadItemGenerate();

  return (
    <Menu shadow="md" width={200} zIndex={200}>
      <Menu.Target>
        <Button className={styles.ButtonIconMenu}>
          <Dots size={24} strokeWidth={2} color={'black'} />
        </Button>
      </Menu.Target>
      {(ticket.FF_estimatelink || ticket.FF_RMA_link) && (
        <Menu.Dropdown>
          {ticket.FF_estimatelink &&
            ticket.status !=
              statusCodeCRMOfTicket.AWAITING_ESTIMATE_DOWN_PAYMENT &&
            ticket.status != statusCodeCRMOfTicket.ESTIMATE_TO_GENERETE &&
            ticket.status != statusCodeCRMOfTicket.OPEN_TICKET &&
            ticket.status != statusCodeCRMOfTicket.OBSOLETE && (
              <Menu.Item
                className={styles.ButtonDot}
                onClick={() => {
                  createDownloadItem(
                    ticket.FF_estimatelink || '',
                    fileNameToDownload.TICKET_QUOTE,
                  );
                }}
              >
                {t('HEADER_DETAIL_PREVENTIVE_BUTTON')}
              </Menu.Item>
            )}
          {ticket.FF_RMA_link &&
            ticket.status == statusCodeCRMOfTicket.RMA_GENERATED && (
              <Menu.Item
                className={styles.ButtonDot}
                onClick={() => {
                  createDownloadItem(
                    ticket.FF_RMA_link || '',
                    fileNameToDownload.TICKET_LABEL,
                  );
                  updateTicketStatusPostDownloadLabel(ticket.id.toString());
                  updateJobOrderStatusPostDownloadLabel(ticket.jobOrdersTask);
                }}
              >
                {t('HEADER_DETAIL_LABEL_BUTTON')}
              </Menu.Item>
            )}
        </Menu.Dropdown>
      )}
    </Menu>
  );
};
