import { useEffect, useState } from 'react';

export function useSearch(refreshCallback: () => void) {
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [jobOrderInterventionType, setJobOrderInterventionType] = useState<
    string | undefined
  >(undefined);
  const [searchSerialNumber, setSearchSerialNumber] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    refreshCallback();
  }, [search, jobOrderInterventionType, searchSerialNumber]);
  return {
    search,
    setSearch,
    jobOrderInterventionType,
    setJobOrderInterventionType,
    setSearchSerialNumber,
    searchSerialNumber,
  };
}
