import React from 'react';

export default function DeleteJobOrderIcon() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.21846 13.3843C4.53422 13.3843 4.80265 13.1159 4.80265 12.8001V4.4001C4.80265 4.08434 4.53424 3.81592 4.21846 3.81592C3.90268 3.81592 3.63428 4.08433 3.63428 4.4001V12.8001C3.63428 13.1159 3.88693 13.3843 4.21846 13.3843Z"
        fill="#616E7B"
      />
      <path
        d="M6.49214 13.3843C6.80791 13.3843 7.07633 13.1159 7.07633 12.8001V4.4001C7.07633 4.08434 6.80792 3.81592 6.49214 3.81592C6.17637 3.81592 5.90796 4.08433 5.90796 4.4001V12.8001C5.90796 13.1159 6.17637 13.3843 6.49214 13.3843Z"
        fill="#616E7B"
      />
      <path
        d="M8.76583 13.3843C9.08159 13.3843 9.35001 13.1159 9.35001 12.8001V4.4001C9.35001 4.08434 9.0816 3.81592 8.76583 3.81592C8.45005 3.81592 8.18164 4.08433 8.18164 4.4001V12.8001C8.18164 13.1159 8.45005 13.3843 8.76583 13.3843Z"
        fill="#616E7B"
      />
      <path
        d="M12.2079 1.62111H8.82896L8.21317 0.752658C8.10259 0.594717 7.91314 0.5 7.72372 0.5H5.27631C5.08687 0.5 4.89734 0.59472 4.78687 0.752658L4.17107 1.62111H0.776324C0.460561 1.62111 0.192139 1.88952 0.192139 2.2053C0.192139 2.52107 0.460547 2.78948 0.776324 2.78948H1.23416V14.079C1.23416 14.8527 1.86569 15.5 2.65517 15.5H10.3447C11.1184 15.5 11.7657 14.8685 11.7657 14.079L11.7658 2.78948H12.2236C12.5394 2.78948 12.8078 2.52107 12.8078 2.2053C12.8079 1.88953 12.5238 1.62111 12.2079 1.62111H12.2079ZM10.5816 14.0791C10.5816 14.2211 10.471 14.3317 10.3289 14.3317L2.65522 14.3316C2.51314 14.3316 2.40257 14.221 2.40257 14.0789V2.78943H10.5815L10.5816 14.0791Z"
        fill="#616E7B"
      />
    </svg>
  );
}
