import { Title } from '@mantine/core';
import styles from './HeaderAuth.module.scss';

interface WelcomeHeaderProps {
  title?: string;
  subTitle?: string;
  link?: string;
  textLink?: string;
}

export function HeaderAuth({
  title,
  subTitle,
  link,
  textLink,
}: WelcomeHeaderProps) {
  return (
    <div className={styles.HeaderAuth}>
      <Title className={styles.HeaderTitle}>{title}</Title>
      <div className={styles.HeaderSubtitle}>
        {subTitle}
        <a href={link} className={styles.SubTitle}>
          {textLink}
        </a>
      </div>
    </div>
  );
}
