// export enum QueryTicketStatus {
//   OPEN_TICKET = 'status eq 35 or status eq 33 or status eq 18',
//   /*Open ticket query with all status except: SHIPPED and OBSOLETE*/
//   OPEN_TICKET_WITH_ALL_STATUS_EXCEPT_SHIPPED_AND_OBSOLETE = 'status eq 35 or status eq 33 or status eq 18' +
//     ' or ' +
//     'status eq 34 or status eq 28 or status eq 28 or status eq 29 or status eq 30 or status eq 36 or status eq 31 or status eq 42 or status eq 43 or status eq 46',
//   RMA_TO_GENERATE = 'status eq 34',
//   WAITING_RMA = 'status eq 45',
//   AWAITING_ARRIVAL = 'status eq 28',
//   IN_PROCESS = 'status eq 29 or status eq 30 or status eq 36 or status eq 31' /*IN LAVORAZIONE*/,
//   WAITING_FOR_PAYMENT = 'status eq 42',
//   WAITING_FOR_SHIPMENT = 'status eq 43',
//   SHIPPED = 'status eq 32' /*Spedito o chiuso*/,
//   OBSOLETE = 'status eq 41' /*Obsoleto*/,
//   GENERATED_RMA = 'status eq 46' /*Rma generato*/,
//   DEFAULT = '',
// }

export enum InterventionsTypeEnum {
  FF_ACCREDIA = 'FF_ACCREDIA',
  FF_Riparazione = 'FF_Riparazione',
  FF_Calibrazione = 'FF_Calibrazione',
}
export enum TicketStatusEnum {
  OPEN_TICKET = 'OPEN_TICKET',
  WAITING_RMA = 'WAITING_RMA',
  GENERATED_RMA = 'GENERATED_RMA',
  AWAITING_ARRIVAL = 'AWAITING_ARRIVAL',
  IN_PROCESS = 'IN_PROCESS',
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
  WAITING_FOR_SHIPMENT = 'WAITING_FOR_SHIPMENT',
  SHIPPED = 'SHIPPED',
}

export const QueryTicketStatusMap = {
  [TicketStatusEnum.OPEN_TICKET]: [35, 33, 18],
  [TicketStatusEnum.WAITING_RMA]: [45],
  [TicketStatusEnum.AWAITING_ARRIVAL]: [28],
  [TicketStatusEnum.IN_PROCESS]: [29, 30, 36, 31],
  [TicketStatusEnum.WAITING_FOR_PAYMENT]: [42],
  [TicketStatusEnum.WAITING_FOR_SHIPMENT]: [43],
  [TicketStatusEnum.SHIPPED]: [32],
  [TicketStatusEnum.GENERATED_RMA]: [46],
  /*Open ticket query with all status except: SHIPPED and OBSOLETE*/
  OPEN_TICKET_WITH_ALL_STATUS_EXCEPT_SHIPPED_AND_OBSOLETE: [
    35, 33, 18, 34, 28, 28, 29, 30, 36, 31, 42, 43, 46,
  ],
  RMA_TO_GENERATE: [34],
  OBSOLETE: [41],
  DEFAULT: [],
} as const;
