import { useNavigate } from 'react-router-dom';
import { verifyEmail } from 'supertokens-auth-react/recipe/emailverification';
import { Paths } from '../../hooks/useNavigationLinks';
import { useEffect, useRef } from 'react';

export function ConfirmVerifyForm() {
  const navigator = useNavigate();
  const useEffectOnce = useRef(false);

  useEffect(() => {
    if (useEffectOnce.current) return;
    confirmVerifyFrom();
    useEffectOnce.current = true;
  }, [useEffectOnce]);

  async function confirmVerifyFrom() {
    try {
      const response = await verifyEmail();
      if (response.status === 'OK') {
        navigator(Paths.INSTRUMENTS);
      } else navigator(Paths.SIGNIN);
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        console.log(err.message);
        navigator(Paths.SIGNIN);
      } else {
        console.log(err.message);
        navigator(Paths.SIGNIN);
      }
    }
  }

  return <></>;
}
