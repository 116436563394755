import React from 'react';

export default function Emoticon() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.5111 24.8889C25.1568 24.8889 23.2 26.8457 23.2 29.2C23.2 31.5543 25.1568 33.5111 27.5111 33.5111C29.8654 33.5111 31.8222 31.5543 31.8222 29.2C31.8222 26.8457 29.8654 24.8889 27.5111 24.8889Z"
        fill="#616E7B"
      />
      <path
        d="M52.4889 24.8889C50.1346 24.8889 48.1778 26.8457 48.1778 29.2C48.1778 31.5543 50.1346 33.5111 52.4889 33.5111C54.8432 33.5111 56.8 31.5543 56.8 29.2C56.8 26.8457 54.8432 24.8889 52.4889 24.8889Z"
        fill="#616E7B"
      />
      <path
        d="M40 0C17.9349 0 0 17.9349 0 40C0 62.0651 17.9349 80 40 80C62.0651 80 80 62.0651 80 40C80 17.9349 62.0651 0 40 0ZM40 4.85302C59.4381 4.85302 75.1476 20.5617 75.1476 40.0006C75.1476 59.4387 59.4389 75.1483 40 75.1483C20.5619 75.1483 4.85238 59.4395 4.85238 40.0006C4.85238 20.5625 20.5611 4.85302 40 4.85302Z"
        fill="#616E7B"
      />
      <path
        d="M40 45.9698C32.6295 45.9841 25.2294 49.3484 20.8619 56.1354C20.0081 57.4623 20.1532 59.1532 21.3616 59.8421C22.642 60.5725 24.1023 60.0535 24.8897 58.8438C28.2969 53.6094 34.1507 51.0029 40.0007 50.9879C45.8513 50.9737 51.7045 53.5809 55.1116 58.8152C55.8991 60.0249 57.3586 60.5717 58.6397 59.842C59.8488 59.1525 59.9939 57.4338 59.1395 56.1069C54.7712 49.3193 47.3711 45.9557 40.0014 45.97L40 45.9698Z"
        fill="#616E7B"
      />
    </svg>
  );
}
