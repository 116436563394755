import {
  Modal,
  Select,
  TextInput,
  Button,
  Grid,
  Checkbox,
  Autocomplete,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotBlank, orderCountry } from '../../lib/utils';
import styles from '../../pages/Instruments/Instruments.module.scss';
import { useUpsetInstrument } from './useUpsetInstument';
import { useFileInput } from '../../hooks/useFileInput';
import { CatalogsApi, StreetAndStoreApi } from '../../api';
import {
  getFilterMatchCatalog,
  getNameFile,
  isAvailableRollingStock,
} from '../../lib/crmUtils';
import { constants } from '../../constants';
import { UploadFile } from '../../components/UploadFile/UploadFile';
import { OptionObject } from '../../lib/types';
import { PurchasePlace } from '../../lib/constantCrm';
import { Search } from 'tabler-icons-react';
import 'dayjs/locale/it';
import 'dayjs/locale/en';
import { useCountry } from '../../components/Address/useCountry';

export interface Props {
  opened: boolean;
  onClose: () => void;
  onSave: () => void;
  instrumentId: string | any;
}

type OptionDropDownModel = {
  value: string;
  label: string;
  productid: number;
  productname: string;
};

export function InstrumentsModal({
  opened = false,
  onClose = () => {},
  onSave = () => {},
  instrumentId,
}: Props) {
  const { t } = useTranslation();
  const { onSubmit, handleSubmitInstrument, form, loading } =
    useUpsetInstrument(instrumentId);
  const { fileErrorMessage, isValidFile, clearInput } = useFileInput();
  const [stores, setStore] = useState<OptionObject[]>([]);
  const [models, setModels] = useState<OptionDropDownModel[]>([]);
  const [typedModel, setTypedModel] = useState('');
  const todayDate = new Date();
  const { listCountries } = useCountry();

  const onTypeModel = useCallback(() => {
    let searchValue = '';
    if (isNotBlank(typedModel)) {
      searchValue = getFilterMatchCatalog('code', typedModel.toUpperCase());
      CatalogsApi.search({ search: searchValue })
        .then((response) => {
          const mod = [];
          for (const catalog of response.data) {
            if (
              catalog.FF_GT06_GRSTAT4 &&
              isAvailableRollingStock(catalog.FF_GT06_GRSTAT4)
            ) {
              mod.push({
                value: catalog.code,
                label: catalog.code,
                productid: catalog.id,
                productname: catalog.productName,
              });
            }
          }
          setModels(mod);
        })
        .catch((error) => console.log(error));
    } else {
      if (models.length > 0) setModels([]);
    }
  }, [setModels, typedModel]);

  useEffect(() => {
    const getOptions = setTimeout(() => {
      onTypeModel();
    }, constants.SEARCH_TIMESHOLD_MS);

    return () => clearTimeout(getOptions);
  }, [onTypeModel]);

  const onChangeModel = useCallback(
    (value: string) => {
      const mod = models.filter((item) => item.value == value);
      if (isNotBlank(value)) {
        form.setFieldValue('model', mod[0].value || '');
        form.setFieldValue('typeProduct', mod[0].productname);
        form.setFieldValue('productId', mod[0].productid);
      } else {
        form.setFieldValue('model', '');
        form.setFieldValue('typeProduct', '');
        form.setFieldValue('productId', 0);
      }
    },
    [form, models],
  );

  useEffect(() => {
    const insertModel = models.filter((x) => x.value === form.values.model);
    if (insertModel.length === 0 && isNotBlank(form.values.model)) {
      models.push({
        value: form.values.model,
        label: form.values.model,
        productid: form.values.productId,
        productname: form.values.typeProduct,
      });
      setModels([...models]);
    }
  }, [form, setModels, models]);

  const onSearchStreetAndStore = useCallback(() => {
    if (isNotBlank(form.values.storeAddress.value)) {
      const search = { search: form.values.storeAddress.value };
      StreetAndStoreApi.findAll(search).then((stores) => {
        setStore(stores);
      });
    } else {
      setStore([]);
    }
  }, [form.values.storeAddress.value]);

  useEffect(() => {
    const getStreetAndStore = setTimeout(() => {
      onSearchStreetAndStore();
    }, constants.SEARCH_TIMESHOLD_MS);

    return () => clearTimeout(getStreetAndStore);
  }, [onSearchStreetAndStore]);

  /*****************************
   * FILE METHOD
   ****************************/
  const removeFile = useCallback(() => {
    clearInput;
    form.setFieldValue('purchaseAttach.deleted', true);
    form.setFieldValue('filePurchaseAttach', undefined);
  }, [form, clearInput]);

  const onChangeFile = useCallback(
    (value: File | null) => {
      if (value != null) {
        if (isValidFile(value)) {
          form.setFieldValue('filePurchaseAttach', value);
        }
      }
    },
    [form, isValidFile],
  );

  const attachedFile = useMemo(() => {
    const nameFile = form.values.filePurchaseAttach;
    if (nameFile) return nameFile;
    else if (
      !form.values.purchaseAttach.deleted &&
      isNotBlank(form.values.purchaseAttach.value)
    ) {
      return new File([], getNameFile(form.values.purchaseAttach.value || ''));
    }
    return null;
  }, [form.values.purchaseAttach, form.values.filePurchaseAttach]);

  return (
    <Modal
      size={'xl'}
      opened={opened}
      onClose={() => {
        onClose();
        form.reset();
      }}
      title={!instrumentId ? t('ADD_INSTRUMENT') : t('EDIT_INSTRUMENT')}
    >
      <form
        onSubmit={onSubmit(async (values) => {
          const { success } = await handleSubmitInstrument(values);
          if (success) {
            await onSave();
          }
        })}
      >
        <Grid mt={'lg'}>
          <Grid.Col span={3} mb={'md'}>
            <Select
              label={t('MODEL')}
              searchable
              //nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
              placeholder={t('SELECT_MODEL')}
              title={t('SELECT_MODEL')}
              data={models}
              {...form.getInputProps('model')}
              clearable={true}
              onChange={onChangeModel}
              onSearchChange={setTypedModel}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col span={3} mb={'md'}>
            <TextInput
              label={t('TYPE_PRODUCT')}
              placeholder={t('TYPE_PRODUCT')}
              disabled={true}
              {...form.getInputProps('typeProduct')}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col span={3} mb={'md'}>
            <TextInput
              label={t('SERIAL_NUMBER')}
              placeholder={t('INSERT_SERIAL_NUMBER')}
              title={t('INSERT_SERIAL_NUMBER')}
              {...form.getInputProps('serialNumber')}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col span={3} mb={'md'}>
            <TextInput
              label={t('ASSIGNED_TO')}
              placeholder={t('INSERT_ASSIGNED_TO')}
              title={t('INSERT_ASSIGNED_TO')}
              {...form.getInputProps('assignedTo.value')}
            />
          </Grid.Col>
          <Grid.Col span={3} mb={'md'}>
            <DatePicker
              label={t('PURCHASE_DATE')}
              locale={t('LANGUAGE_DATE').substring(0, 2)}
              inputFormat="DD/MM/YYYY"
              labelFormat="DD/MM/YYYY"
              placeholder={t('DATE_FORMAT')}
              maxDate={todayDate}
              {...form.getInputProps('purchaseDate')}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col span={3} mb={'md'}>
            <Select
              label={t('PURCHASE_PLACE')}
              placeholder={t('INSERT_PURCHASE_PLACE')}
              title={t('INSERT_PURCHASE_PLACE')}
              data={[
                {
                  value: PurchasePlace.E_COMMERCE,
                  label: t('PLACE_OF_PURCHASE_ECOMMERCE'),
                },
                {
                  value: PurchasePlace.NEGOZIO_FISICO,
                  label: t('PLACE_OF_PURCHASE_STORE'),
                },
              ]}
              {...form.getInputProps('purchasePlace.value')}
              withAsterisk
              onChange={(val) => {
                val == PurchasePlace.E_COMMERCE &&
                  form.clearFieldError('storeAddress.value');
                if (val != form.values.purchasePlace.value) {
                  setStore([]);
                  form.setFieldValue('storeAddress.value', '');
                }
                form.setFieldValue('purchasePlace.value', val);
              }}
            />
          </Grid.Col>
          <Grid.Col span={6} mb={'md'}>
            <Select
              label={t('PURCHASE_COUNTRY')}
              placeholder={t('SELECT_PURCHASE_COUNTRY')}
              nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
              data={orderCountry(listCountries)}
              filter={(value: string, find: OptionObject) =>
                find.label.toLowerCase().includes(value.toLowerCase())
              }
              searchable
              clearable
              {...form.getInputProps('purchaseCountry.value')}
              withAsterisk
            />
          </Grid.Col>
          <Grid.Col span={9} mb={'md'}>
            {form.values.purchasePlace.value == PurchasePlace.NEGOZIO_FISICO ? (
              <Autocomplete
                nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
                data={stores}
                label={t('STORE_INSERT_TITLE')}
                placeholder={t('STORE_INSERT_PLACEHOLDER')}
                limit={10}
                icon={<Search size={20} strokeWidth={2} color={'black'} />}
                filter={(value: string, find) => {
                  let new_val = '';
                  const split_string = value.split(' ');
                  split_string.forEach((val) => {
                    new_val = val;
                  });
                  return find.value
                    .toLowerCase()
                    .includes(new_val.toLowerCase());
                }}
                {...form.getInputProps('storeAddress.value')}
                withAsterisk
              />
            ) : (
              <TextInput
                label={t('STORE_INSERT_TITLE')}
                placeholder={t('STORE_INSERT_PLACEHOLDER')}
                {...form.getInputProps('storeAddress.value')}
              />
            )}
          </Grid.Col>
        </Grid>
        <UploadFile
          file={attachedFile}
          fileErrorMessage={fileErrorMessage}
          clearInput={removeFile}
          onChangeFile={(value) => onChangeFile(value)}
          typeOfFileToAttach={t('INFO_MODAL_BOX_INPUT_FILE')}
        />
        <div className={styles.checkBox}>
          <Checkbox
            checked={
              form.values.enableCheckWarrInServiceRequest.value.toLocaleLowerCase() ==
              'true'
            }
            label={t('INFO_CHECK_BOX_LABEL')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              form.setFieldValue(
                'enableCheckWarrInServiceRequest.value',
                e.currentTarget.checked.toString(),
              );
            }}
          />
        </div>
        <div className={styles.ContainerButtonModal}>
          <Button
            className={styles.ButtonCancel}
            onClick={() => {
              onClose();
              form.reset();
              clearInput();
            }}
          >
            {t('CANCEL')}
          </Button>
          <Button
            type="submit"
            disabled={
              form.values.enableCheckWarrInServiceRequest.value.toLocaleLowerCase() !=
                'true' || loading
            }
          >
            {!instrumentId ? t('ADD_INSTRUMENT') : t('EDIT_INSTRUMENT')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
