import { Button, Grid, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { sendPasswordResetEmail } from 'supertokens-auth-react/lib/build/recipe/emailpassword';
import { useCallback, useState } from 'react';
import styles from './ResetPasswordForm.module.scss';
import { constants } from '../../constants';
import { HeaderAuth } from '../../components/HeaderAuth/HeaderAuth';
import * as React from 'react';

export function ResetPasswordForm() {
  const [resendEmail, setEmailValue] = useState({
    emailValue: '',
  });
  const { t } = useTranslation();
  const handleSubmit = useCallback(async () => {
    await ResetPasswordFormClicked(resendEmail.emailValue);
  }, [resendEmail.emailValue]);

  const form = useForm({
    initialValues: {
      email: resendEmail.emailValue,
    },

    validate: {
      email: (value) =>
        value === ''
          ? t('ERROR_MANDATORY_FIELD')
          : constants.EMAIL_REGEX.test(value)
          ? null
          : t('ERROR_EMAIL_FORMAT'),
    },
  });

  async function ResetPasswordFormClicked(email: string) {
    try {
      const response = await sendPasswordResetEmail({
        formFields: [
          {
            id: 'email',
            value: email,
          },
        ],
      });
      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            console.error('error');
          }
        });
      } else {
        setEmailValue({
          emailValue: email,
        });
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        console.error(err.message);
      } else {
        console.error('Server is down');
      }
    }
  }

  return (
    <>
      <div className={styles.ResetPasswordForm}>
        <HeaderAuth
          title={
            !resendEmail.emailValue
              ? t('EMAIL_PASSWORD_RESET_HEADER_SHORT_TITLE')
              : t('EMAIL_PASSWORD_RESET_PASSWORD_LANDING_PAGE_TITLE')
          }
          subTitle={
            !resendEmail.emailValue
              ? t('EMAIL_PASSWORD_RESET_HEADER_SUBTITLE')
              : t('EMAIL_PASSWORD_RESET_PASSWORD_LANDING_PAGE_SUBTITLE')
          }
        />
        {!resendEmail.emailValue ? (
          <form
            onSubmit={form.onSubmit((values) =>
              ResetPasswordFormClicked(values.email),
            )}
          >
            <Grid className={styles.GirdEmail}>
              <Grid.Col>
                <TextInput
                  className={styles.TextInput}
                  label={t('SignIn_EmailInputLabel')}
                  placeholder={t('SignIn_EmailInputPlaceholder')}
                  radius="xs"
                  size="sm"
                  withAsterisk
                  {...form.getInputProps('email')}
                />
              </Grid.Col>
              <Grid.Col className={styles.TextInput}>
                <Button type={'submit'}>
                  {t('EMAIL_PASSWORD_RESET_HEADER_SHORT_TITLE')}
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        ) : (
          <Grid className={styles.GirdLogIn}>
            <Grid.Col className={styles.TextInput}>
              <p className={styles.notification}>
                {t('EMAIL_PASSWORD_RESET_PASSWORD_LANDING_PAGE_RESEND_LABEL')}
                <a className={styles.HLink} onClick={handleSubmit}>
                  {t('EMAIL_PASSWORD_RESET_PASSWORD_LANDING_PAGE_RESEND_LINK')}
                </a>
              </p>
            </Grid.Col>
          </Grid>
        )}
      </div>
    </>
  );
}
