import { Button, Grid, PasswordInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../hooks/useNavigationLinks';
import { submitNewPassword } from 'supertokens-auth-react/lib/build/recipe/emailpassword';
import { useNavigate } from 'react-router-dom';
import styles from './UpdatePassword.module.scss';
import { constants } from '../../constants';
import { HeaderAuth } from '../../components/HeaderAuth/HeaderAuth';
import { InfoBox } from '../../components/InfoBox/InfoBox';

export function UpdatePassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      newPassword: '',
      passwordConfirmation: '',
    },

    validate: {
      newPassword: (value) =>
        value === ''
          ? t('ERROR_MANDATORY_FIELD')
          : constants.PASSWORD_REGEX.test(value)
          ? null
          : t('ERROR_PASSWORD_FORMAT'),

      passwordConfirmation: (value, values) =>
        value === ''
          ? t('ERROR_MANDATORY_FIELD')
          : value !== values.newPassword
          ? t('ERROR_PASSWORD_CONSISTENCY')
          : null,
    },
  });

  async function newPasswordValue(
    newPassword: string,
    confirmPassword: string,
  ) {
    try {
      const response = await submitNewPassword({
        formFields: [
          {
            id: 'password',
            value: newPassword,
          },
          {
            id: 'confirmPassword',
            value: confirmPassword,
          },
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField) => {
          if (formField.id === 'password') {
            console.error(formField.error);
          }
        });
      } else {
        navigate(Paths.INSTRUMENTS);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        console.error(err.message);
      } else {
        console.error('Server is down');
      }
    }
  }

  return (
    <>
      <HeaderAuth
        title={t('EMAIL_PASSWORD_CHANGE_FORM_TITLE')}
        subTitle={t('EMAIL_PASSWORD_CHANGE_FORM_SUBTITLE')}
      ></HeaderAuth>
      <form
        onSubmit={form.onSubmit((values) =>
          newPasswordValue(values.newPassword, values.passwordConfirmation),
        )}
      >
        <Grid className={styles.PasswordChangeForm}>
          <Grid.Col span={6}>
            <PasswordInput
              label={t('EMAIL_PASSWORD_NEW_PASSWORD_LABEL')}
              placeholder={t('SignIn_PasswordInputPlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('newPassword')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label={t('EMAIL_PASSWORD_CONFIRM_PASSWORD_LABEL')}
              placeholder={t('SignIn_PasswordInputPlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('passwordConfirmation')}
            />
          </Grid.Col>
          <Grid.Col span={12} mb={15} className={styles.TextInput}>
            <InfoBox context={t('SignUp_InformationAboutPassword')} />
          </Grid.Col>
          <Grid.Col>
            <Button type={'submit'}>
              {t('PASSWORD_CHANGE_SUBMIT_BUTTON')}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
}
