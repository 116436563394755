import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card/Card';
import {
  Button,
  Grid,
  Group,
  Modal,
  PasswordInput,
  Radio,
  Select,
  TextInput,
} from '@mantine/core';
import { OptionHeader } from '../../components/OptionHeader/OptionHeader';
import { useAccount } from '../../hooks/useAccount';
import styles from './Profile.module.scss';
import { AccountApi, TicketApi } from '../../api';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import i18n from '../../lib/i18n/i18n';
import { useApiNotification } from '../../hooks/useApiNotification';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { useChangePassword } from './useChangePassword';
import { useUpdateAccount } from './useUpdateAccount';
import {
  resetAddress,
  setBillingAddress,
  setFlagisShippingAddres,
  setShippingAddress,
} from '../../components/AddressDispatch/parser/parserAddressDispatch';
import { Address } from '../../components/Address/Address';
import { TypeOfAddress } from '../../lib/constantCrm';
import { useUpdateAddressDispatch } from '../../components/AddressDispatch/useUpdateAddressDispatch';
import { useCompany } from '../../hooks/useCompany';
import { useUpdateCompany } from './useUpdateCompany';
import {
  getState,
  isNotBlank,
  orderCountry,
  removeLocaleLanguage,
} from '../../lib/utils';
import { useCountry } from '../../components/Address/useCountry';
import { constants } from '../../constants';

export function Profile() {
  const { t } = useTranslation();
  const { showError } = useApiNotification();
  const { account, updateAccount } = useAccount();
  const { company, refreshCompany } = useCompany();

  const passForm = useChangePassword();
  const accountForm = useUpdateAccount();
  const companyForm = useUpdateCompany();
  const addressBillingForm = useUpdateAddressDispatch();
  const addressShippingForm = useUpdateAddressDispatch();

  const [language, setLanguage] = useState(removeLocaleLanguage(i18n.language));
  const { listCountries } = useCountry();

  const [confirmationDeleteAccountModal, setOpenModal] = useState(false);
  const [ticketInManagement, setTicketInManagement] = useState<
    number | undefined
  >(undefined);

  const useEffectOnce = useRef(false);

  useEffect(() => {
    //Init setup of profile info
    if (useEffectOnce.current) return;
    if (company) {
      companyForm.form.setValues({
        FF_GT02_EXT003: company.FF_GT02_EXT003?.toUpperCase() || '',
        phone: company?.phones[0] ? company?.phones[0].value : '',
        state: getState(company.state),
      });
      addressBillingForm.form.setValues({
        infoAddress: setBillingAddress(company),
        isShippingAddress: setFlagisShippingAddres(company),
        FF_GT_SYNC: company.FF_GT_SYNC,
      });
      addressShippingForm.form.setValues({
        infoAddress: setShippingAddress(company),
      });
      useEffectOnce.current = true;
    }
  }, [company, useEffectOnce]);

  useEffect(() => {
    TicketApi.countStatus().then((response) => {
      setTicketInManagement(response?.unableDeleteAccount || 0);
    });
  }, []);

  const onCancelAddressBilling = useCallback(() => {
    addressBillingForm.form.setFieldValue(
      'infoAddress',
      resetAddress(company?.state),
    );
    addressBillingForm.form.setFieldValue('isShippingAddress', true);
  }, [company]);

  const onCancelAddressShippping = useCallback(() => {
    addressShippingForm.form.setFieldValue('infoAddress', resetAddress());
  }, []);

  const hasCompanyChanged = useMemo(() => {
    let hasChanged = false;

    if (company?.FF_GT02_EXT003 != companyForm.form.values.FF_GT02_EXT003)
      hasChanged = true;
    else if (company.state != companyForm.form.values.state) hasChanged = true;
    else if (
      (company.phones &&
        company.phones.length > 0 &&
        company.phones[0].value != companyForm.form.values.phone) ||
      (company.phones.length == 0 && isNotBlank(companyForm.form.values.phone))
    )
      hasChanged = true;
    return hasChanged;
  }, [company, companyForm]);

  return (
    <Card title={t('ACCOUNT_PROFILE_LABEL')}>
      <></>
      <div className={styles.MainDiv}>
        <OptionHeader title={t('YOUR_DATA_LABEL')} />
        <form
          onSubmit={companyForm.onSubmit(async (values) => {
            const { success } = await companyForm.handleSubmit(values);
            if (success) refreshCompany();
          })}
        >
          <Grid className={styles.BodyCard}>
            <Grid.Col span={3}>
              <TextInput
                disabled={true}
                label={t('first_name')}
                value={account?.first_name ? account.first_name : ''}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                disabled={true}
                label={t('last_name')}
                value={account?.last_name ? account.last_name : ''}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                disabled={true}
                label={t('email')}
                value={account?.email ? account.email : ''}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                label={t('phone')}
                placeholder={t('phone_placeholder')}
                {...companyForm.form.getInputProps('phone')}
                disabled={company?.phones && company?.phones?.length > 0}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                disabled={true}
                label={t('company_name')}
                value={company?.companyName || ''}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                disabled={true}
                label={t('vat_number')}
                value={company?.vatId || ''}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <TextInput
                label={t('sdi_label')}
                placeholder={t('sdi_number')}
                {...companyForm.form.getInputProps('FF_GT02_EXT003')}
                disabled={isNotBlank(company?.FF_GT02_EXT003)}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <Select
                label={t('TITLE_SELECT_COUNTRY')}
                placeholder={t('SELECT_COUNTRY')}
                {...companyForm.form.getInputProps('state')}
                value={companyForm.form.values.state}
                disabled={isNotBlank(company?.state)}
                nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
                data={orderCountry(listCountries)}
                filter={(value: string, find) =>
                  find.value.toLowerCase().startsWith(value.toLowerCase())
                }
                searchable
                clearable
              />
            </Grid.Col>
          </Grid>

          <Grid justify={'flex-end'} className={styles.BodyCard}>
            <Grid.Col>
              <Group position="right">
                <Button disabled={!hasCompanyChanged} type="submit">
                  {t('confirm_button')}
                </Button>
              </Group>
            </Grid.Col>
          </Grid>
        </form>
        <form
          onSubmit={addressBillingForm.onSubmit(async (values) => {
            await addressBillingForm.handleSubmit(
              values,
              TypeOfAddress.BILLING,
            );
          })}
        >
          <OptionHeader title={t('ADDRESS_TO_BILLADDRESS')} />
          <Address
            key={'billAddress'}
            listCountries={listCountries}
            fieldName="infoAddress"
            formAddress={addressBillingForm.form}
            enableToUseLikeShippingAddress={true}
            disabledCountry={isNotBlank(company?.state)}
            onCancel={() => onCancelAddressBilling()}
            showButtons={true}
            titleConfirmButton={t('CONFIRM_BILL_ADDRESS')}
          />
        </form>
        <form
          onSubmit={addressShippingForm.onSubmit(async (values) => {
            await addressShippingForm.handleSubmit(
              values,
              TypeOfAddress.SHIIPING,
            );
          })}
        >
          {!addressBillingForm.form.values.isShippingAddress && (
            <>
              <OptionHeader title={t('ADDRESS_TO_SHIPPINGADDRESS')} />
              <Address
                key={'shippingAddress'}
                listCountries={listCountries}
                fieldName="infoAddress"
                formAddress={addressShippingForm.form}
                onCancel={() => onCancelAddressShippping()}
                showButtons={true}
                titleConfirmButton={t('CONFIRM_SHIPPING_ADDRESS')}
              />
            </>
          )}
        </form>
        <OptionHeader title={t('CHANGE_PASSWORD')} />
        <form
          onSubmit={passForm.onSubmit(async (values) => {
            await passForm.handleSubmit(values);
          })}
        >
          <Grid className={styles.BodyCard}>
            <Grid.Col span={3}>
              <PasswordInput
                className={styles.TextInput}
                label={t('OLD_PASSWORD')}
                placeholder={
                  t('ENTER_PASSWORD') + ' ' + t('OLD_PASSWORD').toLowerCase()
                }
                radius="xs"
                size="sm"
                withAsterisk
                {...passForm.form.getInputProps('oldPassword')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <PasswordInput
                className={styles.TextInput}
                label={t('NEW_PASSWORD')}
                placeholder={
                  t('ENTER_PASSWORD') + ' ' + t('NEW_PASSWORD').toLowerCase()
                }
                radius="xs"
                size="sm"
                withAsterisk
                {...passForm.form.getInputProps('password')}
              />
            </Grid.Col>
            <Grid.Col span={3}>
              <PasswordInput
                className={styles.TextInput}
                label={t('CONFIRM_NEW_PASSWORD')}
                placeholder={
                  t('ENTER_PASSWORD') +
                  ' ' +
                  t('CONFIRM_NEW_PASSWORD').toLowerCase()
                }
                radius="xs"
                size="sm"
                withAsterisk
                {...passForm.form.getInputProps('confirmPassword')}
              />
            </Grid.Col>
            <Grid.Col span={9}>
              <InfoBox context={t('SignUp_InformationAboutPassword')} />
            </Grid.Col>
          </Grid>
          <Grid className={styles.BodyCard}>
            <Grid.Col span={6} className={styles.TextInput}>
              <Button
                className={styles.ButtonCancel}
                onClick={() => {
                  return passForm.form.reset();
                }}
              >
                {t('CANCEL')}
              </Button>
            </Grid.Col>
            <Grid.Col span={6} className={styles.TextInput}>
              <Button className={styles.ButtonConfirm} type={'submit'}>
                {t('PASSWORD_CHANGE_SUBMIT_BUTTON')}
              </Button>
            </Grid.Col>
          </Grid>
        </form>
        <OptionHeader title={t('change_language')} />
        <Group className={styles.BodyCard} position={'apart'}>
          <Radio.Group
            value={account?.language || language}
            onChange={(v) =>
              i18n.changeLanguage(v, async (error) => {
                if (error) return showError();
                const updateLangugage = {
                  language: v,
                };
                setLanguage(v);
                const changeAccount = { ...account };
                changeAccount.language = v;
                updateAccount(changeAccount);
                await accountForm.handleSubmit(updateLangugage);
              })
            }
          >
            {constants.SOPPORTEDLANGUGAES.map((language) => (
              <Radio key={language} value={language} label={t(language)} />
            ))}
          </Radio.Group>
        </Group>

        <OptionHeader title={t('delete_account')}></OptionHeader>
        <Group className={styles.BodyCard} position={'apart'}>
          <p className={styles.ActionTitle}>{t('DELETE_ACCOUNT_LABEL')}</p>
          <Button
            className={styles.ButtonConfirm}
            onClick={() => setOpenModal(true)}
          >
            {t('delete_account')}
          </Button>
        </Group>
        <Modal
          centered={true}
          opened={confirmationDeleteAccountModal}
          onClose={() => setOpenModal(false)}
          title={
            ticketInManagement == 0
              ? t('delete_account_confirmation_modal_message')
              : t('unable_to_delete_account_modal_message')
          }
        >
          <Button onClick={() => setOpenModal(false)}>{t('back')}</Button>
          {ticketInManagement == 0 && (
            <Button
              className={styles.ButtonConfirm}
              onClick={async () => {
                if (
                  ticketInManagement == 0 &&
                  ticketInManagement != undefined
                ) {
                  await AccountApi.remove();
                  await signOut();
                  window.location.href = '/';
                }
              }}
            >
              {t('delete_account')}
            </Button>
          )}
        </Modal>
      </div>
    </Card>
  );
}
