import React from 'react';

export default function HtLogo() {
  return (
    <svg
      width="83"
      height="30"
      viewBox="0 0 83 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_158_1570)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.00244141 19.2192L5.30496 19.3918V17.931L0.00244141 19.2192Z"
          fill="#004A98"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9602 3.68604L18.7393 6.21326H19.9728L18.9602 3.68604Z"
          fill="#004F8B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9028 5.68317L12.8169 6.21327H13.2158L12.9028 5.68317Z"
          fill="#004A98"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.9255 0L24.6616 6.21327H28.1475L24.9255 0Z"
          fill="#004F8B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.4851 6.23792C79.9951 6.2367 80.4941 6.38751 80.9188 6.67123C81.3435 6.95496 81.6748 7.35885 81.8708 7.83179C82.0669 8.30473 82.1188 8.82547 82.02 9.32807C81.9213 9.83066 81.6763 10.2925 81.316 10.6552C80.9558 11.0179 80.4965 11.265 79.9963 11.3654C79.4961 11.4658 78.9776 11.4149 78.5062 11.2192C78.0349 11.0234 77.6319 10.6916 77.3484 10.2657C77.0649 9.83985 76.9136 9.33908 76.9136 8.82679C76.9136 8.14125 77.1843 7.48368 77.6664 6.99835C78.1484 6.51302 78.8025 6.23955 79.4851 6.23792ZM79.4851 6.62625C79.0517 6.62625 78.6281 6.75531 78.2678 6.99711C77.9075 7.23891 77.6267 7.58259 77.4609 7.98469C77.295 8.38678 77.2516 8.82923 77.3362 9.2561C77.4207 9.68296 77.6294 10.075 77.9358 10.3828C78.2422 10.6905 78.6326 10.9001 79.0576 10.985C79.4826 11.07 79.9232 11.0264 80.3235 10.8598C80.7238 10.6933 81.066 10.4112 81.3068 10.0493C81.5475 9.68746 81.676 9.26202 81.676 8.82679C81.676 8.24318 81.4452 7.68345 81.0343 7.27077C80.6234 6.85809 80.0661 6.62625 79.4851 6.62625Z"
          fill="#004A98"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M79.2088 8.55556H79.3008C79.4765 8.56851 79.6528 8.54111 79.8163 8.47543C79.8605 8.44492 79.8957 8.40311 79.9184 8.35434C79.941 8.30558 79.9503 8.25162 79.9452 8.19804C79.9507 8.13875 79.9406 8.07905 79.9158 8.02495C79.891 7.97084 79.8525 7.92424 79.8041 7.88985C79.6459 7.81991 79.4731 7.79028 79.3008 7.80356H79.2088V8.55556ZM79.1781 8.88225V10.0596H78.7178V7.43372H79.4052C79.5615 7.4301 79.7177 7.44249 79.8716 7.4707C79.9712 7.49143 80.0667 7.52894 80.1539 7.58165C80.2441 7.64533 80.3178 7.72992 80.3687 7.82822C80.4208 7.93138 80.4481 8.04537 80.4485 8.16106C80.4525 8.25097 80.4389 8.34081 80.4084 8.42543C80.3779 8.51005 80.331 8.58779 80.2705 8.65419C80.1278 8.78162 79.9457 8.85565 79.755 8.86376L80.7921 10.0596H80.2398L79.2333 8.88225H79.1781Z"
          fill="#004A98"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.3027 30H76.3182V6.21326H67.3027V9.45551H74.2868V13.4066H69.9539V27.2755H67.3027V30ZM28.1536 6.21326L31.9955 13.4929L39.5995 11.4958V9.45551H44.767V10.0719L49.7626 8.69118L44.767 11.089V16.2852H52.3218V9.45551H57.5323V27.2755H52.3157V19.9281H44.767V27.2755H39.5995V13.6532L30.0071 18.2207V18.1775L29.9211 18.2207L26.65 10.8239L25.8522 27.818L20.5067 13.6408L19.58 26.5543L14.3634 14.2572L12.5223 25.4017L6.77786 19.4103H5.30493V30.0123H67.2904V27.2755H64.7251V13.4066H60.3554V9.45551H67.2904V6.21326H28.1536Z"
          fill="#004A98"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7393 6.21326H13.2158L18.0335 16.729L18.7393 6.21326Z"
          fill="#004F8B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.1843 20.902L12.8168 6.21326H5.30493V17.9309L8.22009 17.2221L11.1843 20.902Z"
          fill="#004F8B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.6615 6.21326H19.9727L24.0846 16.729L24.6615 6.21326Z"
          fill="#004F8B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.9149 18.196L30.0069 18.1529L26.6499 10.7869L29.9149 18.196Z"
          fill="#004A98"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.5994 13.6285L44.7669 11.0952L39.5994 13.6285Z"
          fill="#004A98"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7669 10.0781L39.5994 11.4958L44.7669 10.0781Z"
          fill="#004A98"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.7669 10.0719L39.5933 11.4958V13.6223L44.7669 11.0951V10.0719Z"
          fill="#004A98"
        />
      </g>
      <defs>
        <clipPath id="clip0_158_1570">
          <rect width="82.0588" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
