import { FileInput, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import styles from './UploadFile.module.scss';

import CrossIcon from '../../components/icons/CrossIcon';
import InfoIcon from '../../components/icons/InfoIcon';
import PaperClipIcon from '../../components/icons/PaperClipIcon';

interface Props {
  file?: File | null;
  fileErrorMessage?: string;
  onChangeFile?: (value: File) => void;
  clearInput?: () => void;
  onClick?: () => void;
  typeOfFileToAttach?: string;
  disabled?: boolean;
}

export function UploadFile({
  file = null,
  fileErrorMessage = '',
  onChangeFile = () => {},
  onClick = () => {},
  clearInput = () => {},
  typeOfFileToAttach,
  disabled = false,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.mainDivInput}>
        <Title className={styles.TitleInputSubmit}>{typeOfFileToAttach}</Title>
        <div className={styles.iconDivInput}>
          <InfoIcon />
        </div>
      </div>
      <div className={styles.DivInput} onClick={onClick}>
        <div className={styles.ClipIcon}>
          <PaperClipIcon />
        </div>
        <FileInput
          className={styles.InputFile}
          placeholder={t('select_avatar_file')}
          variant="unstyled"
          radius="xs"
          size="xs"
          withAsterisk
          accept="image/png,image/jpeg/,application/pdf"
          onChange={(value: File) => onChangeFile(value)}
          value={file}
          error={fileErrorMessage}
          disabled={disabled}
        />
        {file ? (
          <div className={styles.IconCross} onClick={() => clearInput()}>
            <CrossIcon />
          </div>
        ) : null}
      </div>
    </>
  );
}
