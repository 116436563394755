import React from 'react';
export default function MapPin() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00009 0C3.73468 0 0.265686 3.4686 0.265686 7.73441C0.265686 11.9093 7.46558 19.5779 7.77192 19.9033C7.83145 19.9657 7.91562 20 8.00009 20C8.08457 20 8.16874 19.9657 8.22811 19.9031C8.53441 19.5781 15.7344 11.9092 15.7344 7.73425C15.7345 3.46884 12.2657 0.000242048 8.00034 0.000242048M8.00034 10.8874C6.25977 10.8874 4.84733 9.47201 4.84733 7.73453C4.84733 5.99706 6.25977 4.58136 8.00034 4.58136C9.74091 4.58136 11.1533 5.9969 11.1533 7.73437C11.1533 9.47185 9.74091 10.8874 8.00034 10.8874Z"
        fill="#004B98"
      />
    </svg>
  );
}
