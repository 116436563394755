import { useAccount } from '../../hooks/useAccount';
import { useApiNotification } from '../../hooks/useApiNotification';
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { AccountApi, UpdateAccountDto } from '../../api';

export function useUpdateAccount() {
  const { account } = useAccount();
  const { showError, showSuccess } = useApiNotification();

  const schema = z.object({
    first_name: z.string(),
    last_name: z.string(),
    language: z.string(),
  });

  const form = useForm({
    validate: zodResolver(schema),
    initialValues: {
      first_name: account?.first_name,
      last_name: account?.last_name,
      language: account?.language,
    },
  });

  async function handleSubmit(values: {
    first_name?: string;
    last_name?: string;
    language?: string;
  }): Promise<{ success: boolean }> {
    const changedField: UpdateAccountDto = {
      first_name: values.first_name,
      last_name: values.last_name,
      language: values.language,
    };
    try {
      await AccountApi.update({ requestBody: changedField });
      showSuccess();
      return { success: true };
    } catch (error) {
      showError();
      return { success: false };
    }
  }

  return {
    schema,
    onSubmit: form.onSubmit,
    handleSubmit,
    getInputProps: (field: keyof z.infer<typeof schema>) =>
      form.getInputProps(field),
  };
}
