/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyDto } from '../models/CompanyDto';
import type { CreateCompanyDto } from '../models/CreateCompanyDto';
import type { PaginatedCompanyDto } from '../models/PaginatedCompanyDto';
import type { UpdateCompanyDto } from '../models/UpdateCompanyDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompaniesApi {

    /**
     * @returns PaginatedCompanyDto 
     * @throws ApiError
     */
    public static findAll({
search,
size,
page,
}: {
search?: string,
size?: number,
page?: number,
}): CancelablePromise<PaginatedCompanyDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/companies',
            query: {
                'search': search,
                'size': size,
                'page': page,
            },
        });
    }

    /**
     * @returns number 
     * @throws ApiError
     */
    public static create({
requestBody,
}: {
requestBody: CreateCompanyDto,
}): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/companies',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CompanyDto 
     * @throws ApiError
     */
    public static findOne({
id,
}: {
id: string,
}): CancelablePromise<CompanyDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/companies/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns any 
     * @returns number 
     * @throws ApiError
     */
    public static update({
id,
requestBody,
}: {
id: string,
requestBody: UpdateCompanyDto,
}): CancelablePromise<any | number> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/companies/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
