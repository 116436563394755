import { useEffect, useState } from 'react';
import {
  TextInput,
  PasswordInput,
  Button,
  Grid,
  Alert,
  Select,
  Checkbox,
  Anchor,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import styles from './SignUpForm.module.scss';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { Paths } from '../../hooks/useNavigationLinks';
import { useNavigate } from 'react-router-dom';

import {
  doesEmailExist,
  signUp,
} from 'supertokens-auth-react/recipe/emailpassword';
import { constants } from '../../constants';
import AlertIcon from '../../components/icons/AlertIcon';
import { HeaderAuth } from '../../components/HeaderAuth/HeaderAuth';
import { orderCountry, removeLocaleLanguage } from '../../lib/utils';
import { OptionObject } from '../../lib/types';
import { useVerifyEmail } from '../VerifyEmailForm/useVerifyEmail';
import { useCountry } from '../../components/Address/useCountry';
import i18next from 'i18next';

type CreateUser = {
  name: string;
  lastName: string;
  company: string;
  vatNumber: string;
  state: string;
  email: string;
  password: string;
  confirmPassword: string;
  checkedConsentPersonalData: boolean;
};

type ErrorMessage = {
  showError: boolean;
  messageError: string;
};

export function SignUpForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>(false);
  const [error, setError] = useState<ErrorMessage>();
  const { listCountries } = useCountry();
  const { sendEmail } = useVerifyEmail();

  const form = useForm<CreateUser>({
    initialValues: {
      name: '',
      lastName: '',
      company: '',
      vatNumber: '',
      email: '',
      state: '',
      password: '',
      confirmPassword: '',
      checkedConsentPersonalData: false,
    },
    validate: {
      email: (value) =>
        value === ''
          ? t('ERROR_MANDATORY_FIELD')
          : constants.EMAIL_REGEX.test(value)
          ? null
          : t('ERROR_EMAIL_FORMAT'),
      name: (value) => (value === '' ? t('ERROR_MANDATORY_FIELD') : null),
      lastName: (value) => (value === '' ? t('ERROR_MANDATORY_FIELD') : null),
      company: (value) => (value === '' ? t('ERROR_MANDATORY_FIELD') : null),
      state: (value) => (value === '' ? t('ERROR_MANDATORY_FIELD') : null),
      vatNumber: (value) =>
        value === ''
          ? t('ERROR_MANDATORY_FIELD')
          : !constants.VAT_REGEX.test(value)
          ? t('ERROR_VAT_FORMAT')
          : null,
      password: (value) =>
        value === ''
          ? t('ERROR_MANDATORY_FIELD')
          : !constants.PASSWORD_REGEX.test(value)
          ? t('ERROR_PASSWORD_FORMAT')
          : null,
      confirmPassword: (value, values) =>
        value === ''
          ? t('ERROR_MANDATORY_FIELD')
          : value !== values.password
          ? t('ERROR_PASSWORD_CONSISTENCY')
          : null,
      checkedConsentPersonalData: (value) =>
        !value ? t('ERROR_MANDATORY_FIELD') : null,
    },
  });

  async function signUpClicked(
    email: string,
    password: string,
    name: string,
    lastName: string,
    confirmPassword: string,
    company: string,
    vatNumber: string,
    state: string,
    checkedConsentPersonalData: boolean,
  ) {
    const isEmailCorrect: boolean = await checkEmail(email);
    if (isEmailCorrect) {
      try {
        setIsEmailConfirmed(true);
        const response = await signUp({
          formFields: [
            {
              id: 'first_name',
              value: name,
            },
            {
              id: 'last_name',
              value: lastName,
            },
            {
              id: 'company',
              value: company,
            },
            {
              id: 'vatNumber',
              value: vatNumber,
            },
            {
              id: 'state',
              value: state,
            },
            {
              id: 'email',
              value: email,
            },
            {
              id: 'password',
              value: password,
            },
            {
              id: 'confirm-password',
              value: confirmPassword,
            },
            {
              id: 'consent_personal_data',
              value: checkedConsentPersonalData.toString(),
            },
          ],
        });

        if (response.status === 'FIELD_ERROR') {
          setIsEmailConfirmed(false);
          setError({ showError: true, messageError: t('GENERIC_ERROR') });
          response.formFields.forEach((formField) => {
            if (formField.id === 'email')
              setError({
                showError: true,
                messageError: t('EMAIL_PASSWORD_EMAIL_ALREADY_EXISTS'),
              });
          });
        } else {
          await sendEmail();
          navigate(Paths.VERIFY_EMAIL);
        }
      } catch (err: any) {
        setIsEmailConfirmed(false);
        if (err.isSuperTokensGeneralError === true) {
          console.error(err);
          setError({ showError: true, messageError: err.message });
        } else {
          console.error(err);
          setError({ showError: true, messageError: t('GENERIC_ERROR') });
        }
      }
    }
  }

  async function checkEmail(email: string) {
    try {
      const response = await doesEmailExist({
        email,
      });

      if (response.doesExist) {
        return false;
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        setError({ showError: true, messageError: err.message });
        return false;
      } else {
        console.error(err);
        setError({ showError: true, messageError: t('GENERIC_ERROR') });
        return false;
      }
    } finally {
      return true;
    }
  }

  return (
    <div className={styles.SignUpForm}>
      <HeaderAuth
        title={t('SignUp_Title')}
        subTitle={t('SignUp_Subtitle')}
        textLink={t('SignUp_Link')}
        link={Paths.SIGNIN}
      />
      {!!error?.showError && (
        <Alert
          className={styles.Alert}
          icon={
            <div>
              <AlertIcon />
            </div>
          }
          color="red"
          variant="outline"
        >
          {error.messageError}
        </Alert>
      )}

      <form
        onSubmit={form.onSubmit((values) =>
          signUpClicked(
            values.email,
            values.password,
            values.name,
            values.lastName,
            values.confirmPassword,
            values.company,
            values.vatNumber,
            values.state,
            values.checkedConsentPersonalData,
          ),
        )}
      >
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label={t('SignUp_Name')}
              placeholder={t('SignUp_NamePlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('name')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label={t('SignUp_LastNameLabel')}
              placeholder={t('SignUp_LastNamePlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('lastName')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label={t('SignUp_Company')}
              placeholder={t('SignUp_ComponyPlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('company')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label={t('SignUp_VatCompany')}
              placeholder={t('SignUp_VatCompanyPlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('vatNumber')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Select
              label={t('TITLE_SELECT_COUNTRY')}
              placeholder={t('SELECT_COUNTRY')}
              nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
              data={orderCountry(listCountries)}
              filter={(value: string, find: OptionObject) =>
                find.label.toLowerCase().includes(value.toLowerCase())
              }
              withAsterisk
              searchable
              clearable
              {...form.getInputProps('state')}
            />
          </Grid.Col>
          <Grid.Col>
            <TextInput
              label={t('SignUp_EmailLabel')}
              placeholder={t('SignUp_EmailPlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('email')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label={t('SignUp_PasswordLabel')}
              placeholder={t('SignUp_PasswordPlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('password')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label={t('SignUp_ConfirmPasswordLabel')}
              placeholder={t('SignUp_ConfirmPasswordPlaceholder')}
              radius="xs"
              size="sm"
              withAsterisk
              {...form.getInputProps('confirmPassword')}
            />
          </Grid.Col>
          <Grid.Col>
            <InfoBox context={t('SignUp_InformationAboutPassword')} />
          </Grid.Col>
          <Grid.Col span={12}>
            <Checkbox
              label={
                <>
                  {t('INFO_CHECK_BOX_CONSENT_PERSONAL_DATA_LABEL') + ' '}
                  <Anchor
                    href={
                      'https://www.ht-instruments.it/' +
                      t('LANGUAGE_DATE') +
                      '/privacy-policy/'
                    }
                    target="_blank"
                  >
                    {t('PRIVACY_POLICY_HT')}
                  </Anchor>
                </>
              }
              {...form.getInputProps('checkedConsentPersonalData')}
            />
          </Grid.Col>
          <Grid.Col mt={25}>
            <Button
              loading={isEmailConfirmed}
              type={'submit'}
              className={styles.SignInButton}
            >
              {t('SignUp_ButtonLabel')}
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </div>
  );
}
