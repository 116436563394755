import {
  Button,
  createStyles,
  Group,
  LoadingOverlay,
  Menu,
  Modal,
  MultiSelect,
  Pagination,
  Select,
  Textarea,
  TextInput,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import styles from '../../pages/Instruments/Instruments.module.scss';
import { useTranslation } from 'react-i18next';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import PlusIcon from '../../components/icons/PlusIcon';
import { InstrumentsModal } from './InstrumentModal';
import { Card } from '../../components/Card/Card';
import { JobOrderTaskApi, RollingStocksApi } from '../../api';
import { useOffsetPagination } from '../../hooks/useOffsetPagination';
import { Header, TableSort } from '../../components/Table/Table';
import {
  formatDate,
  formatHours,
  isBlank,
  isNotBlank,
  isNotRightStateName,
} from '../../lib/utils';
import MapPin from '../../components/icons/MapPin';
import { Dots, Search } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../hooks/useNavigationLinks';
import { parserInstrumentFromApiModel } from './parser/parserInstruments';
import { useSearch } from '../../hooks/useSearch';
import { PaginatedInstrument } from './dto/PaginatedInstrument';
import {
  getFilterContents,
  getFilterEqual,
  getFiltersToProductId,
} from '../../lib/crmUtils';
import { useFilterInstrument } from './useFilterInstrument';
import { useApiNotification } from '../../hooks/useApiNotification';
import { useCompany } from '../../hooks/useCompany';

type WithActions<T> = T & { actions: '' };
type Instrument = PaginatedInstrument['data'][number];

const useStyles = createStyles((t) => ({
  values: {
    height: 34,
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '0.5em',
    },

    '::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #ededed',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: t.colors.accent[6],
      opacity: '5',
    },
  },
}));

export function Instruments() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { company } = useCompany();
  const [confirmationDeleteInstrumentsModal, setOpenModal] = useState(false);
  const { showError } = useApiNotification();
  const [isLoaded, setIsLoaded] = useState(false);
  const [openInstrumentModal, setInstrumentModal] = useState(false);
  const { search, setSearch } = useSearch(() => findAll(1));
  const [instrumentsId, setInstrumentEditing] = useState<string>('');
  const [oneSearch, setOneSearch] = useState(false);
  const [isCheckedStateCompany, setCheckedStateCompany] = useState(false);
  const { data, findAll, page, total, loading, size } = useOffsetPagination(
    (pagination) => RollingStocksApi.findAll({ ...pagination, search }),
  );
  const [deleteMode, setDeleteMode] = useState(true);
  const [noteToSend, setNoteToSend] = useState('');

  const [filterTypeProduct, setFilterTypeProduct] = useState<string>('');
  const [filterModel, setFilterModel] = useState<string[]>([]);

  const {
    optionsModel,
    optionsTypeProduct,
    setTypedProduct,
    searchModel,
    searchNumSerial,
    searchTypeProduct,
    setSearchTypeProduct,
    setSearchModel,
    setSearchNumSerial,
  } = useFilterInstrument();

  useEffect(() => {
    company && setCheckedStateCompany(!isNotRightStateName(company.state));
  }, [company]);

  const onSearch = useCallback(
    (typeProduct: string, numSerial: string, model: string) => {
      let filter = '';
      const condition = ' and ';
      if (isNotBlank(numSerial)) filter = numSerial;

      if (isNotBlank(typeProduct)) {
        filter += isBlank(filter) ? typeProduct : condition + typeProduct;
      }

      if (isNotBlank(model)) {
        filter += isBlank(filter) ? model : condition + model;
      }

      setSearch(filter);
    },
    [setSearch],
  );

  const onChangeSelectTypeProduct = useCallback(
    (value: string) => {
      setFilterTypeProduct(value);
      const typeProduct = isBlank(value) ? '' : getFilterEqual('title', value);
      setSearchTypeProduct(typeProduct);
      onSearch(typeProduct, searchNumSerial, searchModel);
    },
    [onSearch, setSearchTypeProduct, searchNumSerial, searchModel],
  );

  const onChangeModel = useCallback(
    (values: string[]) => {
      setFilterModel(values);
      const filtersModel =
        values.length == 0 ? '' : getFiltersToProductId('productId', values);
      setSearchModel(filtersModel);
      onSearch(searchTypeProduct, searchNumSerial, filtersModel);
    },
    [onSearch, setSearchModel, searchNumSerial, searchTypeProduct],
  );

  const headers: Header<WithActions<Instrument>>[] = [
    {
      displayName: t('MODEL').toLocaleUpperCase(),
      field: 'model',
    },
    {
      displayName: t('TYPE_PRODUCT').toLocaleUpperCase(),
      field: 'typeProduct',
      renderCell: (value, field, row) => {
        return (
          <Tooltip label={row.typeProduct} className={styles.Ellipsis}>
            <UnstyledButton fz={14}>{row.typeProduct}</UnstyledButton>
          </Tooltip>
        );
      },
    },
    {
      displayName: t('N_SERIAL').toLocaleUpperCase(),
      field: 'serialNumber',
    },
    {
      displayName: t('ASSIGNED_TO').toLocaleUpperCase(),
      field: 'assignedTo',
      renderCell: (value) => value.value,
    },
    {
      displayName: t('PURCHASE_PLACE_AND_DATE').toLocaleUpperCase(),
      field: 'purchaseDate',
      renderCell: (value, field, row) => {
        return (
          <Group spacing={'xs'}>
            <Group spacing={'xs'}>
              <Tooltip
                label={row.storeAddress.value}
                color="grey"
                withArrow
                arrowPosition="center"
              >
                <UnstyledButton>
                  <MapPin />
                </UnstyledButton>
              </Tooltip>
            </Group>
            {formatDate(value)}
          </Group>
        );
      },
    },
    {
      displayName: t('LAST_UPDATE_DATE').toLocaleUpperCase(),
      field: 'updatedAt',
      renderCell: (value) => {
        return formatDate(value) + ' - ' + formatHours(value);
      },
    },
    {
      displayName: t('ACTION').toLocaleUpperCase(),
      field: 'actions',
      renderCell: (value, field, row) => {
        return (
          <Menu shadow="md" width={200} zIndex={200}>
            <Menu.Target>
              <Button className={styles.ButtonIconMenu}>
                <Dots size={24} strokeWidth={2} color={'black'} />
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>
                <Menu.Item
                  className={styles.ButtonDot}
                  onClick={async () => {
                    try {
                      setIsLoaded(true);
                      await JobOrderTaskApi.findTicketByRollingsId({
                        rollingStockIds: Number(row.id),
                      });
                      setIsLoaded(false);
                      setInstrumentModal(true);
                      setInstrumentEditing(row.id);
                    } catch (e) {
                      setIsLoaded(false);
                      showError(t('ERROR_EDIT_INSTRUMENT'), false);
                    }
                  }}
                >
                  {t('EDIT_INSTRUMENT_ACTION')}
                </Menu.Item>
                {isCheckedStateCompany && (
                  <Menu.Item
                    className={styles.ButtonDot}
                    onClick={async () => {
                      try {
                        setIsLoaded(true);
                        await JobOrderTaskApi.findTicketByRollingsId({
                          rollingStockIds: Number(row.id),
                        });
                        setIsLoaded(false);
                        navigate(Paths.TICKETS, {
                          state: {
                            purchaseAttach: row.purchaseAttach.value,
                            id: Number(row.id),
                          },
                        });
                      } catch (e) {
                        setIsLoaded(false);
                        showError(t('ERROR_CREATE_TICKET'), false);
                      }
                    }}
                  >
                    {t('CREATE_TICKET')}
                  </Menu.Item>
                )}
                <Menu.Item
                  className={styles.ButtonDot}
                  onClick={async () => {
                    try {
                      setIsLoaded(true);
                      await JobOrderTaskApi.findTicketByRollingsId({
                        rollingStockIds: Number(row.id),
                      });
                      setDeleteMode(true);
                      setIsLoaded(false);
                      setOpenModal(true);
                      setInstrumentEditing(row.id);
                    } catch (e) {
                      setIsLoaded(false);
                      showError(t('ERROR_DELETE_INSTRUMENT'), false);
                    }
                  }}
                >
                  {t('DELETE_INSTRUMENTS')}
                </Menu.Item>
              </Menu.Label>
            </Menu.Dropdown>
          </Menu>
        );
      },
    },
  ];
  return (
    <>
      <Card title={t('my_instruments')}>
        <Button
          className={styles.NewInstruments}
          onClick={() => {
            setInstrumentModal(true);
          }}
          leftIcon={<PlusIcon />}
        >
          {t('ADD_INSTRUMENT')}
        </Button>
        <>
          <Group position="apart" mb={'md'} noWrap={true}>
            <Modal
              centered={true}
              opened={confirmationDeleteInstrumentsModal}
              onClose={() => setOpenModal(false)}
              title={t('INSTRUMENTS_DELETE_CONFIRM_MESSAGE')}
            >
              <Textarea
                mb={'md'}
                label={t('NOTE')}
                placeholder={t('INSTRUMENTS_DELETE_NOTE_PLACEHOLDER')}
                maxLength={250}
                autosize
                withAsterisk
                onChange={(note) => {
                  if (note.currentTarget.value.length > 0) {
                    setDeleteMode(false);
                    setNoteToSend(note.currentTarget.value);
                  } else {
                    setDeleteMode(true);
                  }
                }}
              />
              <Button
                onClick={() => {
                  setOpenModal(false);
                  setNoteToSend('');
                }}
              >
                {t('back')}
              </Button>
              <Button
                type="submit"
                className={styles.ButtonConfirm}
                disabled={deleteMode}
                onClick={async () => {
                  await RollingStocksApi.remove({
                    id: instrumentsId,
                    deletenote: noteToSend,
                  });
                  setOpenModal(false);
                  await findAll(page);
                }}
              >
                {t('DELETE_INSTRUMENTS')}
              </Button>
            </Modal>
            <div className={styles.FilterOptionLeft}>
              <TextInput
                className={styles.TextInput}
                placeholder={t('SEARCH_FOR_SERIAL_NUMBER')}
                onChange={(e) => {
                  const filterNumberSearial = getFilterContents(
                    'internalIdentification',
                    e.target.value.trim(),
                  );

                  isBlank(e.target.value) && oneSearch
                    ? (setSearchNumSerial(''),
                      onSearch(searchTypeProduct, '', searchModel))
                    : setSearchNumSerial(filterNumberSearial);
                }}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.key == 'Enter' && isNotBlank(searchNumSerial)) {
                    setOneSearch(true);
                    onSearch(searchTypeProduct, searchNumSerial, searchModel);
                  }
                }}
                disabled={loading}
              />
              <UnstyledButton
                mr={10}
                ml={-25}
                mt={8}
                h={25}
                pos={'relative'}
                onClick={() => {
                  if (isNotBlank(searchNumSerial) && !loading) {
                    setOneSearch(true);
                    onSearch(searchTypeProduct, searchNumSerial, searchModel);
                  }
                }}
              >
                <Search size={20} strokeWidth={2} color={'gray'} />
              </UnstyledButton>
            </div>
            <Group
              position="right"
              noWrap={true}
              className={styles.FilterOptionRight}
            >
              <Select
                key={loading ? 1 : 0}
                value={filterTypeProduct}
                className={styles.FilterTypeProduct}
                searchable
                nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
                data={optionsTypeProduct}
                clearable={true}
                placeholder={t('FILTER_TYPE_PRODUCT')}
                onChange={onChangeSelectTypeProduct}
                onSearchChange={setTypedProduct}
                disabled={loading}
              />
              <MultiSelect
                key={loading ? 2 : 3}
                value={filterModel}
                classNames={classes}
                className={styles.FilterTypeProduct}
                placeholder={t('FILTER_MODEL')}
                searchable
                nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
                data={optionsModel}
                onChange={onChangeModel}
                disabled={loading}
              />
            </Group>
          </Group>
          <Group grow>
            <TableSort<WithActions<Instrument>>
              loading={loading}
              numberOfSkeleton={size}
              isSorted={false}
              data={data.map((d) => {
                return {
                  ...parserInstrumentFromApiModel(d),
                  actions: '',
                };
              })}
              headers={headers}
            />
          </Group>
          <Group mt={15}>
            <Pagination
              className={styles.Pagination}
              page={page}
              onChange={(p) => findAll(p)}
              total={total}
              disabled={loading}
            />
          </Group>
        </>
      </Card>

      {isLoaded && <LoadingOverlay visible overlayBlur={0.3} />}

      <InstrumentsModal
        instrumentId={instrumentsId}
        opened={openInstrumentModal}
        onClose={() => {
          setInstrumentModal(false);
          setInstrumentEditing('');
        }}
        onSave={() => {
          setInstrumentModal(false);
          setInstrumentEditing('');
          findAll(page);
        }}
      />
    </>
  );
}
