/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedRollingStockDto } from '../models/PaginatedRollingStockDto';
import type { RollingStockDto } from '../models/RollingStockDto';
import type { RollingStockResponseDto } from '../models/RollingStockResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RollingStocksApi {

    /**
     * @returns PaginatedRollingStockDto 
     * @throws ApiError
     */
    public static findAll({
select,
orderBy,
search,
size,
page,
}: {
select?: string,
orderBy?: string,
search?: string,
size?: number,
page?: number,
}): CancelablePromise<PaginatedRollingStockDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/rollingstocks',
            query: {
                'select': select,
                'orderBy': orderBy,
                'search': search,
                'size': size,
                'page': page,
            },
        });
    }

    /**
     * @returns string 
     * @throws ApiError
     */
    public static create({
formData,
}: {
formData: {
file?: Blob;
body: string;
},
}): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/rollingstocks',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns RollingStockDto 
     * @throws ApiError
     */
    public static findOne({
id,
}: {
id: string,
}): CancelablePromise<RollingStockDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/rollingstocks/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns RollingStockResponseDto 
     * @throws ApiError
     */
    public static update({
id,
formData,
}: {
id: string,
formData: {
file?: Blob;
body?: string;
},
}): CancelablePromise<RollingStockResponseDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/rollingstocks/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns string 
     * @throws ApiError
     */
    public static remove({
id,
deletenote,
}: {
id: string,
deletenote: string,
}): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/rollingstocks/{id}',
            path: {
                'id': id,
            },
            query: {
                'deletenote': deletenote,
            },
        });
    }

}
