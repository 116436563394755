import * as reactRouterDom from 'react-router-dom';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import {
  getSuperTokensRoutesForReactRouterDom,
  SuperTokensWrapper,
} from 'supertokens-auth-react';
import './lib/supertokens/supertokens';
import { Layout } from './components/Layout/Layout';
import { theme } from './lib/theme/theme';
import { AccountProvider } from './hooks/useAccount';
import { Error404 } from './pages/Error404/Error404';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { OpenAPI } from './api';
import { DefaultPath, Paths } from './hooks/useNavigationLinks';

import { SessionAuth } from 'supertokens-auth-react/recipe/session';

import { RolesProvider } from './hooks/useRoles';
import { Auth } from './pages/Auth/Auth';
import React from 'react';
import { Instruments } from './pages/Instruments/Instruments';
import { SignUpForm } from './pages/SignUpForm/SignUpForm';
import { LayoutAuth } from './components/LayoutAuth/LayoutAuth';
import { ResetPasswordForm } from './pages/ResetPasswordForm/ResetPasswordForm';
import { UpdatePassword } from './pages/UpdatePassword/UpdatePassword';
import { VerifyEmailForm } from './pages/VerifyEmailForm/VerifyEmailForm';
import { ConfirmVerifyForm } from './pages/ConfirmVerifyForm/ConfirmVerifyForm';
import { SignInForm } from './pages/SignInForm/SignInForm';
import { Profile } from './pages/Profile/Profile';
import { Tickets } from './pages/Tickets/Tickets';
import { TicketDetail } from './pages/Tickets/TicketDetail';
import { PublicRoute } from './pages/PublicRoute/PublicRoute';
const queryClient = new QueryClient();

OpenAPI.BASE = process.env.REACT_APP_BACKEND_URL || '';

const App = () => {
  return (
    <MantineProvider
      theme={theme}
      withCSSVariables
      withGlobalStyles
      withNormalizeCSS
    >
      <ModalsProvider>
        <NotificationsProvider position="top-center">
          {/*position="top-center"*/}
          <QueryClientProvider client={queryClient}>
            <SuperTokensWrapper>
              <AccountProvider>
                <RolesProvider>
                  <BrowserRouter>
                    <Routes>
                      {/*Your app routes*/}
                      <Route path="/" element={<Navigate to={DefaultPath} />} />

                      <Route path={Paths.AUTH} element={<Auth />} />

                      <Route
                        path={Paths.SIGNUP}
                        element={
                          <PublicRoute>
                            <LayoutAuth>
                              <SignUpForm />
                            </LayoutAuth>
                          </PublicRoute>
                        }
                      />

                      <Route
                        path={Paths.SIGNIN}
                        element={
                          <PublicRoute>
                            <LayoutAuth>
                              <SignInForm />
                            </LayoutAuth>
                          </PublicRoute>
                        }
                      />
                      <Route
                        path={Paths.RESETPASSWORD}
                        element={
                          <PublicRoute>
                            <LayoutAuth>
                              <ResetPasswordForm />
                            </LayoutAuth>
                          </PublicRoute>
                        }
                      />
                      <Route
                        path={Paths.UPDATEPASSWORDFORM}
                        element={
                          <PublicRoute>
                            <LayoutAuth>
                              <UpdatePassword />
                            </LayoutAuth>
                          </PublicRoute>
                        }
                      />
                      <Route
                        path={Paths.VERIFY_EMAIL}
                        element={
                          <PublicRoute>
                            <LayoutAuth>
                              <VerifyEmailForm />
                            </LayoutAuth>
                          </PublicRoute>
                        }
                      />

                      <Route
                        path={Paths.CONFIRM_VERIFY_EMAIL}
                        element={
                          <PublicRoute>
                            <ConfirmVerifyForm />
                          </PublicRoute>
                        }
                      />

                      <Route
                        path={Paths.INSTRUMENTS}
                        element={
                          <SessionAuth>
                            <Auth>
                              <Layout>
                                <Instruments />
                              </Layout>
                            </Auth>
                          </SessionAuth>
                        }
                      />
                      <Route
                        path={Paths.TICKETS}
                        element={
                          <SessionAuth>
                            <Auth>
                              <Layout>
                                <Tickets />
                              </Layout>
                            </Auth>
                          </SessionAuth>
                        }
                      />
                      <Route
                        path={Paths.TICKETDETAIL + '/:id'}
                        element={
                          <SessionAuth>
                            <Auth>
                              <Layout>
                                <TicketDetail />
                              </Layout>
                            </Auth>
                          </SessionAuth>
                        }
                      />
                      <Route
                        path={Paths.USER_PROFILE_INFO}
                        element={
                          <SessionAuth>
                            <Auth>
                              <Layout>
                                <Profile />
                              </Layout>
                            </Auth>
                          </SessionAuth>
                        }
                      />
                      <Route path="*" element={<Error404 />} />
                    </Routes>
                  </BrowserRouter>
                </RolesProvider>
              </AccountProvider>
            </SuperTokensWrapper>
          </QueryClientProvider>
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  );
};

export default App;
