/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OptionsDTO } from '../models/OptionsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CountriesAndCitiesApi {

    /**
     * @returns OptionsDTO 
     * @throws ApiError
     */
    public static findCountries({
language,
}: {
language?: string,
}): CancelablePromise<OptionsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/countriesAndCities/countries',
            query: {
                'language': language,
            },
        });
    }

    /**
     * @returns OptionsDTO 
     * @throws ApiError
     */
    public static findProvinces({
countryCode,
language,
}: {
countryCode: string,
language?: string,
}): CancelablePromise<OptionsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/countriesAndCities/provinces',
            query: {
                'language': language,
                'countryCode': countryCode,
            },
        });
    }

    /**
     * @returns OptionsDTO 
     * @throws ApiError
     */
    public static findCities({
countryCode,
provinceName,
language,
}: {
countryCode: string,
provinceName: string,
language?: string,
}): CancelablePromise<OptionsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/countriesAndCities/cities',
            query: {
                'language': language,
                'countryCode': countryCode,
                'provinceName': provinceName,
            },
        });
    }

}
