import { Title } from '@mantine/core';
import styles from './TicketStatusBox.module.scss';

interface TicketStatusBox {
  statusTitle: string;
  numberOfCurrentStatus?: any;
}

export function TicketStatusBox({
  statusTitle,
  numberOfCurrentStatus,
}: TicketStatusBox) {
  return (
    <div className={styles.MainBox}>
      <div className={styles.ContainerDiv}>
        <div className={styles.StatusTitle}> {statusTitle}</div>
      </div>
      <div className={styles.NumberOfStatus}> {numberOfCurrentStatus}</div>
    </div>
  );
}
