import { Title } from '@mantine/core';
import styles from './HeaderDetailPreventive.module.scss';
import { ReactNode } from 'react';
import InfoIcon from '../icons/InfoIcon';

interface HeaderDetailPreventive {
  title: string;
  icon?: ReactNode;
}

export function HeaderDetailPreventive({
  title,
  icon,
}: HeaderDetailPreventive) {
  return (
    <>
      <div className={styles.MainBlock}>
        <div>
          <InfoIcon />
        </div>
        <div>
          <Title className={styles.DetailHeaderTitle}>{title}</Title>
        </div>
        <div className={styles.DetailHeaderIcon}>{icon}</div>
      </div>
    </>
  );
}
