import { OptionHeader } from '../OptionHeader/OptionHeader';
import {
  Grid,
  MultiSelect,
  Select,
  Textarea,
  TextInput,
  Button,
  Group,
  UnstyledButton,
  Loader,
} from '@mantine/core';
import InfoIcon from '../icons/InfoIcon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDto, RollingStockDto } from '../../api';
import { UploadFile } from '../UploadFile/UploadFile';
import { capitalizeFirstLetter, isBlank, isNotBlank } from '../../lib/utils';
import { useFileInput } from '../../hooks/useFileInput';
import { FooterPrice } from '../FooterPrice/FooterPrice';
import {
  CreateJobOrder,
  useUpsetJobOrder,
} from '../../pages/Tickets/useUpsetJobOrder';
import { getNameFile } from '../../lib/crmUtils';
import DeleteJobOrderIcon from '../icons/DeleteJobOrderIcon';

import { ModalDeleteJobOrder } from '../ModalDeleteJobOrder/ModalDeleteJobOrder';
import { AvailableRollingStock } from '../../lib/constantCrm';
import { OptionObject } from '../../lib/types';

interface JobOrder {
  idTicket: number;
  numberOfJobOrder: number;
  editJobOrderId?: string | null;
  rollingStocksAvailable: RollingStockDto[];
  loadingRollingStocks: boolean;
  jobOrder: CreateJobOrder;
  jobOrderCreated: CreateJobOrder[];

  company: CompanyDto;
  loadingSaveJobOrder: boolean;
  onSave: (jobOrder: CreateJobOrder) => void;
  onUpdateMode: (numberOfJobOrder: number) => void;
  onDelete: () => void;
}

export function JobOrder({
  idTicket,
  numberOfJobOrder,
  editJobOrderId,
  rollingStocksAvailable,
  loadingRollingStocks,
  loadingSaveJobOrder,
  company,
  jobOrder,
  jobOrderCreated = [],
  onSave,
  onDelete,
  onUpdateMode,
}: JobOrder) {
  const { t } = useTranslation();
  const maxLength = 250;
  const minRows = 5.3;
  const actNumberOfJobOrder = numberOfJobOrder + 1;
  const optionsInterventionType = [
    {
      value: 'Repair',
      label: t('INTERVENTION_TYPE1_REPAIR'),
    },
    {
      value: 'Calibration',
      label: t('INTERVENTION_TYPE2_CALIBRATION'),
    },
    {
      value: 'CalibrationReport',
      label: t('INTERVENTION_TYPE3_CALIBRATION_REPORT'),
    },
  ];

  const { fileErrorMessage, isValidFile, clearInput, fieldValue } =
    useFileInput();
  const { onSubmit, form, loading } = useUpsetJobOrder(idTicket);
  const [interventionTypeObj, setInterventionTypeObj] = useState(
    optionsInterventionType,
  );
  const [deleteJobOrderModal, setDeleteJobOrderModal] = useState(false);

  useEffect(() => {
    jobOrder.ticketId = idTicket;
    if (!jobOrder.phoneRef)
      jobOrder.phoneRef =
        company.phones.length > 0 ? company.phones[0].value : '';
    form.setValues({ ...jobOrder });
  }, [jobOrderCreated, idTicket, company.phones, jobOrder]);

  useEffect(() => {
    if (
      jobOrder.serialN &&
      rollingStocksAvailable &&
      rollingStocksAvailable.length > 0
    )
      filterInterventionType(jobOrder.serialN);
  }, [jobOrderCreated, rollingStocksAvailable, jobOrder]);

  const setJobOrderInUpdateMode = useCallback(() => {
    onUpdateMode(numberOfJobOrder);
  }, [jobOrder.rollingStockId, jobOrderCreated, numberOfJobOrder]);

  /*file for intervention*/
  const attachedFileForIntervention = useMemo(() => {
    const nameFile = form.values.fileInterventionAttach;
    if (nameFile) return nameFile;
    else if (
      !form.values.interventionAttach.deleted &&
      isNotBlank(form.values.interventionAttach.value)
    ) {
      return new File(
        [],
        getNameFile(form.values.interventionAttach.value || ''),
      );
    }
    return null;
  }, [form.values.interventionAttach, form.values.fileInterventionAttach]);

  /*file for purchase*/
  const attachedFile = useMemo(() => {
    const nameFile = form.values.filePurchaseAttach;

    if (nameFile) return nameFile;
    else if (
      !form.values.purchaseAttach.deleted &&
      isNotBlank(form.values.purchaseAttach.value)
    ) {
      return new File([], getNameFile(form.values.purchaseAttach.value || ''));
    }
    return null;
  }, [
    form.values.purchaseAttach,
    form.values.filePurchaseAttach,
    jobOrderCreated,
  ]);

  const removeFile = useCallback(
    (field: string) => {
      clearInput;
      form.setFieldValue(`${field}.deleted`, true);
      form.setFieldValue(`file${capitalizeFirstLetter(field)}`, undefined);
    },
    [form, clearInput],
  );

  const onChangeFile = useCallback(
    (value: File | null, field: string) => {
      if (value != null) {
        if (isValidFile(value, field)) {
          form.setFieldValue(field, value);
        }
      }
    },
    [form, isValidFile],
  );

  const onChangeSerialN = useCallback(
    (value: string) => {
      const rollingStock: RollingStockDto[] | undefined =
        rollingStocksAvailable.filter((d) => {
          return value == d.internalIdentification;
        });
      if (rollingStock) {
        form.setFieldValue('rollingStockId', rollingStock[0].id);
        form.setFieldValue('serialN', value);
        form.setFieldValue('model', rollingStock[0].model);
        form.setFieldValue(
          'repairPrice',
          Number(rollingStock[0].FF_GT06_EXT003) || 0,
        );
        form.setFieldValue(
          'calibrationPrice',
          Number(rollingStock[0].FF_GT06_EXT009) || 0,
        );
        form.setFieldValue(
          'calibrationReportPrice',
          Number(rollingStock[0].FF_GT06_EXT007) || 0,
        );

        rollingStock[0]['freeFieldsRolling'].forEach((val) => {
          if (val.code == 'purchaseattach') {
            form.setFieldValue('purchaseAttach.value', val.value);
            form.setFieldValue('purchaseAttach.deleted', false);
          }
        });

        form.setFieldValue('jobOrderType.FF_ACCREDIA', false);
        form.setFieldValue('jobOrderType.FF_Riparazione', false);
        form.setFieldValue('jobOrderType.FF_Calibrazione', false);

        filterInterventionType(value);
      }
    },

    [rollingStocksAvailable, form],
  );

  function deleteVal(valSelected: string) {
    const options = [...interventionTypeObj];
    for (let i = 0; i < options.length; i++) {
      const { value } = options[i];
      if (value == valSelected) {
        options.splice(i, 1);
        break;
      }
    }
    setInterventionTypeObj(options);
  }
  const getOnChange = useCallback(
    (values: any) => {
      if (values.includes('Calibration')) deleteVal('CalibrationReport');
      else if (values.includes('CalibrationReport')) deleteVal('Calibration');
      else filterInterventionType(form.values.serialN);
      form.clearFieldError('jobOrderType');
      form.clearFieldError('note');
      //Update type of intervention
      setFormField(values);
      onUpdateMode(numberOfJobOrder);
    },
    [interventionTypeObj, numberOfJobOrder],
  );

  function filterInterventionType(serialN: string) {
    if (!serialN && !rollingStocksAvailable) return;
    const rollingStock: RollingStockDto[] | undefined =
      rollingStocksAvailable.filter((d) => {
        return serialN == d.internalIdentification;
      });

    if (
      rollingStock &&
      rollingStock.length > 0 &&
      rollingStock[0].FF_GT06_GRSTAT4 == AvailableRollingStock.FPN
    ) {
      const options: OptionObject[] = optionsInterventionType.filter(
        (intervention) => intervention.value != 'Repair',
      );
      setInterventionTypeObj(options);
    } else {
      setInterventionTypeObj(optionsInterventionType);
    }
  }

  function setFormField(values: any) {
    form.setFieldValue('jobOrderType.FF_ACCREDIA', false);
    form.setFieldValue('jobOrderType.FF_Riparazione', false);
    form.setFieldValue('jobOrderType.FF_Calibrazione', false);
    for (let i = 0; i <= values.length; i++) {
      if (values.includes('Repair'))
        form.setFieldValue('jobOrderType.FF_Riparazione', true);
      if (values.includes('Calibration'))
        form.setFieldValue('jobOrderType.FF_ACCREDIA', true);
      if (values.includes('CalibrationReport'))
        form.setFieldValue('jobOrderType.FF_Calibrazione', true);
    }
  }

  const isInEditMode = useMemo(() => {
    return isBlank(editJobOrderId)
      ? false
      : Number(editJobOrderId) != jobOrder.id;
  }, [editJobOrderId, jobOrder]);

  const isDeleted = useMemo(() => {
    return isBlank(editJobOrderId);
  }, [editJobOrderId, jobOrder]);

  const isUsedJobOrder = useCallback(
    (rolling: RollingStockDto) => {
      if (rolling.internalIdentification == jobOrder.serialN) return false;

      const isUsedRolling = jobOrderCreated.some(
        (job) => job.serialN == rolling.internalIdentification,
      );

      return isUsedRolling;
    },
    [jobOrder, jobOrderCreated, editJobOrderId],
  );

  const optionsRolling = useMemo(() => {
    if (loadingRollingStocks) return [];
    const options: any[] = [];

    rollingStocksAvailable.map((d: RollingStockDto) => {
      if (!isUsedJobOrder(d)) {
        options.push({
          value: d.internalIdentification,
          label: d.internalIdentification,
        });
      }
    });
    return options;
  }, [rollingStocksAvailable, loadingRollingStocks, isUsedJobOrder]);

  const getInterventionType = useMemo(() => {
    const initInterventionType: string[] = [];
    if (form.values.jobOrderType.FF_Riparazione)
      initInterventionType.push('Repair');
    if (form.values.jobOrderType.FF_ACCREDIA)
      initInterventionType.push('Calibration');
    if (form.values.jobOrderType.FF_Calibrazione)
      initInterventionType.push('CalibrationReport');
    return initInterventionType;
  }, [
    form.values.jobOrderType.FF_ACCREDIA,
    form.values.jobOrderType.FF_Riparazione,
    form.values.jobOrderType.FF_Calibrazione,
  ]);

  return (
    <>
      <form
        onSubmit={onSubmit(async (values: CreateJobOrder) => {
          onSave(values);
        })}
      >
        {deleteJobOrderModal && (
          <ModalDeleteJobOrder
            idJobOrder={jobOrder.id || 0}
            title={t('MODAL_DELETE_JOBORDER_TITLE', {
              count: 1,
            })}
            headerTitle={t('MODAL_DELETE_JOBORDER_SUB_TITLE', {
              count: 1,
            })}
            subTitle={t('MODAL_DELETE_JOBORDER_LABEL_AND_PLACEHOLDER')}
            placeHolder={t('MODAL_DELETE_JOBORDER_LABEL_AND_PLACEHOLDER')}
            onClickButtonAbort={() => setDeleteJobOrderModal(false)}
            onClose={() => setDeleteJobOrderModal(false)}
            onDelete={() => {
              onDelete();
              setDeleteJobOrderModal(false);
            }}
            opened={deleteJobOrderModal}
            buttonLabel={t('MODAL_DELETE_JOBORDER_BUTTON_CONFIRM', {
              count: numberOfJobOrder,
            })}
          />
        )}
        <OptionHeader
          title={t('JOB_ORDER') + ' ' + actNumberOfJobOrder}
          icon={
            isDeleted ? (
              <UnstyledButton
                onClick={() => {
                  setDeleteJobOrderModal(true);
                }}
              >
                <DeleteJobOrderIcon />
              </UnstyledButton>
            ) : null
          }
        />
        <Grid m={'xs'}>
          <Grid.Col span={2} mb={'md'}>
            <Select
              label={t('NUMBER_SERIAL')}
              placeholder={t('SELECT_SERIAL_NUMBER')}
              data={optionsRolling}
              nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
              {...form.getInputProps('serialN')}
              onChange={onChangeSerialN}
              withAsterisk
              onDropdownOpen={setJobOrderInUpdateMode}
              rightSection={
                loadingRollingStocks &&
                Number(editJobOrderId) == jobOrder.id &&
                Number(editJobOrderId) != 0 && <Loader size={'sm'} />
              }
              disabled={
                isInEditMode ||
                loading ||
                (loadingRollingStocks &&
                  (isBlank(editJobOrderId) || Number(editJobOrderId) == 0))
              }
            />
          </Grid.Col>
          <Grid.Col span={2} mb={'md'}>
            <TextInput
              label={t('MODEL')}
              placeholder={t('TICKET_MODEL_PRODUCT')}
              {...form.getInputProps('model')}
              withAsterisk
              onClick={setJobOrderInUpdateMode}
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col span={2} mb={'md'}>
            <MultiSelect
              label={t('INTERVENTION_TYPE')}
              placeholder={t('SELECT_TICKET') + ' ' + t('INTERVENTION_TYPE')}
              data={interventionTypeObj}
              {...form.getInputProps('jobOrderType')}
              value={getInterventionType}
              onChange={getOnChange}
              withAsterisk
              onDropdownOpen={setJobOrderInUpdateMode}
              disabled={
                isInEditMode ||
                loading ||
                !form.values.serialN ||
                loadingRollingStocks
              }
              clearable
            />
          </Grid.Col>

          {/*<Grid.Col span={2} mb={'md'}>*/}
          {/*  <Select*/}
          {/*    label={t('FAULT_TYPE')}*/}
          {/*    placeholder={t('SELECT_TICKET') + ' ' + t('FAULT_TYPE')}*/}
          {/*    data={[*/}
          {/*      { value: 'Easytest', label: 'Easytest' },*/}
          {/*      { value: 'Equitest', label: 'Equitest' },*/}
          {/*      { value: 'Ev-test-100', label: 'Ev-test-100' },*/}
          {/*      { value: 'Jupiter', label: 'Jupiter' },*/}
          {/*    ]}*/}
          {/*    {...form.getInputProps(`jobOrder.${numberOfJobOrder}.faultType`)}*/}
          {/*    withAsterisk*/}
          {/*  />*/}
          {/*</Grid.Col>*/}

          <Grid.Col span={2} mb={'md'}>
            <TextInput
              label={
                <p style={{ margin: 'auto' }}>
                  {t('EMAIL_CONTACT_PERSON') + ' '} <InfoIcon></InfoIcon>
                </p>
              }
              placeholder={t('EMAIL_CONTACT_PERSON')}
              {...form.getInputProps('emailRef')}
              onClick={setJobOrderInUpdateMode}
              disabled={isInEditMode || loading || loadingRollingStocks}
            />
          </Grid.Col>
          <Grid.Col span={2} mb={'md'}>
            <TextInput
              label={t('PHONE_CONTACT_PERSON')}
              placeholder={t('PHONE_CONTACT_PERSON')}
              {...form.getInputProps('phoneRef')}
              onClick={setJobOrderInUpdateMode}
              withAsterisk
              disabled={isInEditMode || loading || loadingRollingStocks}
            />
          </Grid.Col>
          <Grid.Col span={6} mb={'md'}>
            <Textarea
              label={t('NOTE')}
              placeholder={t('INSERT_NOTE')}
              maxLength={maxLength}
              minRows={minRows}
              autosize
              withAsterisk={form.values.jobOrderType.FF_Riparazione}
              {...form.getInputProps('note')}
              onClick={setJobOrderInUpdateMode}
              disabled={isInEditMode || loading || loadingRollingStocks}
            />
          </Grid.Col>
          <Grid.Col span={5} mb={'md'}>
            <UploadFile
              file={attachedFile}
              fileErrorMessage={
                fieldValue == 'filePurchaseAttach' ? fileErrorMessage : ''
              }
              clearInput={() => removeFile('purchaseAttach')}
              onChangeFile={(value) =>
                onChangeFile(value, 'filePurchaseAttach')
              }
              typeOfFileToAttach={t('INFO_MODAL_BOX_INPUT_FILE')}
              onClick={setJobOrderInUpdateMode}
              disabled={isInEditMode || loading || loadingRollingStocks}
            />
            <UploadFile
              file={attachedFileForIntervention}
              fileErrorMessage={
                fieldValue == 'fileInterventionAttach' ? fileErrorMessage : ''
              }
              clearInput={() => removeFile('interventionAttach')}
              onChangeFile={(value) =>
                onChangeFile(value, 'fileInterventionAttach')
              }
              typeOfFileToAttach={t('INFO_MODAL_BOX_INPUT_INTERVENTION_FILE')}
              onClick={setJobOrderInUpdateMode}
              disabled={isInEditMode || loading || loadingRollingStocks}
            />
          </Grid.Col>
          {jobOrder.id > 0 && Number(editJobOrderId) != jobOrder.id && (
            <Grid.Col span={12}>
              {form.values.jobOrderType.FF_Riparazione && (
                <div style={{ paddingBottom: 10 }}>
                  <FooterPrice
                    title={t('AMOUNT') + ' ' + t('INTERVENTION_TYPE1_REPAIR')}
                    iconPrice={form.values.repairPrice + ' €'}
                  />
                </div>
              )}
              {form.values.jobOrderType.FF_ACCREDIA && (
                <div style={{ paddingBottom: 10 }}>
                  <FooterPrice
                    title={
                      t('AMOUNT') + ' ' + t('INTERVENTION_TYPE2_CALIBRATION')
                    }
                    iconPrice={form.values.calibrationPrice + ' €'}
                  />
                </div>
              )}
              {form.values.jobOrderType.FF_Calibrazione && (
                <FooterPrice
                  title={
                    t('AMOUNT') +
                    ' ' +
                    t('INTERVENTION_TYPE3_CALIBRATION_REPORT')
                  }
                  iconPrice={form.values.calibrationReportPrice + ' €'}
                />
              )}
            </Grid.Col>
          )}
          {Number(editJobOrderId) == jobOrder.id && (
            <Grid.Col span={12}>
              <Group position="right">
                <Button
                  type="submit"
                  disabled={loadingRollingStocks || loadingSaveJobOrder}
                >
                  {t('BUTTON_CONFIRM_JOB_ORDER')}
                </Button>
              </Group>
            </Grid.Col>
          )}
        </Grid>
      </form>
    </>
  );
}
