import styles from '../../components/NoInstrumentsBox/NoInstrumentsBox.module.scss';
import Emoticon from '../icons/Emoticon';

interface NoInstrumentsBoxProps {
  title: string;
  subtitle: string;
}

export function NoInstrumentsBox({ title, subtitle }: NoInstrumentsBoxProps) {
  return (
    <div className={styles.NoInstrumentsBox}>
      <div className={styles.NoProductCard}>
        <Emoticon></Emoticon>
      </div>
      <div className={styles.TitleInfoBox}>
        {title}
        <div className={styles.SubtitleInfoBox}>{subtitle}</div>
      </div>
    </div>
  );
}
