export enum constantCrm {
  linkGeneralCondition_it = 'https://www.ht-instruments.it/media/filer_public/79/8e/798e4b6e-09e5-4edb-af05-5e41b0c78724/ht_repair_service_300.pdf',
  linkGeneralCondition_en = 'https://www.ht-instruments.it/media/filer_public/00/6c/006c6fd2-799e-43cd-b3f8-76e85e2d255b/ht_repair_service_eng_2.pdf',
}

export enum AvailableRollingStock {
  FPN = 'FPN',
  FPR = 'FPR',
  FPS = 'FPS',
  VER = 'VER',
  VES = 'VES',
}

export enum fileNameToDownload {
  TICKET_QUOTE = 'ticket_quote',
  TICKET_LABEL = 'ticket_label',
}

export enum FreeFieldsRollingStock {
  ASSIGNEDTO = 139,
  PURCHASEPLACE = 135,
  PURCHASECOUNTRY = 136,
  STOREADDRESS = 137,
  WARRCHECK = 140,
  PURCHASEATTACH = 138,
  CLIENTID = 141,
  DELETENOTE = 142,
  ENABLECHECKWARRINSERVICEREQUEST = 160,
}

export enum TypeOfAddress {
  BILLING = 'Billing',
  SHIIPING = 'Shipping',
}

export enum TypeOfDownload {
  ESTIMATE_LINK = 'Estimate link',
  RMA_LINK = 'RMA link',
}

export enum PurchasePlace {
  E_COMMERCE = 'E-Commerce',
  NEGOZIO_FISICO = 'Negozio fisico',
}
