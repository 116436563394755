export const constants = {
  DEFAULT_BACKEND_URL: 'http://localhost:8080',
  DEFAULT_WEB_URL: 'http://localhost:3000',
  DEFAULT_CACHE_TTL: 60 * 60 * 24 * 3, // in seconds, 3 days
  //   PASSWORD_REGEX:
  //     //Minimum eight characters,
  //     // at least one uppercase letter,
  //     // one lowercase letter,
  //     // one number and one special character:
  // /^(?=.*[a-z])(?=.*[A-Z])(?=.*d)(?=.*[^da-zA-Z]).{8,}$/,
  PASSWORD_REGEX:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/,
  EMAIL_REGEX: /^[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+)+[.][A-Za-z]{2,}$/,
  VAT_REGEX: /^(?:[A-Za-z0-9]+)$/,
  SEARCH_TIMESHOLD_MS: 1000,
  SDI_REGEX: /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/,
  NO_CHARACTER_IN_PHONE_REGEX: /[A-Za-z]|o|[?!\"£$%&=€]/g,
  DEFAULT_COUNTRY_CODE: 'IT',
  DEFAULT_LANGUAGE: 'it',
  SOPPORTEDLANGUGAES: ['it', 'en'] as const,
};
