import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';

export function useFileInput() {
  const { t } = useTranslation();
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const MAX_FILE_SIZE = 512 * 1024; /*1MB*/
  const [fieldValue, setFieldValue] = useState('');

  const isValidFile = useCallback((uploadedFile: File, field?: string) => {
    if (uploadedFile.size > MAX_FILE_SIZE) {
      setFileErrorMessage(t('INFO_MODAL_BOX_EXCEEDS_SIZE_ERROR'));
      setFieldValue(field || '');
      return false;
    }

    function isFile(...extensions: string[]) {
      for (const ext of extensions) {
        if (uploadedFile.name.toLowerCase().indexOf(ext.toLowerCase()) != -1) {
          return true;
        }
      }
      return false;
    }

    if (!isFile('.pdf', '.jpeg', '.png', '.jpg')) {
      setFileErrorMessage(t('INFO_MODAL_BOX_TYPE_FILE_ERROR'));
      setFieldValue(field || '');
      return false;
    }
    setFileErrorMessage('');
    return true;
  }, []);

  function clearInput() {
    setFileErrorMessage('');
    setFieldValue('');
  }
  return { clearInput, isValidFile, fileErrorMessage, fieldValue };
}
