import { Button, Grid, PasswordInput, TextInput, Alert } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useTranslation } from 'react-i18next';
import { Paths } from '../../hooks/useNavigationLinks';
import { signIn } from 'supertokens-auth-react/lib/build/recipe/emailpassword';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import styles from './SignInForm.module.scss';
import { constants } from '../../constants';
import { HeaderAuth } from '../../components/HeaderAuth/HeaderAuth';
import AlertIcon from '../../components/icons/AlertIcon';
import Session from 'supertokens-auth-react/recipe/session';

export function SignInForm() {
  const [error, setError] = useState({
    anyError: false,
    combinationError: false,
    emailSyntaxError: false,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sessionContext = Session.useSessionContext();

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },

    validate: {
      email: (value) =>
        value === ''
          ? t('ERROR_MANDATORY_FIELD')
          : constants.EMAIL_REGEX.test(value)
          ? null
          : t('ERROR_EMAIL_FORMAT'),
      password: (value) => (value === '' ? t('ERROR_MANDATORY_FIELD') : null),
    },
  });

  async function signInClicked(email: string, password: string) {
    try {
      const response = await signIn({
        formFields: [
          {
            id: 'email',
            value: email,
          },
          {
            id: 'password',
            value: password,
          },
        ],
      });

      if (response.status === 'FIELD_ERROR') {
        response.formFields.forEach((formField) => {
          if (formField.id === 'email') {
            setError({
              anyError: true,
              emailSyntaxError: true,
              combinationError: false,
            });
            // Email validation failed (for example incorrect email syntax).
          }
        });
      } else if (response.status === 'WRONG_CREDENTIALS_ERROR') {
        setError({
          anyError: true,
          combinationError: true,
          emailSyntaxError: false,
        });
      } else {
        if (sessionContext.loading) {
          return null;
        }

        //Have a Session and email is verified
        if (
          sessionContext.doesSessionExist &&
          sessionContext.accessTokenPayload['st-ev'].v
        ) {
          navigate(Paths.INSTRUMENTS);
        }
        //Have a Session but the email isn't verified
        else if (
          sessionContext.doesSessionExist &&
          !sessionContext.accessTokenPayload['st-ev'].v
        ) {
          navigate(Paths.VERIFY_EMAIL);
        }
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        console.error(err.message);
      } else {
        console.error('Server is down');
      }
    }
  }

  return (
    <>
      <div className={styles.SignInForm}>
        <HeaderAuth
          title={t('SignIn_Title')}
          subTitle={t('SignIn_Subtitle')}
          link={Paths.SIGNUP}
          textLink={t('SignIn_Link')}
        />
        {error.anyError ? (
          <Alert
            className={styles.notification}
            color="red"
            icon={<AlertIcon />}
            variant="outline"
          >
            {error.combinationError
              ? t('EMAIL_PASSWORD_SIGN_IN_WRONG_CREDENTIALS_ERROR')
              : error.emailSyntaxError
              ? t('ERROR_EMAIL_INVALID')
              : null}
          </Alert>
        ) : null}
        <form
          onSubmit={form.onSubmit((values) =>
            signInClicked(values.email, values.password),
          )}
        >
          <Grid className={styles.GirdLogIn}>
            <Grid.Col>
              <TextInput
                className={styles.TextInput}
                label={t('SignIn_EmailInputLabel')}
                placeholder={t('SignUp_EmailPlaceholder')}
                radius="xs"
                size="sm"
                withAsterisk
                {...form.getInputProps('email')}
              />
              <PasswordInput
                className={styles.TextInput}
                label={t('SignIn_PasswordInputLabel')}
                placeholder={t('SignUp_PasswordPlaceholder')}
                radius="xs"
                size="sm"
                withAsterisk
                {...form.getInputProps('password')}
              />
            </Grid.Col>
            <Grid.Col>
              <Button type={'submit'}>{t('SignIn_ButtonLabel')}</Button>
            </Grid.Col>
            <Grid.Col>
              <a href={Paths.RESETPASSWORD} className={styles.HLink}>
                {t('EMAIL_PASSWORD_SIGN_IN_FOOTER_FORGOT_PW_LINK')}
              </a>
            </Grid.Col>
          </Grid>
        </form>
      </div>
    </>
  );
}
