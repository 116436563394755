import { CountriesAndCitiesApi } from '../../api';
import { useEffect, useMemo, useState } from 'react';
import { OptionObject } from '../../lib/types';
import { removeLocaleLanguage } from '../../lib/utils';
import i18next from 'i18next';
import { useAccount } from '../../hooks/useAccount';

export function useCountry() {
  const { account } = useAccount();
  const [loadingCountry, setLoadingCountry] = useState(false);
  const [listCountries, setCountries] = useState<OptionObject[]>([]);

  const language = useMemo(() => {
    return account?.language || i18next.language;
  }, [account?.language]);

  useEffect(() => {
    async function getContries() {
      setLoadingCountry(true);
      const listCountries = await CountriesAndCitiesApi.findCountries({
        language: removeLocaleLanguage(language),
      });
      setCountries(listCountries);
      setLoadingCountry(false);
    }

    language && getContries();
  }, [language]);

  return {
    listCountries,
    loadingCountry,
  };
}
