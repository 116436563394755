import { Key, useCallback, useState, MouseEvent } from 'react';
import styles from '../DropDown/DropDown.module.scss';
import { UserCircle } from 'tabler-icons-react';
import RectangleMenu from '../icons/RectangleMenu';
import { useTranslation } from 'react-i18next';
import { signOut } from 'supertokens-auth-react/recipe/emailpassword';
import { useNavigate } from 'react-router-dom';

interface DropDown {
  link: object[];
}

export function DropDown({ link }: DropDown) {
  const [openNavLink, setOpenNavLink] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openDropDown = useCallback(() => {
    !openNavLink ? setOpenNavLink(true) : setOpenNavLink(false);
  }, [openNavLink]);

  const onClickItemMenu = useCallback(
    async (item: { label: string; link: string }) => {
      if (item.label == t('logout')) await signOut();
      navigate(item.link);
    },
    [link],
  );

  return (
    <div className={styles.MainDivDropDown}>
      <UserCircle
        size={30}
        strokeWidth={1.5}
        color={'#004B98'}
        onClick={() => {
          openDropDown();
        }}
      />
      <div className={styles.ContainerLink}>
        {openNavLink && (
          <>
            <div className={styles.RectangleDiv}>
              <RectangleMenu />
            </div>
            <ul className={styles.UlStyle}>
              {link.map((value: any | string, index: Key) => (
                <li className={styles.ListStyle} key={index}>
                  <a
                    className={styles.TextDecoration}
                    onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                      e.preventDefault();
                      onClickItemMenu(value);
                    }}
                  >
                    {value.label}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
}
