import { useAccount } from '../../hooks/useAccount';
import { useApiNotification } from '../../hooks/useApiNotification';
import { useForm } from '@mantine/form';
import { CompaniesApi, UpdateCompanyDto } from '../../api';
import {
  isAlphaNumericCodSdi,
  isBlank,
  isNotBlank,
  isValidPhone,
} from '../../lib/utils';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../hooks/useCompany';

export function useUpdateCompany() {
  const { account } = useAccount();
  const { company } = useCompany();
  const { t } = useTranslation();
  const { showError, showSuccess } = useApiNotification();

  const form = useForm({
    initialValues: {
      phone: '',
      FF_GT02_EXT003: '',
      state: '',
    },

    validate: {
      phone: (value) => {
        if (company?.phones[0] && isNotBlank(company?.phones[0].value))
          return null;
        else return !isValidPhone(value) ? t('ERROR_PHONE_FORMAT') : null;
      },
      FF_GT02_EXT003: (value) =>
        !isAlphaNumericCodSdi(value) || value.length != 7
          ? t('ERROR_SDI_LEN')
          : null,
      state: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
    },
  });

  async function handleSubmit(values: {
    phone: string;
    FF_GT02_EXT003: string;
    state: string;
  }): Promise<{ success: boolean }> {
    try {
      const requestBody: UpdateCompanyDto = {
        phones: isNotBlank(values.phone) ? [{ value: values.phone }] : [],
        FF_GT02_EXT003: values.FF_GT02_EXT003,
        state: values.state,
      };

      await CompaniesApi.update({
        id: String(account?.companyId),
        requestBody,
      });

      showSuccess();
      return { success: true };
    } catch (error) {
      showError();
      return { success: false };
    }
  }

  return {
    form,
    onSubmit: form.onSubmit,
    handleSubmit,
  };
}
