import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { CompaniesApi, TicketApi, TicketDto } from '../../api';
import { useApiNotification } from '../../hooks/useApiNotification';
import { statusCodeCRMOfTicket } from './parser/parserStatusTicket';
import { useDownloadItemGenerate } from '../../hooks/useDownloadItemGenerate';
import { fileNameToDownload } from '../../lib/constantCrm';
import { useAccount } from '../../hooks/useAccount';
import {
  AddressDispatch,
  AddressZ,
} from '../../components/AddressDispatch/useUpdateAddressDispatch';
import { parserAddressDispatchToCompanyDto } from '../../components/AddressDispatch/parser/parserAddressDispatch';
import { isAlphaNumericCodSdi, isBlank } from '../../lib/utils';
import { useTranslation } from 'react-i18next';
import { CreateJobOrder, useUpsetJobOrder } from './useUpsetJobOrder';

export const CreateTicketZ = z.object({
  FF_flagvexatiousitems: z.boolean(),
  FF_flagacceptedservice: z.boolean(),
  FF_GT02_EXT003: z.string(),
  billAddress: AddressZ,
  isShippingAddress: z.boolean(),
  shippingAddress: AddressZ,
  FF_GT_SYNC: z.boolean(),
});

export type CreateTicketForm = z.infer<typeof CreateTicketZ>;

export function useUpsetTicket() {
  const { t } = useTranslation();
  const { showSuccess, showError } = useApiNotification();
  const { createPdf } = useDownloadItemGenerate();
  const { updateJobOrderToOpenIntervention } = useUpsetJobOrder();
  const { account } = useAccount();
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState<TicketDto | null>(null);

  const createTicketOnce = useRef(false);

  useEffect(() => {
    if (createTicketOnce.current) return;

    (async () => {
      const createNewTicket = await TicketApi.create({
        requestBody: {
          status: statusCodeCRMOfTicket.OBSOLETE,
        },
      });
      TicketApi.findOne({
        id: String(createNewTicket) || '',
      }).then((responseTicket) => {
        setTicket(responseTicket);
      });
    })();

    createTicketOnce.current = true;
  }, [account]);

  const form = useForm<CreateTicketForm>({
    validate: {
      FF_GT02_EXT003: (value) =>
        !isAlphaNumericCodSdi(value) || value.length != 7
          ? t('ERROR_SDI_LEN')
          : null,
      billAddress: {
        state: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
        address: (value) =>
          isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null,
        city: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
        province: (value) =>
          isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null,
        zipCode: (value) =>
          isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null,
      },
      shippingAddress: {
        state: (value, values) =>
          isBlank(value) && !values.isShippingAddress
            ? t('ERROR_MANDATORY_FIELD')
            : null,
        address: (value, values) =>
          isBlank(value) && !values.isShippingAddress
            ? t('ERROR_MANDATORY_FIELD')
            : null,
        city: (value, values) =>
          isBlank(value) && !values.isShippingAddress
            ? t('ERROR_MANDATORY_FIELD')
            : null,
        province: (value, values) =>
          isBlank(value) && !values.isShippingAddress
            ? t('ERROR_MANDATORY_FIELD')
            : null,
        zipCode: (value, values) =>
          isBlank(value) && !values.isShippingAddress
            ? t('ERROR_MANDATORY_FIELD')
            : null,
      },
    },

    initialValues: {
      FF_flagvexatiousitems: false,
      FF_flagacceptedservice: false,
      FF_GT02_EXT003: '',
      billAddress: {
        state: '',
        address: '',
        city: '',
        province: '',
        labelProvince: '',
        zipCode: '',
      },
      isShippingAddress: true,
      FF_GT_SYNC: false,
      shippingAddress: {
        state: '',
        address: '',
        city: '',
        province: '',
        labelProvince: '',
        zipCode: '',
      },
    },
  });

  async function handleSubmitTicket(
    values: CreateTicketForm,
    jobOrderTasks: CreateJobOrder[],
  ): Promise<{ success: boolean } | { success: boolean; error: Error }> {
    try {
      setLoading(true);
      const requestTicket = {
        status: statusCodeCRMOfTicket.AWAITING_RMA,
        FF_flagvexatiousitems: values.FF_flagvexatiousitems,
        FF_flagacceptedservice: values.FF_flagacceptedservice,
      };

      const addressDispatch: AddressDispatch = {
        billAddress: { ...values.billAddress },
        shippingAddress: { ...values.shippingAddress },
        isShippingAddress: values.isShippingAddress,
      };
      const requestCompany = parserAddressDispatchToCompanyDto(addressDispatch);
      //CODSDI
      requestCompany.FF_GT02_EXT003 = values.FF_GT02_EXT003;

      CompaniesApi.update({
        id: String(account?.companyId),
        requestBody: requestCompany,
      });
      await updateJobOrderToOpenIntervention(jobOrderTasks);

      const blob: Blob = await createPdf(String(ticket?.id));
      await TicketApi.update({
        id: String(ticket?.id),
        formData: {
          file: new File([blob], fileNameToDownload.TICKET_QUOTE + '.pdf', {
            type: 'application/pdf',
          }),
          body: JSON.stringify(requestTicket),
        },
      });

      form.reset();
      showSuccess();

      return { success: true };
    } catch (error: unknown) {
      if (error instanceof Error) {
        showError();
        return { success: false, error };
      }
      return { success: false, error: new Error('Unknown server error') };
    } finally {
      setLoading(false);
    }
  }

  return {
    onSubmit: form.onSubmit,
    ticket,
    setTicket,
    loading,
    handleSubmitTicket,
    form,
  };
}
