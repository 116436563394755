import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type NavigationLink = {
  link: string;
  label: string;
};

export enum Paths {
  AUTH = '/auth',
  SIGNUP = '/signup',
  SIGNIN = '/signin',
  VERIFY_EMAIL = '/verifyEmail',
  CONFIRM_VERIFY_EMAIL = '/confirmVerifyEmail',
  USERS = '/users',
  PRODUCTS = '/products',
  TICKETS = '/tickets',
  TICKETDETAIL = '/ticketDetail',
  ACCOUNT = '/account',
  INSTRUMENTS = '/instruments',
  RESETPASSWORD = '/update-password',
  UPDATEPASSWORDFORM = '/resetpassword',
  USER_PROFILE_INFO = '/info_profile',
}

export const DefaultPath = Paths.INSTRUMENTS;

export function useNavigationLinks() {
  const location = useLocation();
  const { t } = useTranslation();

  function isLinkActive(link: NavigationLink | string) {
    if (typeof link == 'string') return location.pathname.startsWith(link);
    return location.pathname.startsWith(link.link);
  }

  const links: NavigationLink[] = [
    { link: Paths.INSTRUMENTS, label: t('my_instruments') },
    { link: Paths.TICKETS, label: t('my_tickets') },
    // { link: Paths.PRODUCTS, label: t('products'), icon: Apple },
    // { link: Paths.USERS, label: t('users'), icon: Users },
    // { link: Paths.ACCOUNT, label: t('account'), icon: UserCircle },
  ];

  return {
    links,
    isLinkActive,
  };
}
