import { Title } from '@mantine/core';
import styles from './OptionHeader.module.scss';
import { ReactNode } from 'react';

interface OptionHeader {
  title: string;
  icon?: ReactNode;
}

export function OptionHeader({ title, icon }: OptionHeader) {
  return (
    <>
      <div className={styles.MainBlock}>
        <Title className={styles.OptionHeaderTitle}>{title}</Title>
        <div>{icon}</div>
      </div>
    </>
  );
}
