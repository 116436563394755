import React from 'react';

export default function InfoIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 15C11.6423 15 15 11.6423 15 7.5C15 3.35769 11.6423 0 7.5 0C3.35769 0 0 3.35769 0 7.5C0 11.6423 3.35769 15 7.5 15ZM8.24997 3.74984C8.24997 4.16407 7.91423 4.49994 7.5 4.49994C7.08577 4.49994 6.75003 4.16407 6.75003 3.74984C6.75003 3.33561 7.08577 2.99987 7.5 2.99987C7.91423 2.99987 8.24997 3.33561 8.24997 3.74984ZM6.75003 6.74997C6.75003 6.33574 7.08577 5.99987 7.5 5.99987C7.91423 5.99987 8.24997 6.33574 8.24997 6.74997V11.25C8.24997 11.6643 7.91423 12 7.5 12C7.08577 12 6.75003 11.6643 6.75003 11.25V6.74997Z"
        fill="#616E7B"
      />
    </svg>
  );
}
