import {
  Button,
  Checkbox,
  Grid,
  Group,
  Select,
  TextInput,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { isNotBlank, removeLocaleLanguage } from '../../lib/utils';
import styles from './Address.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionObject } from '../../lib/types';
import { getLabelByCode } from '../../lib/crmUtils';
import { CountriesAndCitiesApi } from '../../api';
import { useAccount } from '../../hooks/useAccount';
import i18next from 'i18next';

interface AddressProps {
  formAddress: any;
  fieldName?: string;
  enableToUseLikeShippingAddress?: boolean;
  disabledCountry?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  showButtons: boolean;
  titleConfirmButton: string;
  listCountries: OptionObject[];
}

export function Address({
  formAddress,
  fieldName = '',
  enableToUseLikeShippingAddress = false,
  disabledCountry = false,
  onCancel = () => {},
  onSave = () => {},
  showButtons = false,
  titleConfirmButton = '',
  listCountries,
}: AddressProps) {
  const { t } = useTranslation();
  const { account } = useAccount();

  const field = isNotBlank(fieldName) ? fieldName + '.' : '';
  const [provicies, setProvinces] = useState<OptionObject[]>([]);
  const [cities, setCities] = useState<OptionObject[]>([]);

  const language = useMemo(() => {
    return account?.language || i18next.language;
  }, [account?.language]);

  useEffect(() => {
    if (isNotBlank(formAddress.values[fieldName].state)) {
      getProvinces(formAddress.values[fieldName].state);
    }
  }, [formAddress.values[fieldName].state, language]);

  useEffect(() => {
    const state = formAddress.values[fieldName].state;
    const province = formAddress.values[fieldName].province;
    if (isNotBlank(state) && isNotBlank(province)) {
      getCities(state, province);
    }
  }, [
    formAddress.values[fieldName].province,
    formAddress.values[fieldName].state,
    language,
  ]);

  const getProvinces = useCallback(
    async (countryCode: string) => {
      const provicies = await CountriesAndCitiesApi.findProvinces({
        language: removeLocaleLanguage(language),
        countryCode: countryCode,
      });
      setProvinces(provicies);
    },
    [language],
  );

  const getCities = useCallback(
    async (countryCode: string, provinceName: string, city?: string) => {
      const cities = await CountriesAndCitiesApi.findCities({
        language: removeLocaleLanguage(language),
        countryCode: countryCode,
        provinceName: provinceName,
      });

      if (city && isNotBlank(city) && !isExistInCities(cities, city)) {
        cities.push({ value: city, label: city });
      }

      setCities(cities);
    },
    [language],
  );

  function isExistInCities(cities: OptionObject[], city = '') {
    const listOfCities = cities.map((item) => item.value);
    if (listOfCities.includes(city)) return true;
    return false;
  }

  return (
    <>
      <Grid className={styles.Address}>
        <Grid.Col span={2}>
          <Select
            label={t('PURCHASE_COUNTRY')}
            placeholder={t('SELECT_PURCHASE_COUNTRY')}
            data={listCountries}
            nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
            value={formAddress.values[fieldName].state}
            {...formAddress.getInputProps(`${field}state`)}
            searchable
            onChange={(select) => {
              if (select) {
                formAddress.setFieldValue(`${field}state`, select);
                formAddress.setFieldValue(`${field}province`, '');
                formAddress.setFieldValue(`${field}labelProvince`, '');
                formAddress.setFieldValue(`${field}city`, '');
                setProvinces([]);
                setCities([]);
              }
            }}
            disabled={
              (formAddress.values.FF_GT_SYNC &&
                enableToUseLikeShippingAddress) ||
              disabledCountry
            }
            filter={(value: string, find: OptionObject) =>
              find.label.toLowerCase().includes(value.toLowerCase())
            }
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            label={t('PROVINCE')}
            placeholder={t('PLACEHOLDER_PROVINCE')}
            value={formAddress.values[fieldName].province}
            nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
            data={provicies}
            searchable
            {...formAddress.getInputProps(`${field}province`)}
            onChange={(select) => {
              if (select) {
                formAddress.setFieldValue(`${field}province`, select);
                formAddress.setFieldValue(
                  `${field}labelProvince`,
                  getLabelByCode(select, provicies),
                );
                formAddress.setFieldValue(`${field}city`, '');
                setCities([]);
              }
            }}
            disabled={
              formAddress.values.FF_GT_SYNC && enableToUseLikeShippingAddress
            }
            filter={(value: string, find: OptionObject) =>
              find.label.toLowerCase().includes(value.toLowerCase())
            }
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Select
            label={t('CITY')}
            placeholder={t('PLACEHOLDER_CITY')}
            value={formAddress.values[fieldName].city}
            nothingFound={t('NOTHING_FOUND_ALERT_LABEL')}
            data={cities}
            {...formAddress.getInputProps(`${field}city`)}
            searchable
            disabled={
              formAddress.values.FF_GT_SYNC && enableToUseLikeShippingAddress
            }
            filter={(value: string, find: OptionObject) =>
              find.label.toLowerCase().includes(value.toLowerCase())
            }
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <TextInput
            label={t('ADDRESS')}
            placeholder={t('PLACEHOLDER_ADDRESS')}
            {...formAddress.getInputProps(`${field}address`)}
            disabled={
              formAddress.values.FF_GT_SYNC && enableToUseLikeShippingAddress
            }
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <TextInput
            label={t('ZIPCODE')}
            placeholder={t('PLACEHOLDER_ZIPCODE')}
            {...formAddress.getInputProps(`${field}zipCode`)}
            disabled={
              formAddress.values.FF_GT_SYNC && enableToUseLikeShippingAddress
            }
          />
        </Grid.Col>
        {enableToUseLikeShippingAddress && (
          <Grid.Col span={3} mb={8}>
            <Group className={styles.EnableShippingAddress}>
              <Checkbox
                label={t('ADD_SHIPPING_ADDRESS')}
                {...formAddress.getInputProps('isShippingAddress')}
                checked={formAddress.values.isShippingAddress}
              />
            </Group>
          </Grid.Col>
        )}
      </Grid>
      {showButtons && (
        <Grid className={styles.Address}>
          <Grid.Col span={6}>
            <Button
              color="gray.5"
              disabled={
                formAddress.values.FF_GT_SYNC && enableToUseLikeShippingAddress
              }
              onClick={() => onCancel()}
            >
              {t('CANCEL')}
            </Button>
          </Grid.Col>
          <Grid.Col span={6} className={styles.TextInput}>
            <Group position="right">
              <Button
                className={styles.ButtonConfirm}
                type={'submit'}
                name={fieldName}
                onClick={() => {
                  onSave();
                }}
              >
                {titleConfirmButton}
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
}
