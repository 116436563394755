import { t } from 'i18next';

export function parserStatusFromApiModel(value: number): string {
  switch (value) {
    // Ticket aperto
    case 35: {
      return t('OPEN_TICKET');
    }
    case 33: {
      return t('OPEN_TICKET');
    }
    case 18: {
      return t('OPEN_TICKET');
    }

    // Rma to generate
    case 34: {
      return t('RMA_TO_GENERATE');
    }

    // Attesa rma
    case 45: {
      return t('WAITING_RMA');
    }

    // Rma generato
    case 44: {
      return t('GENERATED_RMA');
    }

    // In attesa arrivo
    case 28: {
      return t('AWAITING_ARRIVAL');
    }

    // In lavorazione
    case 29: {
      return t('IN_PROCESS');
    }
    case 30: {
      return t('IN_PROCESS');
    }
    case 36: {
      return t('IN_PROCESS');
    }
    case 31: {
      return t('IN_PROCESS');
    }

    // Attesa pagamento
    case 42: {
      return t('WAITING_FOR_PAYMENT');
    }

    // Pagamento effettuato
    case 43: {
      return t('WAITING_FOR_SHIPMENT');
    }
    // Spedito
    case 32: {
      return t('SHIPPED');
    }

    // Obsoleto
    case 41: {
      return t('OBSOLETE');
    }

    default:
      return '';
  }
}

export enum statusCodeCRMOfTicket {
  //TICKET APERTO
  OPEN_TICKET = 35,
  ESTIMATE_TO_GENERETE = 33,
  AWAITING_ESTIMATE_DOWN_PAYMENT = 18,
  //ATTESA RMA
  AWAITING_RMA = 45,
  // Rma generato
  RMA_GENERATED = 44,
  // In attesa arrivo
  AWAITING_CUSTOMER_ITEM = 28,
  // In lavorazione
  TO_ASSIGN = 29,
  IN_PROCESS = 30,
  TO_SEND_PARTIAL_ITEM = 36,
  TO_SEND = 31,
  // Attesa pagamento
  WAITING_FOR_PAYMENT = 42,
  // Pagamento effettuato
  DONE_PAYMENT = 43,
  // Spedito
  SHIPPED_CLOSED = 32,
  //Obsoleto
  OBSOLETE = 41,
}
