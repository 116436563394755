import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { ApiError, RollingStockDto, RollingStocksApi } from '../../api';
import { useApiNotification } from '../../hooks/useApiNotification';
import { useTranslation } from 'react-i18next';
import { CreateInstrument } from './dto/CreateInstrument';
import {
  parseInstrumentToApiModel,
  parserInstrumentFromApiModel,
} from './parser/parserInstruments';
import { isBlank } from '../../lib/utils';
import { FreeFieldsRollingStock } from '../../lib/constantCrm';

export function useUpsetInstrument(instrumentId?: string | null) {
  const { t } = useTranslation();
  const { showSuccess, showError } = useApiNotification();
  const [loading, setLoading] = useState(false);

  const form = useForm<CreateInstrument>({
    validate: {
      model: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
      typeProduct: (value) =>
        isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null,
      serialNumber: (value) =>
        isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null,
      purchaseDate: (value) =>
        isBlank(value.toString()) ? t('ERROR_MANDATORY_FIELD') : null,
      purchaseCountry: {
        value: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
      },
      purchasePlace: {
        value: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
      },
      storeAddress: {
        value: (value, values) =>
          values.purchasePlace.value == t('PLACE_OF_PURCHASE_ECOMMERCE')
            ? null
            : isBlank(value)
            ? t('ERROR_MANDATORY_FIELD')
            : null,
      },
    },

    initialValues: {
      model: '',
      typeProduct: '',
      productId: 0,
      serialNumber: '',
      assignedTo: {
        freeId: FreeFieldsRollingStock.ASSIGNEDTO,
        code: 'assignedto',
        value: '',
      },
      purchaseDate: new Date(),
      purchasePlace: {
        freeId: FreeFieldsRollingStock.PURCHASEPLACE,
        code: 'typeofshop',
        value: '',
      },
      purchaseCountry: {
        freeId: FreeFieldsRollingStock.PURCHASECOUNTRY,
        code: 'purchasecountry',
        value: '',
      },
      storeAddress: {
        freeId: FreeFieldsRollingStock.STOREADDRESS,
        code: 'purchasestore',
        value: '',
      },
      warrcheck: {
        freeId: FreeFieldsRollingStock.WARRCHECK,
        code: 'warrcheck',
        value: 'false',
      },
      enableCheckWarrInServiceRequest: {
        freeId: FreeFieldsRollingStock.ENABLECHECKWARRINSERVICEREQUEST,
        code: 'enableCheckWarrInServiceRequest',
        value: '',
      },
      purchaseAttach: {
        freeId: FreeFieldsRollingStock.PURCHASEATTACH,
        code: 'purchaseattach',
        value: '',
        deleted: false,
      },
      filePurchaseAttach: undefined,
    },
  });

  async function fetchInstruments(instrumentId: string) {
    try {
      setLoading(true);
      const instrument: RollingStockDto = await RollingStocksApi.findOne({
        id: instrumentId,
      });
      form.setValues(parserInstrumentFromApiModel(instrument));
    } catch (error) {
      showError();
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (instrumentId) {
      fetchInstruments(instrumentId);
    } else {
      form.reset();
    }
  }, [instrumentId]);

  async function handleSubmitInstrument(
    values: CreateInstrument,
  ): Promise<{ success: true } | { success: false; error: Error }> {
    try {
      const requestBody = parseInstrumentToApiModel(values);

      setLoading(true);
      !instrumentId
        ? await RollingStocksApi.create({
            formData: {
              file: values.filePurchaseAttach,
              body: JSON.stringify(requestBody),
            },
          })
        : await RollingStocksApi.update({
            id: instrumentId,
            formData: {
              file: values.filePurchaseAttach,
              body: JSON.stringify(requestBody),
            },
          });
      //fetchProduct(productResponse.id);

      form.reset();
      showSuccess();

      return { success: true };
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        const errorMessageSerialNumberConflict =
          'Esiste già un altro prodotto con lo stesso codice. I codici devono essere univoci.';
        if (error.status === 409)
          showError(t('ERROR_INSTRUMENT_CONFLICT'), false);
        else if (
          error.status === 500 &&
          error.body.message === errorMessageSerialNumberConflict
        )
          showError(t('ERROR_INSTRUMENT_SERIAL_NUMBER_CONFLICT'), false);
        else showError();
        return { success: false, error };
      } else if (error instanceof Error) {
        showError();
        return { success: false, error };
      }
      return { success: false, error: new Error('Unknown server error') };
    } finally {
      setLoading(false);
    }
  }

  return {
    onSubmit: form.onSubmit,
    loading,
    handleSubmitInstrument,
    form,
  };
}
