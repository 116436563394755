import { useTranslation } from 'react-i18next';
import { useApiNotification } from '../../hooks/useApiNotification';
import { z } from 'zod';
import { constants } from '../../constants';
import { useForm, zodResolver } from '@mantine/form';
import { AccountApi } from '../../api';
import { useAccount } from '../../hooks/useAccount';
import { signIn } from 'supertokens-auth-react/lib/build/recipe/emailpassword';

//CreateProductZ;
export const CreateUserPasswordZ = z.object({
  oldPassword: z.string(),
});

export function useChangePassword() {
  const { t } = useTranslation();
  const { account } = useAccount();
  const { showError, showSuccess } = useApiNotification();

  const schema = z.object({
    oldPassword: z
      .string()
      .regex(
        new RegExp(constants.PASSWORD_REGEX),
        t('password_regex_error_message'),
      ),
    password: z
      .string()
      .regex(
        new RegExp(constants.PASSWORD_REGEX),
        t('password_regex_error_message'),
      ),
    confirmPassword: z
      .string()
      .regex(
        new RegExp(constants.PASSWORD_REGEX),
        t('password_regex_error_message'),
      ),
  });

  const form = useForm({
    validate: zodResolver(schema),
    initialValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
  });

  async function handleSubmit(values: {
    oldPassword: string;
    password: string;
    confirmPassword: string;
  }): Promise<{ success: boolean }> {
    if (values.password !== values.confirmPassword) {
      form.setErrors(() => {
        return { confirmPassword: t('password_match_error_message') };
      });
      return { success: false };
    }
    const isPasswordValid = await signIn({
      formFields: [
        {
          id: 'email',
          value: account?.email || '',
        },
        {
          id: 'password',
          value: values.oldPassword,
        },
      ],
    });
    if (isPasswordValid.status !== 'OK') {
      form.setErrors(() => {
        return { oldPassword: t('password_old_password_error_message') };
      });
      return { success: false };
    }
    try {
      await AccountApi.updatePassword({
        requestBody: {
          oldPassword: values.oldPassword,
          newPassword: values.confirmPassword,
        },
      });
      showSuccess(t('password_update_success'));
      form.reset();
      return { success: true };
    } catch (error) {
      showError();
      return { success: false };
    }
  }

  return {
    schema,
    form,
    onSubmit: form.onSubmit,
    handleSubmit,
    getInputProps: (field: keyof z.infer<typeof schema>) =>
      form.getInputProps(field),
  };
}
