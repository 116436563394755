import {
  Button,
  Checkbox,
  Grid,
  Group,
  Loader,
  Modal,
  TextInput,
} from '@mantine/core';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/Tickets/CreateTicket.module.scss';
import { CreateTicketForm, useUpsetTicket } from './useUpsetTicket';
import { JobOrder } from '../../components/JobOrder/JobOrder';
import { Card } from '../../components/Card/Card';
import PlusIconBlack from '../../components/icons/PlusIconBlack';
import { InfoCircle } from 'tabler-icons-react';
import { OptionHeader } from '../../components/OptionHeader/OptionHeader';
import { CreateJobOrder, useUpsetJobOrder } from './useUpsetJobOrder';
import { parserCreateJobOrderToApiModel } from './parser/parserJobOrderTask';
import { getTotalTaxablePriceJobOrder } from '../../lib/crmUtils';
import { JobOrderTaskApi, RollingStockDto } from '../../api';
import { ModalDeleteJobOrder } from '../../components/ModalDeleteJobOrder/ModalDeleteJobOrder';
import { constantCrm } from '../../lib/constantCrm';
import { isBlank, isNotBlank } from '../../lib/utils';
import { useAccount } from '../../hooks/useAccount';
import { AddressDispatch } from '../../components/AddressDispatch/AddressDispatch';
import { setAddress } from '../../components/AddressDispatch/parser/parserAddressDispatch';
import { constants } from '../../constants';
import { useCompany } from '../../hooks/useCompany';
import { FreeField } from '../Instruments/dto/Instrument';

export interface Props {
  onClose: () => void;
  onSave: () => void;
  parseRollingStockId?: number;
  parsePurchaseAttach?: any;
}

export function CreateTicket({
  onClose = () => {},
  onSave = () => {},
  parseRollingStockId,
  parsePurchaseAttach,
}: Props) {
  const { t } = useTranslation();
  const { account } = useAccount();
  const { company } = useCompany();

  const { newJobOrderTask, handleSubmitJobOrder } = useUpsetJobOrder();
  const [jobOrderTasks, setJobOrderTasks] = useState<CreateJobOrder[]>([
    newJobOrderTask,
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [editJobOrderId, setEditJobOrderId] = useState<string | null>(null);
  const { onSubmit, handleSubmitTicket, form, ticket, loading, setTicket } =
    useUpsetTicket();

  const [openModalDeleteJobOrder, setOpenModalDeleteJobOrder] = useState(false);
  const [updateAllJobOrder, setUpdateAllJobOrder] = useState(true);

  const [rollingStocksAvailable, setRollingStocksAvailable] = useState<
    RollingStockDto[]
  >([]);

  const [loadingRollingStocks, setLoadingRollingStocks] =
    useState<boolean>(false);
  const [loadingSaveJobOrder, setLoadingSaveJobOrder] =
    useState<boolean>(false);

  const useEffectOnce = useRef(false);

  useEffect(() => {
    onUpdateMode(0);
    if (useEffectOnce.current) return;
    getRollingStocksUnassociated();
    useEffectOnce.current = true;
  }, []);

  async function getRollingStocksUnassociated() {
    setLoadingRollingStocks(true);
    const rollingStockResponse =
      await JobOrderTaskApi.getRollingStockWithoutJobOrder();
    setRollingStocksAvailable(rollingStockResponse);
    setLoadingRollingStocks(false);
  }

  useEffect(() => {
    if (company) {
      form.setValues({
        ...form.values,
        ...setAddress(company),
        FF_GT02_EXT003: company.FF_GT02_EXT003?.toUpperCase() || '',
        FF_GT_SYNC: company.FF_GT_SYNC,
      });
    }
  }, [company]);

  const filterDeleteJobOrderOrTicket = useCallback(async () => {
    if (
      jobOrderTasks.length == 0 ||
      (jobOrderTasks.length >= 1 && jobOrderTasks[0].id == 0)
    ) {
      onClose();
    } else {
      setUpdateAllJobOrder(true);
      setOpenModalDeleteJobOrder(true);
    }
  }, [jobOrderTasks, ticket]);

  useEffect(() => {
    if (parseRollingStockId != undefined && rollingStocksAvailable.length > 0) {
      const rollingStockData = rollingStocksAvailable.find(
        (rolling) => rolling.id == parseRollingStockId,
      );

      if (rollingStockData) {
        let purchase = '';
        const purchaseAttachFreeField: FreeField[] =
          rollingStockData?.freeFieldsRolling.filter(
            (freeField) => freeField.code == 'purchaseattach',
          );
        if (purchaseAttachFreeField && purchaseAttachFreeField?.length > 0)
          purchase = purchaseAttachFreeField[0].value;

        jobOrderTasks[0].serialN = rollingStockData.internalIdentification;
        jobOrderTasks[0].model = rollingStockData.model;
        jobOrderTasks[0].rollingStockId = parseRollingStockId;
        jobOrderTasks[0].purchaseAttach.value = purchase;
        jobOrderTasks[0].repairPrice =
          Number(rollingStockData.FF_GT06_EXT003) || 0;
        jobOrderTasks[0].calibrationPrice =
          Number(rollingStockData.FF_GT06_EXT009) || 0;
        jobOrderTasks[0].calibrationReportPrice =
          Number(rollingStockData.FF_GT06_EXT007) || 0;

        setJobOrderTasks([...jobOrderTasks]);
      }
    }
  }, [parseRollingStockId, parsePurchaseAttach, rollingStocksAvailable]);

  const onSaveJobOrder = useCallback(
    async (values: CreateJobOrder, index: number) => {
      setLoadingSaveJobOrder(true);
      const { success, jobOrderUpdated } = await handleSubmitJobOrder(values);

      if (success && jobOrderUpdated) {
        setJobOrderTasks((jobOrder) => {
          jobOrder[index] = { ...jobOrderUpdated };
          return [...jobOrder];
        });
        setEditJobOrderId('');
        onUpdateMode();
        setLoadingSaveJobOrder(false);
      } else {
        setLoadingSaveJobOrder(false);
      }
    },
    [handleSubmitJobOrder],
  );

  const addJobOrder = useCallback(() => {
    newJobOrderTask.model = '';
    newJobOrderTask.serialN = '';
    jobOrderTasks.push(newJobOrderTask);
    setJobOrderTasks(jobOrderTasks);
    onUpdateMode(jobOrderTasks.length - 1);
  }, [jobOrderTasks]);

  const deleteJobOrder = useCallback(
    (item: CreateJobOrder) => {
      const filterJobOrder = jobOrderTasks.filter(
        (deleteJob) => deleteJob.id != item.id,
      );
      setJobOrderTasks(filterJobOrder);
    },
    [jobOrderTasks],
  );

  const onUpdateMode = useCallback(
    (indexJobOrder?: number) => {
      if (indexJobOrder == null || indexJobOrder == undefined) {
        setEditJobOrderId(null);
      } else if (
        isBlank(editJobOrderId) ||
        jobOrderTasks[indexJobOrder].id != Number(editJobOrderId)
      ) {
        setEditJobOrderId(jobOrderTasks[indexJobOrder].id.toString());
      }
    },
    [jobOrderTasks, editJobOrderId],
  );

  const getLinkGeneralCondition = useMemo(() => {
    let link = constantCrm.linkGeneralCondition_it;
    if (
      isNotBlank(account?.language) &&
      localStorage.getItem('i18nextLng') != constants.DEFAULT_LANGUAGE &&
      account?.language != constants.DEFAULT_LANGUAGE
    )
      link = constantCrm.linkGeneralCondition_en;

    return link;
  }, [account]);

  return (
    <>
      {openModalDeleteJobOrder && (
        <ModalDeleteJobOrder
          updateAllJobOrder={updateAllJobOrder}
          allJobOrderTask={jobOrderTasks}
          idJobOrder={jobOrderTasks[0]?.id || 0}
          title={t('MODAL_DELETE_JOBORDER_TITLE', {
            count: jobOrderTasks.length,
          })}
          headerTitle={t('MODAL_DELETE_JOBORDER_SUB_TITLE', {
            count: jobOrderTasks.length,
          })}
          subTitle={t('MODAL_DELETE_JOBORDER_LABEL_AND_PLACEHOLDER')}
          placeHolder={t('MODAL_DELETE_JOBORDER_LABEL_AND_PLACEHOLDER')}
          onClose={() => setOpenModalDeleteJobOrder(false)}
          onDelete={() => {
            setOpenModalDeleteJobOrder(false);
            onClose();
          }}
          onClickButtonAbort={() => setOpenModalDeleteJobOrder(false)}
          opened={openModalDeleteJobOrder}
          buttonLabel={t('MODAL_DELETE_JOBORDER_BUTTON_CONFIRM', {
            count: jobOrderTasks.length,
          })}
        />
      )}
      <Modal
        centered={true}
        opened={openModal}
        title={t('MODAL_CREATE_TICKET_AND_NO_JOBORDER_TITLE')}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Button
          onClick={() => setOpenModal(false)}
          className={styles.ButtonBackModalTicket}
        >
          {t('back')}
        </Button>
      </Modal>
      {ticket && !loading && company ? (
        <Card title={t('TICKET') + ' N° : ' + ticket.ticketId}>
          <></>
          <>
            {jobOrderTasks.map((item, index) => {
              return (
                <JobOrder
                  key={index}
                  numberOfJobOrder={index}
                  editJobOrderId={editJobOrderId}
                  idTicket={ticket.id}
                  rollingStocksAvailable={rollingStocksAvailable}
                  loadingRollingStocks={loadingRollingStocks}
                  loadingSaveJobOrder={loadingSaveJobOrder}
                  jobOrder={item}
                  jobOrderCreated={jobOrderTasks}
                  company={company}
                  onSave={(order) => onSaveJobOrder(order, index)}
                  onUpdateMode={(indexJobOrderInUpdateMode) =>
                    onUpdateMode(indexJobOrderInUpdateMode)
                  }
                  onDelete={() => deleteJobOrder(item)}
                />
              );
            })}
            {isBlank(editJobOrderId) &&
              rollingStocksAvailable.length != jobOrderTasks.length && (
                <div onClick={addJobOrder} className={styles.ButtonAddJobOrder}>
                  <span>{t('ADD_JOB_ORDER')}</span>
                  <PlusIconBlack />
                </div>
              )}
            <OptionHeader
              title={t('FOOTER_TOTAL_PRICE')}
              icon={
                getTotalTaxablePriceJobOrder(
                  jobOrderTasks.map((jobOrder) =>
                    parserCreateJobOrderToApiModel(jobOrder),
                  ),
                ) + ' €'
              }
            />
            <form
              onSubmit={onSubmit(async (values: CreateTicketForm) => {
                if (jobOrderTasks.length > 0) {
                  const { success } = await handleSubmitTicket(
                    values,
                    jobOrderTasks,
                  );
                  if (success) {
                    setTicket(null);
                    setJobOrderTasks([]);
                    onSave();
                  }
                } else {
                  setOpenModal(true);
                }
              })}
            >
              <OptionHeader title={t('BILLING_DATA')} />
              <Grid m={'xs'}>
                <Grid.Col span={2}>
                  <TextInput
                    label={t('sdi_label')}
                    placeholder={t('sdi_number')}
                    {...form.getInputProps('FF_GT02_EXT003')}
                    disabled={isNotBlank(company?.FF_GT02_EXT003)}
                  />
                </Grid.Col>
              </Grid>

              <AddressDispatch formAddress={form} />

              <div className={styles.CostOfInterventionInfo}>
                {t('INFO_COST_FOR_INTERVENTION')}
              </div>
              <div className={styles.CheckBoxMargin}>
                <div className={styles.checkBox}>
                  <Checkbox
                    label={t('INFO_CHECK_BOX_TICKET_LABEL')}
                    checked={form.values.FF_flagacceptedservice}
                    {...form.getInputProps('FF_flagacceptedservice')}
                  />
                  <a href={getLinkGeneralCondition} target={'blank'}>
                    <InfoCircle size={19} strokeWidth={1.5} color={'black'} />
                  </a>
                </div>
                <div className={styles.checkBox}>
                  <Checkbox
                    label={t('INFO_CHECK_BOX_TICKET_LABEL_2')}
                    checked={form.values.FF_flagvexatiousitems}
                    {...form.getInputProps('FF_flagvexatiousitems')}
                  />
                </div>
              </div>
              <div className={styles.ContainerButtonModal}>
                <Button
                  className={styles.ButtonCancel}
                  onClick={() => filterDeleteJobOrderOrTicket()}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  type="submit"
                  disabled={
                    !form.values.FF_flagacceptedservice ||
                    !form.values.FF_flagvexatiousitems ||
                    isNotBlank(editJobOrderId) ||
                    loading
                  }
                >
                  {t('BUTTON_CONFIRM_TICKET_AND_DOWNLOAD')}
                </Button>
              </div>
            </form>
          </>
        </Card>
      ) : (
        <Card title={''}>
          <></>
          <Group position={'center'} m={250}>
            <Loader />
          </Group>
        </Card>
      )}
    </>
  );
}
