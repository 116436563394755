import InfoIcon from '../icons/InfoIcon';
import styles from './InfoBox.module.scss';

interface Props {
  context: string;
}

export function InfoBox({ context }: Props) {
  return (
    <div className={styles.InfoBox}>
      <div className={styles.InfoIcon}>
        <InfoIcon />
      </div>
      <label className={styles.Context}>{context}</label>
    </div>
  );
}
