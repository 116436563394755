import React from 'react';
export default function PlusIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5327 5.45457C13.3563 4.63097 13.3563 3.29104 12.5327 2.46744C12.1336 2.06835 11.6031 1.84874 11.039 1.84874C10.4748 1.84874 9.94432 2.06836 9.54534 2.46744L7.10637 4.90618C6.93167 5.08053 6.69961 5.17676 6.45272 5.17676C6.20583 5.17676 5.97377 5.08053 5.79907 4.90618C5.62437 4.73159 5.52814 4.49941 5.52814 4.25253C5.52814 4.00562 5.62437 3.77346 5.79907 3.59864L8.23804 1.16023C8.98607 0.412082 9.98078 0 11.039 0C12.0974 0 13.092 0.412082 13.8397 1.16023C14.5879 1.9079 15 2.90261 15 3.96092C15 5.0191 14.5879 6.01381 13.8398 6.7616L11.4012 9.20034C11.2265 9.37527 10.9943 9.47139 10.7475 9.47139C10.5007 9.47139 10.2688 9.37515 10.094 9.20045C9.91927 9.02587 9.82304 8.79393 9.82304 8.5468C9.82304 8.29968 9.91927 8.06774 10.0937 7.89327L12.5327 5.45457ZM10.2043 3.87147C10.4512 3.87147 10.6833 3.9677 10.8579 4.14217C11.0325 4.31676 11.1286 4.54893 11.1286 4.79582C11.1286 5.04272 11.0324 5.27488 10.8579 5.44971L5.44975 10.8576C5.27516 11.0321 5.04299 11.1283 4.7961 11.1283C4.54921 11.1283 4.31704 11.032 4.14245 10.8576C3.78178 10.4969 3.78178 9.91046 4.14245 9.54991L9.5506 4.14205C9.7253 3.96758 9.95736 3.87158 10.2043 3.87158V3.87147ZM1.16026 8.23805L3.59923 5.79919C3.77393 5.62461 4.00608 5.52861 4.25288 5.52861C4.49967 5.52861 4.73158 5.62484 4.90641 5.79919C5.08111 5.97355 5.17711 6.20572 5.17711 6.45284C5.17711 6.69975 5.08111 6.93191 4.90641 7.10649L2.46744 9.54511C1.64419 10.3687 1.64419 11.7086 2.46744 12.5322C2.86665 12.9313 3.39736 13.1513 3.96118 13.1513C4.52532 13.1513 5.05571 12.9317 5.45504 12.5324L7.89365 10.0933C8.06824 9.91891 8.30018 9.82303 8.54695 9.82303C8.79362 9.82303 9.02566 9.91891 9.20048 10.0935C9.37518 10.2681 9.47141 10.5003 9.47141 10.7474C9.47141 10.9942 9.37518 11.2264 9.20048 11.4013L6.76186 13.8399C6.01455 14.5879 5.01984 15 3.9613 15C2.90276 15 1.90793 14.5879 1.16026 13.8399C-0.383975 12.2952 -0.383975 9.78236 1.16026 8.23828V8.23805Z"
        fill="#91A2B3"
      />
    </svg>
  );
}
