import { useCallback, useEffect, useState } from 'react';

import { CompaniesApi, CompanyDto } from '../api';
import { useAccount } from './useAccount';

export function useCompany() {
  const { account } = useAccount();

  const [company, setCompany] = useState<CompanyDto | null>(null);

  const getCompany = useCallback(async () => {
    CompaniesApi.findOne({
      id: String(account?.companyId),
    })
      .then((response) => {
        setCompany(response);
      })
      .catch();
  }, [account?.companyId]);

  useEffect(() => {
    account?.companyId && getCompany();
  }, [account?.companyId]);

  const refreshCompany = useCallback(async () => {
    getCompany();
  }, [account?.companyId]);

  return { company, refreshCompany };
}
