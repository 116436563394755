import styles from './Card.module.scss';
import { Container, Title } from '@mantine/core';
import { ReactElement, ReactNode } from 'react';

interface CardProps {
  title: string;
  children: ReactNode[];
}

export function Card({ children, title }: CardProps) {
  return (
    <Container size={'xl'} className={styles.Card}>
      <div className={styles.HeaderCard}>
        <Title className={styles.Title}>{title}</Title>
        {children[0]}
      </div>
      {children[1]}
    </Container>
  );
}
