import { useForm } from '@mantine/form';
import { useState } from 'react';
import { z } from 'zod';
import { JobOrderTaskApi } from '../../api';
import { useApiNotification } from '../../hooks/useApiNotification';
import { isBlank, isValidPhone, isNotBlank } from '../../lib/utils';
import { useTranslation } from 'react-i18next';
import { parserCreateJobOrderToApiModel } from './parser/parserJobOrderTask';
import { constants } from '../../constants';
import { useAccount } from '../../hooks/useAccount';
import { statusCodeCRMOfJobOrder } from './parser/parserStatusJobOrderTask';

export const JobOrderTypes = z.object({
  FF_Calibrazione: z.boolean(),
  FF_ACCREDIA: z.boolean(),
  FF_Riparazione: z.boolean(),
});

export const AttachZ = z.object({
  value: z.string(),
  deleted: z.boolean().optional(),
});

export const JobOrderZ = z.object({
  id: z.number(),
  ticketId: z.number(),
  rollingStockId: z.number(),
  serialN: z.string(),
  model: z.string(),
  jobOrderType: JobOrderTypes,
  emailRef: z.string(),
  phoneRef: z.string(),
  note: z.string(),
  repairPrice: z.number(),
  calibrationPrice: z.number(),
  calibrationReportPrice: z.number(),
  purchaseAttach: AttachZ,
  interventionAttach: AttachZ,
  filePurchaseAttach: z.instanceof(File).or(z.undefined()),
  fileInterventionAttach: z.instanceof(File).or(z.undefined()),
});

export type CreateJobOrder = z.infer<typeof JobOrderZ>;

export function useUpsetJobOrder(idTicket?: number) {
  const { t } = useTranslation();
  const { account } = useAccount();
  const [loading, setLoading] = useState(false);
  const { showSuccess, showError } = useApiNotification();

  /*
   * FF_Calibrazione -> t(INTERVENTION_TYPE3_CALIBRATION_REPORT) "Rapporto di taratura" - "Test Report (RDT)",-> CalibrationReport
   * FF_ACCREDIA ->  t('INTERVENTION_TYPE2_CALIBRATION') -> "Calibrazione Accredia" - "Calibration Certificate" -> Calibration
   * FF_Riparazione -> ('INTERVENTION_TYPE1_REPAIR') -> "Riparazione" - "Repair" -> Repair
   */
  const newJobOrderTask: CreateJobOrder = {
    id: 0,
    ticketId: idTicket || 0,
    serialN: '',
    model: '',
    rollingStockId: 0,
    jobOrderType: {
      FF_Calibrazione: false,
      FF_ACCREDIA: false,
      FF_Riparazione: false,
    },
    // faultType: '',
    emailRef: account?.email || '',
    phoneRef: '',
    note: '',
    repairPrice: 0,
    calibrationPrice: 0,
    calibrationReportPrice: 0,
    purchaseAttach: {
      value: '',
      deleted: false,
    },
    interventionAttach: {
      value: '',
      deleted: false,
    },
    filePurchaseAttach: undefined,
    fileInterventionAttach: undefined,
  };

  const form = useForm<CreateJobOrder>({
    validate: {
      serialN: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
      model: (value) => (isBlank(value) ? t('ERROR_MANDATORY_FIELD') : null),
      jobOrderType: (value) =>
        value.FF_Riparazione || value.FF_Calibrazione || value.FF_ACCREDIA
          ? null
          : t('ERROR_MANDATORY_FIELD'),
      note: (value, values) =>
        isBlank(value) && values.jobOrderType.FF_Riparazione
          ? t('ERROR_MANDATORY_FIELD')
          : null,
      emailRef: (value) =>
        isBlank(value)
          ? t('ERROR_MANDATORY_FIELD')
          : constants.EMAIL_REGEX.test(value)
          ? null
          : t('ERROR_EMAIL_FORMAT'),
      phoneRef: (value) =>
        isBlank(value)
          ? t('ERROR_MANDATORY_FIELD')
          : isValidPhone(value)
          ? null
          : t('ERROR_PHONE_FORMAT'),
    },

    initialValues: {
      ...newJobOrderTask,
    },
  });

  async function handleSubmitJobOrder(values: CreateJobOrder): Promise<{
    success: boolean;
    error?: Error;
    jobOrderUpdated?: CreateJobOrder;
  }> {
    try {
      setLoading(true);
      const requestBody = parserCreateJobOrderToApiModel(values);
      const idJobOrder = values.id || null;
      const formData = {
        purchaseAttach: undefined,
        interventionAttach: values.fileInterventionAttach,
        body: JSON.stringify(requestBody),
      };

      if (!idJobOrder) {
        const responseCreate = await JobOrderTaskApi.create({
          formData,
        });
        values = updateAttach(
          values,
          '',
          responseCreate.linkInterventionAttach,
          responseCreate.id,
        );
      } else {
        const responseUpdate = await JobOrderTaskApi.update({
          id: idJobOrder.toString(),
          formData,
        });
        values = updateAttach(
          values,
          '',
          responseUpdate.linkInterventionAttach,
          responseUpdate.id,
        );
      }

      showSuccess();
      return { success: true, jobOrderUpdated: values };
    } catch (error: unknown) {
      if (error instanceof Error) {
        showError();
        return { success: false, error };
      }
      return {
        success: false,
        error: new Error('Unknown server error'),
      };
    } finally {
      setLoading(false);
    }
  }

  function updateAttach(
    values: CreateJobOrder,
    linkPurchaseAttach: string,
    linkInterventionAttach: string,
    id?: string,
  ) {
    const jobOrder: CreateJobOrder = { ...values };
    if (isNotBlank(linkPurchaseAttach)) {
      jobOrder.purchaseAttach = { value: linkPurchaseAttach, deleted: false };
    }
    if (isNotBlank(linkInterventionAttach)) {
      jobOrder.interventionAttach = {
        value: linkInterventionAttach,
        deleted: false,
      };
    }
    if (id) jobOrder.id = Number(id);

    return jobOrder;
  }

  async function updateJobOrderToOpenIntervention(
    jobOrderTasks: CreateJobOrder[],
  ) {
    const requestBody = {
      status: statusCodeCRMOfJobOrder.OPEN_INTERVENTION,
    };
    const multipleIdForJobOrder: any[] = [];
    jobOrderTasks.map((value) => {
      if (value.id != 0)
        multipleIdForJobOrder.push({
          id: String(value?.id),
          ...requestBody,
        });

      //Update PurchaseAttach
      if (value.filePurchaseAttach || value.purchaseAttach.deleted) {
        const formData = {
          purchaseAttach: value.filePurchaseAttach,
          body: JSON.stringify(value.purchaseAttach),
        };
        JobOrderTaskApi.updatePurchaseAttach({
          rollingStockId: value.rollingStockId,
          formData,
        });
      }
    });
    await JobOrderTaskApi.updateAllJobOrder({
      requestBody: multipleIdForJobOrder,
    });
  }

  return {
    onSubmit: form.onSubmit,
    handleSubmitJobOrder,
    form,
    loading,
    newJobOrderTask,
    updateJobOrderToOpenIntervention,
  };
}
