/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountTicketsStatusDto } from '../models/CountTicketsStatusDto';
import type { CreateTicketDto } from '../models/CreateTicketDto';
import type { TicketDto } from '../models/TicketDto';
import type { TicketResponseDto } from '../models/TicketResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TicketApi {

    /**
     * @returns CountTicketsStatusDto 
     * @throws ApiError
     */
    public static countStatus(): CancelablePromise<CountTicketsStatusDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ticket/count/status',
        });
    }

    /**
     * @returns TicketResponseDto 
     * @throws ApiError
     */
    public static findAll(): CancelablePromise<TicketResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ticket',
        });
    }

    /**
     * @returns string 
     * @throws ApiError
     */
    public static create({
requestBody,
}: {
requestBody: CreateTicketDto,
}): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/ticket',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns TicketDto 
     * @throws ApiError
     */
    public static findOne({
id,
}: {
id: string,
}): CancelablePromise<TicketDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/ticket/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns string 
     * @throws ApiError
     */
    public static update({
id,
formData,
}: {
id: string,
formData: {
file?: Blob;
body: string;
},
}): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/ticket/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
