import { Title, Text, Button, Container, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { DefaultPath } from '../../hooks/useNavigationLinks';
import styles from './Error404.module.scss';
import { useTranslation } from 'react-i18next';

export function Error404() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container className={styles.Error404Page}>
      <div className={styles.label}>404</div>
      <Title className={styles.title}>{t('error_404_title')}</Title>
      <Text
        color="dimmed"
        size="lg"
        align="center"
        className={styles.description}
      >
        {t('error_404_message')}
      </Text>
      <Group position="center">
        <Button
          variant="subtle"
          size="md"
          onClick={() => navigate(DefaultPath)}
        >
          {t('error_404_action')}
        </Button>
      </Group>
    </Container>
  );
}
