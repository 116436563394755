import React from 'react';

export default function Arrowicon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6861 13.4102C18.6672 13.2136 18.5877 13.0276 18.4589 12.8779L15.0137 8.85851C14.8516 8.65624 14.6143 8.52847 14.3563 8.50418C14.0982 8.48001 13.8415 8.56145 13.6446 8.73008C13.4476 8.89857 13.3275 9.13981 13.3116 9.39844C13.2957 9.65719 13.3854 9.91126 13.5603 10.1027L16.4733 13.5L13.5603 16.8973C13.3855 17.0888 13.2958 17.3428 13.3117 17.6016C13.3275 17.8602 13.4476 18.1014 13.6445 18.2699C13.8415 18.4385 14.0983 18.52 14.3564 18.4958C14.6145 18.4715 14.8516 18.3438 15.0137 18.1415L18.4589 14.1221C18.6281 13.9254 18.71 13.6685 18.6863 13.4103L18.6861 13.4102Z"
        fill="#616E7B"
      />
      <path
        d="M7 6V13.5H16.5"
        stroke="#616E7B"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
