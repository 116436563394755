import { useCallback, useEffect, useState } from 'react';
import { RollingStocksApi } from '../../api';
import { constants } from '../../constants';
import { getFilterContents, getFilterEqual } from '../../lib/crmUtils';
import { isBlank, isNotBlank } from '../../lib/utils';

export interface OptionsSearch {
  page: number;
  size: number;
  orderBy?: string;
  select?: string;
  search?: string;
}

export interface OptionsFilter {
  value: string;
  label: string;
}

export function useFilterInstrument() {
  const [searchNumSerial, setSearchNumSerial] = useState('');
  const [searchTypeProduct, setSearchTypeProduct] = useState('');
  const [searchModel, setSearchModel] = useState('');
  const [typedProduct, setTypedProduct] = useState('');
  const [typedModel, setTypedModel] = useState('');
  const [optionsTypeProduct, setOptionsTypeProduct] = useState<OptionsFilter[]>(
    [],
  );
  const [optionsModel, setOptionsModel] = useState<OptionsFilter[]>([]);

  const onTypeProduct = useCallback(
    (searchValue: string) => {
      const options: OptionsSearch = {
        page: 1,
        size: 100,
        orderBy: 'title asc',
      };

      if (isNotBlank(searchValue)) options.search = searchValue;

      RollingStocksApi.findAll(options)
        .then((response) => {
          if (response.data.length > 0) {
            const data = response.data.filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  (originValue) => value.title === originValue.title,
                ),
            );

            const optionsTypeProduct = [];

            for (const type of data) {
              optionsTypeProduct.push({ value: type.title, label: type.title });
            }

            setOptionsTypeProduct(optionsTypeProduct);
          }
        })
        .catch((e) => console.log(e));
    },
    [setOptionsTypeProduct],
  );

  useEffect(() => {
    const getOptions = setTimeout(() => {
      const search = isBlank(typedProduct)
        ? ''
        : getFilterContents('title', typedProduct);
      onTypeProduct(search);
    }, constants.SEARCH_TIMESHOLD_MS);

    return () => clearTimeout(getOptions);
  }, [typedProduct, onTypeProduct]);

  const onTypeModel = useCallback(
    (searchValue: string) => {
      const options: OptionsSearch = {
        page: 1,
        size: 100,
      };

      if (isNotBlank(searchValue)) options.search = searchValue;

      RollingStocksApi.findAll(options)
        .then((response) => {
          const data = response.data.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (originValue) => value.model === originValue.model,
              ),
          );

          const optionsModel = [];
          for (const type of data) {
            optionsModel.push({
              value: type.productId.toString(),
              label: type.model || '',
            });
          }

          setOptionsModel(optionsModel);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [setOptionsModel],
  );

  useEffect(() => {
    const getOptions = setTimeout(() => {
      const search = isBlank(typedModel)
        ? ''
        : getFilterEqual('productId', typedModel);
      onTypeModel(search);
    }, constants.SEARCH_TIMESHOLD_MS);

    return () => clearTimeout(getOptions);
  }, [typedModel, onTypeModel]);

  return {
    setSearchNumSerial,
    searchNumSerial,
    setSearchTypeProduct,
    searchTypeProduct,
    setSearchModel,
    searchModel,
    typedModel,
    setTypedProduct,
    typedProduct,
    optionsTypeProduct,
    optionsModel,
  };
}
