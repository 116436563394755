import React from 'react';

export default function AlertIcon() {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.86 11.9042L11.7879 1.83421C11.2889 1.05363 10.4264 0.581299 9.5 0.581299C8.57356 0.581299 7.71105 1.05363 7.21208 1.83421L1.14 11.9042C0.703431 12.6319 0.688341 13.5373 1.10041 14.2792C1.57698 15.1145 2.46622 15.6287 3.42791 15.625H15.5721C16.5272 15.6352 17.4149 15.134 17.8996 14.3109C18.324 13.5609 18.3088 12.6398 17.86 11.9042ZM9.5 12.4584C9.06277 12.4584 8.70833 12.1039 8.70833 11.6667C8.70833 11.2295 9.06277 10.875 9.5 10.875C9.93722 10.875 10.2917 11.2295 10.2917 11.6667C10.2917 12.1039 9.93722 12.4584 9.5 12.4584ZM9.5 10.0834C9.93722 10.0834 10.2917 9.72893 10.2917 9.29171V6.12504C10.2917 5.68782 9.93722 5.33338 9.5 5.33338C9.06277 5.33338 8.70833 5.68782 8.70833 6.12504V9.29171C8.70833 9.72893 9.06277 10.0834 9.5 10.0834Z"
        fill="#F59F00"
      />
    </svg>
  );
}
