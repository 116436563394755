import {
  Burger,
  Container,
  Group,
  Header,
  Transition,
  Paper,
} from '@mantine/core';
import styles from './LayoutHeader.module.scss';
import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import HtLogo from '../icons/HtLogo';
import { Paths, useNavigationLinks } from '../../hooks/useNavigationLinks';
import { useNavigate } from 'react-router-dom';
import { DropDown } from '../DropDown/DropDown';
import { useTranslation } from 'react-i18next';

// interface HeaderResponsiveProps {
//   links: { link: string; label: string, selected: boolean }[];
// }

export function LayoutHeader() {
  const navigate = useNavigate();
  const { links, isLinkActive } = useNavigationLinks();
  const [opened, { close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { t } = useTranslation();
  // const [links, setLinks] = useState(link);

  const items = links.map((link, key) => (
    <div className={styles.lineBottom} key={key}>
      <a
        className={isLinkActive(link) ? styles.Href : styles.unSelected}
        key={link.label}
        href={link.link}
        onClick={(event) => {
          event.preventDefault();
          setActive(link.link);
          navigate(link.link);
          //close();
        }}
      >
        {link.label}
      </a>
    </div>
  ));
  return (
    <Header height={56} mb={120} className={styles.topHeader}>
      <div className={styles.navBar}>
        <HtLogo />
        <Group spacing={5} className={styles.linkNavBar}>
          {items}
        </Group>
        {/*<Burger
          className={styles.burger}
          onClick={toggle}
          opened={opened}
        />*/}
        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(style) => (
            <Paper className={styles.dropdown} withBorder style={style}>
              {items}
            </Paper>
          )}
        </Transition>
      </div>
      <Group spacing={0} position="right" noWrap className={styles.UserIcon}>
        <DropDown
          link={[
            {
              label: t('ACCOUNT_PROFILE_LABEL'),
              link: Paths.USER_PROFILE_INFO,
            },
            { label: t('logout'), link: Paths.SIGNIN },
          ]}
        ></DropDown>
      </Group>
    </Header>
  );
}
