/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OptionsDTO } from '../models/OptionsDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StreetAndStoreApi {

    /**
     * @returns OptionsDTO 
     * @throws ApiError
     */
    public static findAll({
search,
}: {
search?: string,
}): CancelablePromise<OptionsDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/streetAndStore',
            query: {
                'search': search,
            },
        });
    }

}
