import { sendVerificationEmail } from 'supertokens-auth-react/recipe/emailverification';

export function useVerifyEmail() {
  async function sendEmail() {
    try {
      const response = await sendVerificationEmail();
      if (response.status === 'EMAIL_ALREADY_VERIFIED_ERROR') {
        console.log(response.status);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        console.log(err.message);
      } else {
        console.log(err.message);
      }
    }
  }

  return {
    sendEmail,
  };
}
