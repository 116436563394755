import { Title } from '@mantine/core';
import styles from './VerifyEmailForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useVerifyEmail } from './useVerifyEmail';

export function VerifyEmailForm() {
  const { t } = useTranslation();
  const { sendEmail } = useVerifyEmail();

  const sendAgainEmail = useCallback(() => {
    //Timer for continue press to link
    sendEmail().then((r) => console.log(r));
  }, []);

  return (
    <div className={styles.VerifyEmailForm}>
      <div className={styles.VerifyEmailHeader}>
        <Title>{t('EMAIL_VERIFICATION_TITLE')}</Title>
      </div>
      <div className={styles.VerifyEmailSubtitle}>
        {t('EMAIL_VERIFICATION_SUBTITLE')}
      </div>
      <div className={styles.VerifyEmailLink}>
        {t('EMAIL_VERIFICATION_LABEL_RESEND_EMAIL')}
        <a className={styles.LinkResendEmail} onClick={sendAgainEmail}>
          {t('EMAIL_VERIFICATION_LINK_RESEND_EMAIL')}
        </a>
      </div>
    </div>
  );
}
