/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetFreeFieldsDto } from '../models/GetFreeFieldsDto';
import type { JobOrderResponseDto } from '../models/JobOrderResponseDto';
import type { JobOrderTaskDto } from '../models/JobOrderTaskDto';
import type { PaginatedJobOrderTaskDto } from '../models/PaginatedJobOrderTaskDto';
import type { RollingStockDto } from '../models/RollingStockDto';
import type { UpdateJobOrderTaskListDto } from '../models/UpdateJobOrderTaskListDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class JobOrderTaskApi {

    /**
     * @returns PaginatedJobOrderTaskDto 
     * @throws ApiError
     */
    public static findAll({
search,
size,
page,
}: {
search?: string,
size?: number,
page?: number,
}): CancelablePromise<PaginatedJobOrderTaskDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/jobOrderTask',
            query: {
                'search': search,
                'size': size,
                'page': page,
            },
        });
    }

    /**
     * @returns number 
     * @throws ApiError
     */
    public static updateAllJobOrder({
requestBody,
}: {
requestBody: UpdateJobOrderTaskListDto,
}): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/jobOrderTask',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns JobOrderResponseDto 
     * @throws ApiError
     */
    public static create({
formData,
}: {
formData: {
purchaseAttach?: Blob;
interventionAttach?: Blob;
body?: string;
},
}): CancelablePromise<JobOrderResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/jobOrderTask',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns JobOrderTaskDto 
     * @throws ApiError
     */
    public static findOne({
id,
}: {
id: string,
}): CancelablePromise<JobOrderTaskDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/jobOrderTask/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns JobOrderResponseDto 
     * @throws ApiError
     */
    public static update({
id,
formData,
}: {
id: string,
formData: {
purchaseAttach?: Blob;
interventionAttach?: Blob;
body?: string;
},
}): CancelablePromise<JobOrderResponseDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/jobOrderTask/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public static findTicketByRollingsId({
rollingStockIds,
}: {
rollingStockIds: number,
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/jobOrderTask/check/{rollingStockIds}',
            path: {
                'rollingStockIds': rollingStockIds,
            },
        });
    }

    /**
     * Get RollingStocks are not associated to JobOrderTask
     * @returns RollingStockDto 
     * @throws ApiError
     */
    public static getRollingStockWithoutJobOrder(): CancelablePromise<Array<RollingStockDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/jobOrderTask/unassociated/rollingstock',
        });
    }

    /**
     * @returns GetFreeFieldsDto 
     * @throws ApiError
     */
    public static getFeedback({
id,
}: {
id: string,
}): CancelablePromise<GetFreeFieldsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/jobOrderTask/freefields/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public static updatePurchaseAttach({
rollingStockId,
formData,
}: {
rollingStockId: number,
formData: {
purchaseAttach?: Blob;
body?: string;
},
}): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/jobOrderTask/purchaseAttach/{rollingStockId}',
            path: {
                'rollingStockId': rollingStockId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

}
