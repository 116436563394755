import { CompanyDto, UpdateCompanyDto } from '../../../api';
import { TypeOfAddress } from '../../../lib/constantCrm';
import { getCodeFromValue, getLabelFromValue } from '../../../lib/crmUtils';
import { AddressDispatch, InfoAddress } from '../useUpdateAddressDispatch';

export function parserAddressDispatchToCompanyDto(
  values: AddressDispatch,
): UpdateCompanyDto {
  const company: UpdateCompanyDto = {};
  company.address = values.billAddress.address;
  company.province = values.billAddress.province;
  company.city = values.billAddress.city;
  company.zipCode = values.billAddress.zipCode;
  company.state = values.billAddress.state;

  if (!values.isShippingAddress) {
    company.FF_SHIPPING_ADDRESS = values.shippingAddress.address;
    company.FF_SHIPPING_CITY = values.shippingAddress.city;
    company.FF_SHIPPING_COUNTRY = values.shippingAddress.state;
    company.FF_SHIPPING_PROVINCE = values.shippingAddress.province;
    company.FF_SHIPPING_ZIPCODE = values.shippingAddress.zipCode;
  } else {
    company.FF_SHIPPING_ADDRESS = values.billAddress.address;
    company.FF_SHIPPING_CITY = values.billAddress.city;
    company.FF_SHIPPING_COUNTRY = values.billAddress.state;
    company.FF_SHIPPING_PROVINCE = values.billAddress.province;
    company.FF_SHIPPING_ZIPCODE = values.billAddress.zipCode;
  }

  return company;
}

export function parserInfoAddressToCompanyDto(
  values: InfoAddress,
  typeAddress: string,
): UpdateCompanyDto {
  const company: UpdateCompanyDto = {};

  if (typeAddress == TypeOfAddress.BILLING) {
    company.address = values.infoAddress.address;
    company.province =
      values.infoAddress.province + '-' + values.infoAddress.labelProvince;
    company.city = values.infoAddress.city;
    company.zipCode = values.infoAddress.zipCode;
    company.state = values.infoAddress.state;

    if (values.isShippingAddress) {
      company.FF_SHIPPING_ADDRESS = values.infoAddress.address;
      company.FF_SHIPPING_CITY = values.infoAddress.city;
      company.FF_SHIPPING_COUNTRY = values.infoAddress.state;
      company.FF_SHIPPING_PROVINCE =
        values.infoAddress.province + '-' + values.infoAddress.labelProvince;
      company.FF_SHIPPING_ZIPCODE = values.infoAddress.zipCode;
    }
  }

  if (typeAddress == TypeOfAddress.SHIIPING) {
    company.FF_SHIPPING_ADDRESS = values.infoAddress.address;
    company.FF_SHIPPING_CITY = values.infoAddress.city;
    company.FF_SHIPPING_COUNTRY = values.infoAddress.state;
    company.FF_SHIPPING_PROVINCE =
      values.infoAddress.province + '-' + values.infoAddress.labelProvince;
    company.FF_SHIPPING_ZIPCODE = values.infoAddress.zipCode;
  }

  return company;
}

export function setAddress(company: CompanyDto) {
  const formContact = {
    billAddress: setBillingAddress(company),
    isShippingAddress: setFlagisShippingAddres(company),
    shippingAddress: setShippingAddress(company),
  };

  return formContact;
}

export function setBillingAddress(company: CompanyDto) {
  const billAddress = {
    address: company.address,
    city: company.city,
    province: getCodeFromValue(company.province),
    labelProvince: getLabelFromValue(company.province),
    state: getCodeFromValue(company.state),
    zipCode: company.zipCode,
  };

  return billAddress;
}

export function setFlagisShippingAddres(company: CompanyDto) {
  return company.FF_SHIPPING_ADDRESS == undefined
    ? false
    : checkSameAddress(company);
}

export function setShippingAddress(company: CompanyDto) {
  const shippingAddress = {
    state: getCodeFromValue(company.FF_SHIPPING_COUNTRY) || '',
    address: company.FF_SHIPPING_ADDRESS || '',
    city: company.FF_SHIPPING_CITY || '',
    province: getCodeFromValue(company.FF_SHIPPING_PROVINCE) || '',
    labelProvince: getLabelFromValue(company.FF_SHIPPING_PROVINCE) || '',
    zipCode: company.FF_SHIPPING_ZIPCODE || '',
  };

  return shippingAddress;
}

export function resetAddress(country?: string) {
  return {
    address: '',
    city: '',
    labelProvince: '',
    province: '',
    state: country || '',
    zipCode: '',
  };
}

function checkSameAddress(company: CompanyDto) {
  if (company.FF_SHIPPING_ADDRESS != company.address) return false;
  else if (company.FF_SHIPPING_CITY != company.city) return false;
  else if (company.FF_SHIPPING_COUNTRY != company.state) return false;
  else if (company.FF_SHIPPING_PROVINCE != company.province) return false;
  else if (company.FF_SHIPPING_ZIPCODE != company.zipCode) return false;
  return true;
}
