import { Button, Modal, Select, Title } from '@mantine/core';
import styles from './ModalDeleteJobOrder.module.scss';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { JobOrderTaskApi } from '../../api';
import { CreateJobOrder } from '../../pages/Tickets/useUpsetJobOrder';
import { statusCodeCRMOfJobOrder } from '../../pages/Tickets/parser/parserStatusJobOrderTask';

interface ModalDeleteJobOrder {
  allJobOrderTask?: CreateJobOrder[];
  idJobOrder: number;
  title?: string;
  subTitle?: string;
  headerTitle?: string;
  placeHolder?: string;
  opened: boolean;
  onClose: () => void;
  onClickButtonAbort?: () => void;
  onDelete: () => void;
  updateAllJobOrder?: boolean;
  buttonLabel: string;
}

export function ModalDeleteJobOrder({
  idJobOrder,
  title,
  placeHolder,
  opened,
  headerTitle,
  subTitle,
  onClose,
  onClickButtonAbort,
  onDelete,
  allJobOrderTask,
  updateAllJobOrder,
  buttonLabel,
}: ModalDeleteJobOrder) {
  const { t } = useTranslation();
  const [activateButtonModal, setActivateButtonModal] = useState(true);
  const [objectFeedBack, setObjectFeedBack] = useState<
    { label: string; value: string }[]
  >([]);
  const [getValueOfFeedBack, setValueOfFeedBack] = useState<string | null>(
    null,
  );
  const useEffectOnce = useRef(false);

  const selectedItem = useCallback(
    (select: any) => {
      if (select.currentTarget.value.length > 0) {
        setValueOfFeedBack(select.currentTarget.value);
        setActivateButtonModal(false);
      }
    },
    [objectFeedBack],
  );

  useEffect(() => {
    if (useEffectOnce.current || idJobOrder == 0) return;
    JobOrderTaskApi.getFeedback({
      id: String(idJobOrder),
    }).then((response) => {
      response.map((findFeedBack) => {
        if (findFeedBack.code == 'feedback_del_joborder') {
          findFeedBack.dropItemsValues.map((value) => {
            objectFeedBack.push({ label: value.key, value: value.value });
          });
        }
      });
    });
    useEffectOnce.current = true;
  }, [useEffectOnce, objectFeedBack, idJobOrder]);

  const sendRequestToDeleteJobOrder = useCallback(
    async (idJobOrder: number) => {
      const requestBody = {
        status: statusCodeCRMOfJobOrder.QUOTE_NOT_ACCEPTED,
        FF_feedback_del_joborder: getValueOfFeedBack,
      };
      if (!allJobOrderTask) {
        JobOrderTaskApi.update({
          id: String(idJobOrder),
          formData: { body: JSON.stringify(requestBody) },
        }).then(() => onDelete());
      } else if (allJobOrderTask && updateAllJobOrder) {
        const multipleIdForJobOrder: any[] = [];
        allJobOrderTask.map((value) => {
          value.id != 0 &&
            multipleIdForJobOrder.push({
              id: String(value?.id),
              ...requestBody,
            });
        });
        await JobOrderTaskApi.updateAllJobOrder({
          requestBody: multipleIdForJobOrder,
        }).then(() => onDelete());
      }
    },
    [getValueOfFeedBack],
  );

  return (
    <Modal
      centered={true}
      opened={opened}
      onClose={onClose}
      title={title}
      size={500}
    >
      <Title className={styles.SubTitleModal}>{headerTitle}</Title>
      <Select
        className={styles.SelectOption}
        data={objectFeedBack}
        label={subTitle}
        placeholder={placeHolder}
        onSelect={(select) => selectedItem(select)}
      />
      <Button onClick={onClickButtonAbort} className={styles.ButtonAbort}>
        {t('cancel')}
      </Button>
      <Button
        disabled={activateButtonModal}
        className={styles.ButtonConfirm}
        onClick={async () => {
          await sendRequestToDeleteJobOrder(idJobOrder);
        }}
      >
        {buttonLabel}
      </Button>
    </Modal>
  );
}
