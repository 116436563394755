import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useSessionContext } from 'supertokens-auth-react/lib/build/recipe/session';
import { EmptyPropsWithChildren } from '../lib/types';
import { UpdateAccountDto } from '../api';

const AccountContext = createContext<{
  account: UpdateAccountDto | null;
  updateAccount: (account: UpdateAccountDto) => void;
}>({
  account: null,
  updateAccount: () => {},
});

export const AccountProvider = ({ children }: EmptyPropsWithChildren) => {
  const session = useSessionContext();

  const [account, setAccount] = useState<UpdateAccountDto | null>(null);
  const updateAccount = (account: UpdateAccountDto) => {
    setAccount(account);
  };

  useEffect(() => {
    let accountDto: UpdateAccountDto | null = null;
    if (!session.loading) {
      const { doesSessionExist, accessTokenPayload } = session;
      if (doesSessionExist) accountDto = accessTokenPayload;
    }
    setAccount(accountDto);
  }, [session]);

  return (
    <AccountContext.Provider value={{ account, updateAccount }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const { account, updateAccount } = useContext(AccountContext);
  return { account, updateAccount };
};
