/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountDto } from '../models/AccountDto';
import type { ChangePasswordDto } from '../models/ChangePasswordDto';
import type { UpdateAccountDto } from '../models/UpdateAccountDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountApi {

    /**
     * @returns AccountDto 
     * @throws ApiError
     */
    public static update({
requestBody,
}: {
requestBody: UpdateAccountDto,
}): CancelablePromise<AccountDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/account/update',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static remove(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/account',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static updatePassword({
requestBody,
}: {
requestBody: ChangePasswordDto,
}): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/account/update-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any 
     * @throws ApiError
     */
    public static metadata(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/account/metadata/retrieve',
        });
    }

}
