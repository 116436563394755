import { CSSObject } from '@mantine/core';
import { MantineThemeOverride } from '@mantine/styles/lib/theme/types';
import { NormalisedPalette } from 'supertokens-auth-react/lib/build/types';

type ColorShades = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
];
type Colors = {
  [key: string]: ColorShades;
};

const colors: Colors = {
  primary: [
    '#CAFBFD4D',
    '#CAFBFD99',
    '#cedce9', //dettaglio ticket in figma
    '#97F2FC',
    '#62DEF6',
    '#3BC4EE',
    '#009FE3',
    '#007BC3',
    '#005CA3',
    '#004B98', //in figma blu
  ],

  secondary: [
    '#F6F3F1',
    '#EDE8E4',
    '#F2F5F9', //in figma disabled
    '#E5ECF2', //in figma grigio chiaro
    '#91A2B3', //in figma grigio medio
    '#616E7B', //in figma grigio scuro
    '#2E3A46',
    '#4B4644',
    '#403431',
    '#2B1715',
  ],

  accent: [
    '#F0EBE7',
    '#E1D5CB',
    '#D7C0AF',
    '#D1AC91',
    '#D29A70',
    '#D9884C',
    '#E87722', //in figma Arancione
    '#C56D2A',
    '#A46434',
    '#895B39',
  ],

  gray: [
    '#F6F3F1',
    '#EDE8E4',
    '#F2F5F9', //in figma Disabled
    '#E5ECF2', //in figma Grigio chiaro
    '#91A2B3', //in figma Grigio medio
    '#616E7B', //in figma Grigio scuro
    '#4B4644',
    '#403431',
    '#2B1715',
    '#240D0D',
  ],

  success: [
    '#C3FAE880',
    '#C3FAE8',
    '#96F2D7',
    '#63E6BE',
    '#38D9A9',
    '#20C997',
    '#12B886',
    '#0CA678',
    '#099268',
    '#00988F', //in figma Success color
  ],

  warning: [
    '#FFF3BF80',
    '#FFF3BF',
    '#FFEC99',
    '#FFE066',
    '#FFD43B',
    '#E8BC22', //in figma Yellow
    '#FAB005',
    '#F59F00',
    '#F08C00',
    '#E67700',
  ],

  alert: [
    '#FFE2D780',
    '#FFE2D7',
    '#FFBFAF',
    '#FF9587',
    '#FF6D69',
    '#FA5252',
    '#E85222', // in figma Error color
    '#F03E3E',
    '#E03131',
    '#C92A2A',
  ],
};

export const theme: MantineThemeOverride = {
  // add corresponding vars in _variables.scss
  colors,
  primaryColor: 'accent',
  fontFamily: 'Geometria',

  breakpoints: {
    xs: 600,
    sm: 900,
    md: 1200,
    lg: 1400,
    xl: 1800,
  },
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 24,
    xl: 40,
  },

  components: {
    // Title: {
    //   styles: (theme, params: ButtonStylesParams) => {
    //     const styles: Record<string, CSSObject> = {
    //       root: {
    //         fontSize: '40px',
    //         lineHeight: '60px',
    //       },
    //     };
    //     return styles;
    //   },
    // },
    Button: {
      styles: () => {
        const styles: Record<string, CSSObject> = {
          root: {
            //background: colors.accent[6],
            borderRadius: '5px',
            lineHeight: '20px',
            fontSize: '14px',
          },
        };

        return styles;
      },
    },
    TextInput: {
      styles: () => {
        const styles: Record<string, CSSObject> = {
          input: {
            textOverflow: 'ellipsis',
            border: '1px solid #91A2B3',
            borderRadius: '5px',
            fontWeight: 500,
            color: colors.gray[5],
            lineHeight: '18px',
            fontSize: '14px',
          },
        };

        return styles;
      },
    },
    Select: {
      styles: () => {
        const styles: Record<string, CSSObject> = {
          input: {
            textOverflow: 'ellipsis',
            border: '1px solid #91A2B3',
            borderRadius: '5px',
            fontWeight: 500,
            color: colors.gray[5],
            lineHeight: '18px',
            fontSize: '14px',
          },
        };

        return styles;
      },
    },
    Textarea: {
      styles: () => {
        const styles: Record<string, CSSObject> = {
          input: {
            border: '1px solid #91A2B3',
            borderRadius: '5px',
            fontWeight: 500,
            color: colors.gray[5],
            lineHeight: '18px',
            fontSize: '14px',
          },
        };

        return styles;
      },
    },
    PasswordInput: {
      styles: () => {
        const styles: Record<string, CSSObject> = {
          input: {
            border: '1px solid #91A2B3',
            borderRadius: '5px',
            fontWeight: 500,
            color: colors.gray[5],
            lineHeight: '18px',
            fontSize: '14px',
          },
        };
        return styles;
      },
    },
    Modal: {
      styles: () => {
        const styles: Record<string, CSSObject> = {
          title: {
            color: colors.gray[5],
          },
        };
        return styles;
      },
    },
  },
};

export const supertokensTheme: NormalisedPalette = {
  colors: {
    background: 'white',
    inputBackground: '#fafafa',
    selectedBackground: '#eeeeee',
    primary: colors.accent[6], //change in all button
    success: colors.success[9],
    error: colors.alert[6],
    textTitle: '#2E3A46',
    textLabel: '#2E3A46',
    textInput: '#2E3A46',
    textPrimary: '#656565',
    textLink: colors.accent[6],
    buttonText: 'white',
    superTokensBrandingBackground: 'transparent',
    superTokensBrandingText: 'transparent',
  },
  fonts: {
    size: ['12px', '14px', '16px', '24px', '40px'],
  },
};
