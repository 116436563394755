import { Skeleton } from '@mantine/core';
import style from './Loader.module.scss';

interface skeletonProps {
  numberOfSkeleton: number;
}

export function Loader({ numberOfSkeleton }: skeletonProps) {
  function writeSkeleton() {
    const number = [];
    while (numberOfSkeleton > 0) {
      numberOfSkeleton -= 1;
      number.push(<Skeleton key={numberOfSkeleton} height={38} mb="sm" />);
    }
    return number;
  }
  return (
    <>
      <div className={style.MainDivSkeleton}>
        {writeSkeleton().map((value) => {
          return value;
        })}
      </div>
    </>
  );
}
