import { createContext, useContext, useEffect, useState } from 'react';

import { useClaimValue } from 'supertokens-auth-react/lib/build/recipe/session';
import { EmptyPropsWithChildren } from '../lib/types';
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles';

const RolesContext = createContext<Role[] | null>(null);

export enum Role {
  USER = 'user',
  ADMIN = 'admin',
}

export const RolesProvider = ({ children }: EmptyPropsWithChildren) => {
  const claimValue = useClaimValue(UserRoleClaim);
  const [roles, setRoles] = useState<Role[] | null>(null);
  useEffect(() => {
    let rolesValue: Role[] | null = null;

    if (!claimValue.loading) {
      const { value, doesSessionExist } = claimValue;
      if (doesSessionExist && value !== undefined) rolesValue = value as Role[];
    }

    setRoles(rolesValue);
  }, [claimValue]);

  return (
    <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>
  );
};

export const useRoles = () => {
  const roles = useContext(RolesContext);

  function isAdmin() {
    return roles?.includes(Role.ADMIN);
  }

  return { roles, isAdmin };
};
