import {
  CreateQuoteCustomDto,
  JobOrderTaskApi,
  JobOrderTaskDto,
  QuoteCustomizableApi,
  TicketApi,
  TicketDto,
} from '../api';
import { getTotalTaxablePriceJobOrder } from '../lib/crmUtils';
import { parserJobOrderToQuoteCustom } from '../pages/Tickets/parser/parserJobOrderTask';
import { statusCodeCRMOfTicket } from '../pages/Tickets/parser/parserStatusTicket';
import { statusCodeCRMOfJobOrder } from '../pages/Tickets/parser/parserStatusJobOrderTask';
import { fileNameToDownload } from '../lib/constantCrm';
import { useTranslation } from 'react-i18next';
import { useApiNotification } from './useApiNotification';
import prettyPrintDate from '../lib/utils';

export function useDownloadItemGenerate() {
  const { t } = useTranslation();
  const { showError } = useApiNotification();

  /* -------------------------------------
    CREATE FUNCTION
  ------------------------------------- */
  const createPdf = async (idTicket: string) => {
    const dataTicket: TicketDto = await TicketApi.findOne({ id: idTicket });

    const totalTaxablePriceJobOrder: number = getTotalTaxablePriceJobOrder(
      dataTicket?.jobOrdersTask.map((jobOrder) => jobOrder),
    );
    const request: CreateQuoteCustomDto = {
      translation: {
        headerBusinessRegister: t('BUSINESS_REGISTER'),
        headerTaxCode: t('TAX_CODE'),
        headerShareCap: t('SHARE_CAP'),
        headerPhone: t('TRUNCATED_PHONE'),
        headerServiceLink: t('SERVICE_LINK'),
        topBodyDocumentType: t('DOCUMENT_TYPE'),
        topBodyClientCode: t('CLIENT_CODE'),
        topBodyDocumentDate: t('DOCUMENT_DATE'),
        topBodyDocumentNumber: t('DOCUMENT_NUMBER'),
        bodyHeadedTo: t('HEADED_TO'),
        bodyRespectable: t('RESPECTABLE'),
        bodyTaxCode: t('ALT_TAX_CODE'),
        bodyBillingAddress: t('ADDRESS_TO_BILLADDRESS'),
        bodyShippingAddress: t('ADDRESS_TO_SHIPPINGADDRESS'),
        bodyContact: t('CONTACT_PERSON'),
        bodyPhone: t('phone'),
        bodyPiva: t('vat_label'),
        tableJobOrderNumber: t('N_JOBORDER'),
        tableSerialNumber: t('N_SERIAL'),
        tableModel: t('MODEL'),
        tableJobOrderType: t('JOBORDER_TYPE'),
        tableTotal: t('TOTAL'),
        subTableTotalTaxableIncome: t('TOTAL_TAXABLE_INCOME'),
        subTableTotalDue: t('TOTAL_DUE'),
        footerSdiText: t('PROVIDE_THE_SDI'),
        footerAcceptanceOfGeneralConditions: t('QUOTE_ACCEPTED_ELECTRONICALLY'),
        footerIntroductionArt: t('INTRODUCTION_ART'),
        footerArtFive: t('ART_FIVE'),
        footerArtSix: t('ART_SIX'),
        footerIntroductionTimelines: t('INTRODUCTION_TIMELINES'),
        footerTimelines: t('TIMELINES'),
        footerLinkToFound: t('LINK_TO_FOUND'),
        footerLinkToHT: t('LINK_TO_HT'),
      },
      dateQuoteAccepted: prettyPrintDate(
        String(new Date()),
        t('LANGUAGE_DATE'),
        true,
        true,
      ),
      documentType: t('QUOTE'),
      documentNumber: dataTicket?.ticketId || '',
      documentDate: prettyPrintDate(String(new Date()), t('LANGUAGE_DATE')),
      jobOrder:
        dataTicket?.jobOrdersTask.map((jobOrder) => {
          return parserJobOrderToQuoteCustom(jobOrder);
        }) || [],
      totalTaxable: totalTaxablePriceJobOrder.toFixed(2).toString() + ' €',
      totalWithIva:
        (totalTaxablePriceJobOrder * 0.22).toFixed(2).toString() + ' €',
      totalPrice:
        (totalTaxablePriceJobOrder * (0.22 + 1)).toFixed(2).toString() + ' €',
    };

    const response = await QuoteCustomizableApi.postQuoteCustomizablePdf({
      requestBody: request,
    });
    const downloadLink = document.createElement('a');
    downloadLink.download = fileNameToDownload.TICKET_QUOTE + '.pdf';
    const blob = new Blob([new Uint8Array(response.data)], {
      type: 'application/pdf',
    });
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.click();
    downloadLink.remove();
    return blob;
  };

  const createDownloadItem = async (downloadUrl: string, fileName: string) => {
    const downloadLink = document.createElement('a');
    const extensionFile = downloadUrl.split('.').pop()
      ? '.' + downloadUrl.split('.').pop()
      : '';
    const extensionFilePermitted = ['.pdf', '.jpeg', '.png', '.jpg'];
    if (extensionFilePermitted.includes(extensionFile)) {
      downloadLink.download = fileName + extensionFile;
      downloadLink.href = downloadUrl;
      downloadLink.style.display = 'none';
      downloadLink.target = '_blank';
      downloadLink.rel = 'noopener noreferrer';
      downloadLink.click();
    } else {
      showError(t('INFO_MODAL_BOX_TYPE_FILE_ERROR'));
    }
  };

  /* -------------------------------------
    UPDATE FUNCTION
  ------------------------------------- */
  const updateTicketStatusPostDownloadLabel = (id: string) => {
    const requestBody = {
      status: statusCodeCRMOfTicket.AWAITING_CUSTOMER_ITEM,
    };
    TicketApi.update({
      id: id,
      formData: {
        body: JSON.stringify(requestBody),
      },
    });
  };

  const updateJobOrderStatusPostDownloadLabel = async (
    jobOrders: JobOrderTaskDto[],
  ) => {
    const requestBody = { status: statusCodeCRMOfJobOrder.PENDING_THE_ARRIVAL };
    const requestBodyUpdAllJobOrder = jobOrders.map((jobOrder) => {
      return {
        id: jobOrder.id.toString(),
        ...requestBody,
      };
    });
    await JobOrderTaskApi.updateAllJobOrder({
      requestBody: requestBodyUpdAllJobOrder,
    });
  };

  return {
    createPdf,
    createDownloadItem,
    updateTicketStatusPostDownloadLabel,
    updateJobOrderStatusPostDownloadLabel,
  };
}
