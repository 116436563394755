import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import SuperTokens from 'supertokens-auth-react';

// Are you missing some login translations?
// You can check the fill list here for emailpasswordauth
// https://github.com/supertokens/supertokens-auth-react/blob/master/lib/ts/recipe/emailpassword/components/themes/translations.ts

const options = {
  order: ['path', 'navigator'],
  lookupQuerystring: 'lng',
};

// using HttpBackend, but you can also use local files:
// import commonDe from './locales/de/common.json'
// import commonEn from './locales/en/common.json'
// import commonEs from './locales/es/common.json'
// import commonFr from './locales/fr/common.json'

// const resources = {
//   de: { common: commonDe },
//   en: { common: commonEn },
//   es: { common: commonEs },
//   fr: { common: commonFr },
// };

export const supportedLanguages = ['it', 'en'] as const;
export type SupportedLanguages = (typeof supportedLanguages)[number];

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: 'en' // <--- or choose the default language
    detection: options,
    // resources,
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng: 'en',
    supportedLngs: supportedLanguages,
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: [/*LocalStorageBackend,*/ HttpBackend],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/translation.json',
        },
      ],
    },
    load: 'languageOnly',
  });

i18n.on('languageChanged', (lng) => SuperTokens.changeLanguage(lng));
i18n.on('loaded', () => SuperTokens.loadTranslation({}));

export default i18n;
