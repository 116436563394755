import { t } from 'i18next';
import { JobOrderTaskDto } from '../api';
import { isBlank } from './utils';
import { CreateJobOrderTaskDto } from '../pages/Tickets/Dto/CreateJobOrderTaskDto';
import { AvailableRollingStock } from './constantCrm';
import { OptionObject } from './types';

export function getFilterContents(key: string, value: string) {
  return `substringof('${value}', ${key})`;
}

export function getFilterMatchCatalog(key: string, value: string) {
  return `${key} MATCH ${value}`;
}

export function getFilterEqual(
  key: string,
  value: string | number | boolean | null,
) {
  if (typeof value == 'string') return `${key} eq '${value}'`;
  else if (typeof value == 'number') return `${key} eq ${value}`;
  else if (typeof value == 'boolean') return `${key} eq ${value}`;
  return '';
}

export function getFiltersToProductId(key: string, values: string[]) {
  let filters = '';

  for (const item of values) {
    if (isBlank(filters)) filters = getFilterEqual(key, parseInt(item));
    else filters += ' or ' + getFilterEqual(key, parseInt(item));
  }

  return `(${filters})`;
}

export function getNameFile(urlFile: string) {
  const url = decodeURI(urlFile);
  const fileNameWithGuid = url.split('/').pop() || '';
  const fileName = fileNameWithGuid.slice(fileNameWithGuid.indexOf('|') + 1);

  return fileName;
}

export function getLabelJobOrderType(value: JobOrderTaskDto) {
  const array = [];
  value.FF_Riparazione && array.push(t('INTERVENTION_TYPE1_REPAIR'));
  value.FF_ACCREDIA && array.push(t('INTERVENTION_TYPE2_CALIBRATION'));
  value.FF_Calibrazione &&
    array.push(t('INTERVENTION_TYPE3_CALIBRATION_REPORT'));

  return array.length == 0 ? '-' : array.toString().replace(',', ', ');
}

export function getTotalTaxablePriceJobOrder(
  jobOrders: (CreateJobOrderTaskDto | JobOrderTaskDto)[],
) {
  let totalPrice = 0;
  jobOrders.map((jobOrder) => {
    totalPrice += (jobOrder.FF_Riparazione && jobOrder.FF_HST_Repair) || 0;
    totalPrice += (jobOrder.FF_ACCREDIA && jobOrder.FF_HST_Accredia) || 0;
    totalPrice +=
      (jobOrder.FF_Calibrazione && jobOrder.FF_HST_RDT_Calibra) || 0;
  });
  return totalPrice;
}
export function isAvailableRollingStock(typeOfRollingStock: string) {
  return typeOfRollingStock in AvailableRollingStock;
}

export function getLabelByCode(code: string, list: OptionObject[]) {
  const findItem = list.find((item) => {
    if (item.value === code) return item;
  });

  return findItem?.label || code;
}

export function getCodeFromValue(value: string | undefined) {
  if (!value) return '';
  const infoValue = value.split('-');
  return infoValue[0];
}

export function getLabelFromValue(value: string | undefined) {
  if (!value) return '';
  const infoValue = value.split('-');
  return infoValue[1] ? infoValue[1] : infoValue[0];
}
