import {
  Button,
  createStyles,
  Group,
  Menu,
  MultiSelect,
  Pagination,
  Select,
  TextInput,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import styles from '../../pages/Tickets/Tickets.module.scss';
import { useTranslation } from 'react-i18next';
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import PlusIcon from '../../components/icons/PlusIcon';
import { Card } from '../../components/Card/Card';
import {
  CancelablePromise,
  JobOrderTaskApi,
  JobOrderTaskDto,
  TicketApi,
  TicketResponseDto,
} from '../../api';
import { useSearch } from '../../hooks/useSearch';
import { Header, TableSort } from '../../components/Table/Table';
import {
  formatDate,
  formatHours,
  isBlank,
  isNotBlank,
  isNotRightStateName,
} from '../../lib/utils';
import { ChevronDown, Dots, Search } from 'tabler-icons-react';
import { CreateTicket } from './CreateTicket';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '../../hooks/useNavigationLinks';
import Arrowicon from '../../components/icons/Arrowicon';
import { getStatusJobOrderTask } from './parser/parserStatusJobOrderTask';
import {
  parserStatusFromApiModel,
  statusCodeCRMOfTicket,
} from './parser/parserStatusTicket';
import { TicketStatusBox } from '../../components/TicketStatusBox/TicketStatusBox';
import { getFilterEqual, getLabelJobOrderType } from '../../lib/crmUtils';
import { constants } from '../../constants';
import { useCompany } from '../../hooks/useCompany';
import { useGetTickets } from './useGetTickets';
import { MenuChevronDown } from './MenuChevronDown';
import { InterventionsTypeEnum, TicketStatusEnum } from './TicketEnums';
import { useDownloadItemGenerate } from '../../hooks/useDownloadItemGenerate';

type WithActions<T> = T & {
  actions: '';
  associateId: string;
};

export type Ticket = TicketResponseDto[number];

const useStyles = createStyles((t) => ({
  values: {
    height: 34,
    overflowY: 'auto',
    '::-webkit-scrollbar': {
      width: '0.5em',
    },

    '::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px #ededed',
    },

    '::-webkit-scrollbar-thumb': {
      backgroundColor: t.colors.accent[6],
      opacity: '5',
    },
  },
}));

function useCountStatus(allData: Ticket[] | null, openTicketModal: boolean) {
  const [countManagementTicket, setCountManagementTicket] = useState<number>(0);
  const [countRmaTickets, setCountRmaTickets] = useState<number>(0);
  const [countInProgressTickets, setCountInProgressTickets] =
    useState<number>(0);
  const [statusSuspendedTickets, setSuspendedTickets] = useState<number>(0);

  useEffect(() => {
    if (allData && allData.length > 0 && !openTicketModal) {
      countStatus();
    }
  }, [allData, openTicketModal]);

  async function countStatus() {
    await TicketApi.countStatus().then((response) => {
      setCountManagementTicket(response.countManagementTicket || 0);
      setCountRmaTickets(response.countRmaTickets || 0);
      setCountInProgressTickets(response.countInProgressTickets || 0);
      setSuspendedTickets(response.countSuspendedTickets || 0);
    });
  }

  return {
    countManagementTicket,
    countRmaTickets,
    countInProgressTickets,
    statusSuspendedTickets,
  };
}

export function Tickets() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { company } = useCompany();

  const [haveRollingStocks, setHaveRollingStocks] = useState<boolean>(true);
  const [loadingRollingStocks, setLoadingRollingStocks] =
    useState<boolean>(true);

  const [openTicketModal, setTicketModal] = useState(false);
  const [hideJoborderInfo, setJoborderInfo] = useState<string[]>([]);
  const [retrieveState, setRetrieveState] = useState('');

  const [valueOfId, setValueOfId] = useState<string>();
  // const [filterIntervertionType, setFilterIntervertionType] =
  //   useState<string>('');

  // const {
  //   jobOrderInterventionType,
  //   setJobOrderInterventionType,
  //   search,
  //   setSearch,
  //   searchSerialNumber,
  //   setSearchSerialNumber,
  // } = useSearch(() => findAll(1));

  const {
    allData,
    data,
    findAll,
    page,
    total,
    loading,
    size,
    invalidateCache,
    filter,
    setFilterStatusTicket,
    setJobOrderInterventionType,
    setSearchSerialNumber,
  } = useGetTickets(() =>
    TicketApi.findAll(/*{
         ...pagination,
         jobOrderInterventionType,
         searchSerialNumber,
         search,
      }*/),
  );

  const { filterStatusTicket, jobOrderInterventionType, searchSerialNumber } =
    filter;

  useEffect(() => {
    if (company) setRetrieveState(company.state);
  }, [company]);

  const {
    countManagementTicket,
    countRmaTickets,
    countInProgressTickets,
    statusSuspendedTickets,
  } = useCountStatus(allData, openTicketModal);

  //Open Create Ticket from Instruments
  useEffect(() => {
    if (location.state) {
      setTicketModal(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (openTicketModal) return;
    const getOptions = setTimeout(() => {
      setSearchSerialNumber(valueOfId);
    }, constants.SEARCH_TIMESHOLD_MS);
    return () => clearTimeout(getOptions);
  }, [valueOfId, openTicketModal]);

  const onSearchSeariaNumber = useCallback(
    (valueOfId: string) => {
      setSearchSerialNumber(valueOfId);
    },
    [valueOfId],
  );

  useEffect(() => {
    if (openTicketModal) return;
    const getRollingStockAvailable = async () => {
      return await JobOrderTaskApi.getRollingStockWithoutJobOrder();
    };

    setLoadingRollingStocks(true);
    getRollingStockAvailable()
      .then((rollingStockResponse) => {
        setHaveRollingStocks(rollingStockResponse.length > 0);
        setLoadingRollingStocks(false);
      })
      .catch((e) => {
        setLoadingRollingStocks(false);
        console.log('Error', e);
      });
  }, [openTicketModal]);

  function renderJobOrderType(item: JobOrderTaskDto) {
    const labelJobOrderType = getLabelJobOrderType(item);
    const isCombinationJobOrderType = labelJobOrderType.search(',');

    return isCombinationJobOrderType != -1 ? (
      <Tooltip label={labelJobOrderType} className={styles.Ellipsis}>
        <UnstyledButton fz={14}>{labelJobOrderType}</UnstyledButton>
      </Tooltip>
    ) : (
      labelJobOrderType
    );
  }
  /**************************************************
   * OK
   **************************************************/
  // function prepareFilterAndSend(selectedValue: string | null) {
  //   setFilterIntervertionType(selectedValue || '');
  //   const filter =
  //     isBlank(selectedValue) || !selectedValue
  //       ? ''
  //       : getFilterEqual(selectedValue, true);
  //   setJobOrderInterventionType(filter);
  // }

  // function prepareFilterStatusAndSend(filterData: string[]) {
  //   setFilterStatusTicket(filterData);
  //   let filter = filterData.length > 0 ? '(' : '';
  //   filterData.map((e, index) => {
  //     switch (e) {
  //       case 'OPEN_TICKET': {
  //         filter +=
  //           QueryTicketStatus.OPEN_TICKET_WITH_ALL_STATUS_EXCEPT_SHIPPED_AND_OBSOLETE;
  //         break;
  //       }
  //       case 'WAITING_RMA': {
  //         filter += QueryTicketStatus.WAITING_RMA;
  //         break;
  //       }
  //       case 'GENERATED_RMA': {
  //         filter += QueryTicketStatus.GENERATED_RMA;
  //         break;
  //       }
  //       case 'AWAITING_ARRIVAL': {
  //         filter += QueryTicketStatus.AWAITING_ARRIVAL;
  //         break;
  //       }
  //       case 'IN_PROCESS': {
  //         filter += QueryTicketStatus.IN_PROCESS;
  //         break;
  //       }
  //       case 'WAITING_FOR_PAYMENT': {
  //         filter += QueryTicketStatus.WAITING_FOR_PAYMENT;
  //         break;
  //       }
  //       case 'WAITING_FOR_SHIPMENT': {
  //         filter += QueryTicketStatus.WAITING_FOR_SHIPMENT;
  //         break;
  //       }
  //       case 'SHIPPED': {
  //         filter += QueryTicketStatus.SHIPPED;
  //         break;
  //       }
  //       default: {
  //         filter += QueryTicketStatus.DEFAULT;
  //       }
  //     }
  //     filter += index < filterData.length - 1 ? ' or ' : ')';
  //   });
  //   setSearch(filter);
  // }

  const interventionTypeOptions = [
    {
      value: InterventionsTypeEnum.FF_ACCREDIA,
      label: t('INTERVENTION_TYPE2_CALIBRATION'),
    },
    {
      value: InterventionsTypeEnum.FF_Riparazione,
      label: t('INTERVENTION_TYPE1_REPAIR'),
    },
    {
      value: InterventionsTypeEnum.FF_Calibrazione,
      label: t('INTERVENTION_TYPE3_CALIBRATION_REPORT'),
    },
  ];

  const statusTicket = [
    // {
    //   value: TicketStatusEnum.OPEN_TICKET,
    //   label: t('OPEN_TICKET'),
    // },
    {
      value: TicketStatusEnum.WAITING_RMA,
      label: t('WAITING_RMA'),
    },
    {
      value: TicketStatusEnum.GENERATED_RMA,
      label: t('GENERATED_RMA'),
    },
    {
      value: TicketStatusEnum.AWAITING_ARRIVAL,
      label: t('AWAITING_ARRIVAL'),
    },
    {
      value: TicketStatusEnum.IN_PROCESS,
      label: t('IN_PROCESS'),
    },
    {
      value: TicketStatusEnum.WAITING_FOR_PAYMENT,
      label: t('WAITING_FOR_PAYMENT'),
    },
    {
      value: TicketStatusEnum.WAITING_FOR_SHIPMENT,
      label: t('WAITING_FOR_SHIPMENT'),
    },
    {
      value: TicketStatusEnum.SHIPPED,
      label: t('SHIPPED'),
    },
  ];

  const headers: Header<WithActions<Ticket>>[] = [
    {
      displayName: t('ID_TICKET').toLocaleUpperCase(),
      field: 'ticketId',
      renderCell: (value, field, row) => (
        <p
          onClick={() => navigate(Paths.TICKETDETAIL + '/' + row.id)}
          className={styles.ButtonTicketId}
        >
          {row.ticketId}
        </p>
      ),
    },

    {
      displayName: t('NUMBER_SERIAL').toLocaleUpperCase(),
      field: 'jobOrdersTask',
      renderCell: (value, field, row) => {
        if (row.jobOrdersTask?.length == 1) {
          return row.jobOrdersTask?.[0]['internalIdentification'];
        }
        return;
      },
    },
    {
      displayName: t('MODEL').toLocaleUpperCase(),
      field: 'jobOrdersTask',
      renderCell: (value, field, row) => {
        if (row.jobOrdersTask?.length == 1) {
          return row.jobOrdersTask?.[0]['model'];
        }
        return;
      },
    },

    {
      displayName: t('INTERVENTION_TYPE').toLocaleUpperCase(),
      field: 'jobOrdersTask',
      renderCell: (value, field, row) => {
        if (row.jobOrdersTask?.length == 1) {
          return renderJobOrderType(row.jobOrdersTask?.[0]);
        }
        return;
      },
    },
    {
      displayName: t('STATUS').toLocaleUpperCase(),
      field: 'status',
      renderCell: (value, field, row) => parserStatusFromApiModel(row.status),
    },
    {
      displayName: t('LAST_UPDATED').toLocaleUpperCase(),
      field: 'lastModifiedDate',
      renderCell: (value, field, row) => {
        return (
          formatDate(row.lastModifiedDate) +
          ' - ' +
          formatHours(row.lastModifiedDate)
        );
      },
    },
    {
      displayName: t('ACTIONS').toLocaleUpperCase(),
      field: 'actions',
      renderCell: (value, field, row) => {
        const orderTask =
          row.jobOrdersTask == undefined ? [] : row.jobOrdersTask;

        return (
          <div className={styles.ButtonIconMenu}>
            {orderTask?.length > 1 ? (
              <div className={styles.ActionsContainer}>
                <MenuChevronDown ticket={row} />
                <ChevronDown
                  size={24}
                  strokeWidth={2}
                  color={'black'}
                  className={
                    hideJoborderInfo.indexOf(row.associateId) != -1
                      ? styles.RotateElement
                      : undefined
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    data.forEach((value) => {
                      if (row.id == value.id) {
                        const found = hideJoborderInfo.find(
                          (v) => v == value.ticketId,
                        );
                        if (found == undefined) {
                          hideJoborderInfo.push(row.associateId);
                        } else {
                          const index = hideJoborderInfo.indexOf(
                            row.associateId,
                          );
                          hideJoborderInfo.splice(index, 1);
                        }
                        setJoborderInfo([...hideJoborderInfo]);
                      }
                    });
                  }}
                />
              </div>
            ) : (
              <MenuChevronDown ticket={row} />
            )}
          </div>
        );
      },
      renderNested: (value) => {
        const orderTask =
          value.jobOrdersTask == undefined ? [] : value.jobOrdersTask;
        return (
          <>
            {orderTask.length > 1 &&
              hideJoborderInfo.map(
                (val) =>
                  val == value.associateId &&
                  value.jobOrdersTask?.map((item, i) => {
                    return (
                      <tr
                        className={styles.NestedElements}
                        key={value.ticketId + '_' + i}
                      >
                        <td>
                          <Group position={'apart'}>
                            <Arrowicon /> {item.code}
                          </Group>
                        </td>
                        <td>{item.internalIdentification}</td>
                        <td>{item.model}</td>
                        <td>{renderJobOrderType(item)}</td>
                        <td>{getStatusJobOrderTask(item.status)}</td>
                        <td>
                          {formatDate(item.lastModifiedDate) +
                            '-' +
                            formatHours(item.lastModifiedDate)}
                        </td>
                        <td></td>
                      </tr>
                    );
                  }),
              )}
          </>
        );
      },
    },
  ];

  return (
    <>
      {!openTicketModal ? (
        <Card title={t('my_tickets')}>
          <Button
            className={styles.NewTickets}
            disabled={
              isNotRightStateName(retrieveState) ||
              loadingRollingStocks ||
              !haveRollingStocks
            }
            onClick={() => {
              setTicketModal(true);
            }}
            leftIcon={<PlusIcon />}
          >
            {t('CREATE_TICKET')}
          </Button>
          <>
            <Group position={'apart'} mb={40}>
              <TicketStatusBox
                numberOfCurrentStatus={countManagementTicket}
                statusTitle={t('COUNTER_LABEL_STATUS_SUPPORT')}
              />
              <TicketStatusBox
                numberOfCurrentStatus={countRmaTickets}
                statusTitle={t('COUNTER_LABEL_STATUS_SHIPPING')}
              />
              <TicketStatusBox
                numberOfCurrentStatus={countInProgressTickets}
                statusTitle={t('COUNTER_LABEL_STATUS_WAITING')}
              />
              <TicketStatusBox
                numberOfCurrentStatus={statusSuspendedTickets}
                statusTitle={t('COUNTER_LABEL_STATUS_SUSPENDING')}
              />
            </Group>
            <Group position="apart" mb={'md'} noWrap={true}>
              <div className={styles.FilterOptionLeft}>
                <TextInput
                  className={styles.TextInput}
                  placeholder={t('SEARCH_FOR_SERIAL_NUMBER')}
                  value={valueOfId}
                  onKeyDown={(values: KeyboardEvent<HTMLInputElement>) => {
                    if (values.key == 'Enter') {
                      isNotBlank(values.currentTarget.value)
                        ? setValueOfId(values.currentTarget.value)
                        : setValueOfId('');
                    }
                  }}
                  onChange={(values) => {
                    isBlank(values.currentTarget.value)
                      ? setValueOfId('')
                      : setValueOfId(values.currentTarget.value);
                  }}
                  disabled={loading}
                />
                <UnstyledButton
                  ml={-25}
                  mt={8}
                  h={25}
                  pos={'relative'}
                  onClick={() => {
                    if (!loading) onSearchSeariaNumber(valueOfId || '');
                  }}
                >
                  <Search size={20} strokeWidth={2} color={'gray'} />
                </UnstyledButton>
              </div>
              <Group
                position="right"
                noWrap={true}
                className={styles.FilterOptionRight}
              >
                <Select
                  key={jobOrderInterventionType /*loading ? 1 : 0*/}
                  className={styles.FilterTypeProduct}
                  value={jobOrderInterventionType}
                  searchable
                  initiallyOpened={false}
                  clearable={true}
                  placeholder={t('FILTER_TYPE_TICKETS')}
                  data={interventionTypeOptions}
                  onChange={(e) => setJobOrderInterventionType(e)}
                  disabled={loading}
                />
                <MultiSelect
                  key={loading ? 2 : 3}
                  classNames={classes}
                  className={styles.FilterTypeProduct}
                  placeholder={t('FILTER_STATUS')}
                  value={filterStatusTicket}
                  searchable
                  data={statusTicket}
                  onChange={(e) => setFilterStatusTicket(e)}
                  disabled={loading}
                  clearSearchOnBlur={true}
                />
              </Group>
            </Group>
            <Group grow>
              <TableSort<WithActions<Ticket>>
                loading={loading}
                numberOfSkeleton={size}
                isSorted={false}
                data={data.map((d) => {
                  return {
                    ...d,
                    actions: '',
                    associateId: d.ticketId,
                  };
                })}
                headers={headers}
                tableType="ticket"
              />
            </Group>
            <Group mt={15}>
              <Pagination
                className={styles.Pagination}
                page={page}
                onChange={(p) => findAll(p)}
                total={total}
                disabled={loading}
              />
            </Group>
          </>
        </Card>
      ) : (
        <CreateTicket
          parsePurchaseAttach={location?.state?.purchaseAttach}
          parseRollingStockId={location?.state?.id}
          onClose={() => {
            setTicketModal(false);
            navigate(Paths.TICKETS, {});
            invalidateCache();
          }}
          onSave={() => {
            setTicketModal(false);
            invalidateCache();
          }}
        />
      )}
    </>
  );
}
