import React from 'react';
export default function RectangleMenu() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="13.435"
        width="19"
        height="19"
        transform="rotate(-45 0 13.435)"
        fill="white"
      />
    </svg>
  );
}
