import { Navigate } from 'react-router-dom';
import Session from 'supertokens-auth-react/recipe/session';
import { ReactNode } from 'react';

interface PublicRouteProps {
  children: ReactNode;
}

export function PublicRoute({ children }: PublicRouteProps) {
  const sessionContext = Session.useSessionContext();
  if (sessionContext.loading) {
    return null;
  }

  if (
    sessionContext.doesSessionExist &&
    sessionContext.accessTokenPayload['st-ev'].v
  )
    return <Navigate to="/" replace />;
  else return <>{children}</>;
}
