import { DateTime } from 'luxon';
import i18n from './i18n/i18n';
import { constants } from '../constants';
import { OptionObject } from './types';

// export type UnitOfMeasure = ProductDto['unitOfMeasure'];
export type UnitOfMeasure = string;

const DEFAULT_TIMEZONE = 'Europe/Rome';

export function formatPrice(value: number) {
  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
  }).format(value);
}

export function formatUnitOfMeasure(unitOfMeasure: UnitOfMeasure) {
  return i18n.t(unitOfMeasure);
}

export function isNotBlank(value: string | null | undefined) {
  return typeof value != 'undefined' && value != null && value != '';
}

export function isBlank(value: string | null | undefined) {
  return typeof value == 'undefined' || value == null || value == '';
}

export function formatDate(date: string | Date) {
  const timezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? DEFAULT_TIMEZONE;
  const format = 'dd/MM/yyyy';
  const dateString = typeof date === 'string' ? date : date.toISOString();
  let formatDate = dateString;

  if (DateTime.fromISO(dateString).isValid) {
    formatDate = DateTime.fromISO(dateString, { zone: 'UTC' })
      .setZone(timezone)
      .toFormat(format);
  }

  return formatDate;
}

export function formatHours(date: string | Date) {
  const timezone =
    Intl.DateTimeFormat().resolvedOptions().timeZone ?? DEFAULT_TIMEZONE;

  const format = 'HH:mm';
  const dateString = typeof date === 'string' ? date : date.toISOString();
  let formatHours = dateString;
  if (DateTime.fromISO(dateString).isValid) {
    formatHours = DateTime.fromISO(dateString, { zone: 'UTC' })
      .setZone(timezone)
      .toFormat(format);
  }

  return formatHours;
}

export default function prettyPrintDate(
  dateString: string,
  languageDate: string,
  numeral = false,
  slash = false,
) {
  const date = new Date(dateString);
  const dayNumeric =
    date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const monthNumeric =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const capitalizeFirst = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);
  if (slash) {
    if (numeral) {
      return dayNumeric + '/' + monthNumeric + '/' + date.getFullYear();
    } else {
      return (
        dayNumeric +
        '/' +
        capitalizeFirst(date.toLocaleString(languageDate, { month: 'short' })) +
        '/' +
        date.getFullYear()
      );
    }
  } else {
    if (numeral) {
      return dayNumeric + ' ' + monthNumeric + ' ' + date.getFullYear();
    } else {
      return (
        dayNumeric +
        ' ' +
        capitalizeFirst(date.toLocaleString(languageDate, { month: 'short' })) +
        ' ' +
        date.getFullYear()
      );
    }
  }
}
export function isAlphaNumericCodSdi(codSdi: string) {
  const regex = new RegExp(constants.SDI_REGEX);
  if (codSdi == null) return false;
  return regex.test(codSdi);
}

export function isValidPhone(phone: string) {
  if (phone == null) return false;
  return phone.match(constants.NO_CHARACTER_IN_PHONE_REGEX) ? false : true;
}

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function isNotRightStateName(stateName: string) {
  return (
    isBlank(stateName) ||
    (stateName.toLowerCase() != 'italia' &&
      stateName.toLowerCase() != 'repubblica di san marino' &&
      stateName.toLowerCase() != 'vaticano' &&
      stateName.toLocaleUpperCase() != 'IT' &&
      stateName.toLocaleUpperCase() != 'SM' &&
      stateName.toLocaleUpperCase() != 'VA')
  );
}

export function orderCountry(countries: OptionObject[]) {
  const defaultCountries = ['IT', 'DE', 'FR', 'DK', 'PT'];
  const primaryCountries = [];
  for (const country of countries) {
    if (defaultCountries.includes(country.value)) {
      const indexDefault = defaultCountries.indexOf(country.value);
      primaryCountries[indexDefault] = country;
    }
  }
  const secondaryCountries = countries.filter(
    (country) => !defaultCountries.includes(country.value),
  );
  countries = [...primaryCountries, ...secondaryCountries];

  return countries;
}

export function getState(state: string) {
  if (state.length == 2) return state;
  else {
    if (state.toLowerCase() == 'italia') return 'IT';
    else if (state.toLowerCase() == 'repubblica di san marino') return 'SM';
    else if (state.toLowerCase() == 'vaticano') return 'VA';
  }
}

export function addedCarriageSeparator(
  string: string,
  separatorType: string,
  maxLenght: number,
): string {
  let result = '';
  if (isNotBlank(string))
    string
      .trim()
      .split(/\s+/)
      .map((word) => {
        let lengthWord = word.length;
        let newWord = word;
        while (lengthWord > maxLenght) {
          result += newWord.substring(0, maxLenght - 1);
          result += separatorType + '\n';
          newWord = newWord.substring(maxLenght - 1, word.length);
          lengthWord -= maxLenght - 1;
        }
        result += newWord + ' ';
      });
  return result;
}

export function removeLocaleLanguage(language: string) {
  if (isBlank(language)) return '';
  return language.indexOf('-') === -1
    ? language
    : language.substr(0, language.indexOf('-'));
}
