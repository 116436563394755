import { t } from 'i18next';

export const getStatusJobOrderTask = (key: number) => {
  const status: Record<number, string> = {
    1: t('PENDING_THE_ARRIVAL'),
    14: t('UNDER_PROCESSING'),
    25: t('UNDER_PROCESSING'),
    15: t('UNDER_PROCESSING'),
    24: t('UNDER_PROCESSING'),
    16: t('UNDER_PROCESSING'),
    28: t('UNDER_PROCESSING'),
    17: t('UNDER_PROCESSING'),
    26: t('UNDER_PROCESSING'),
    18: t('UNDER_PROCESSING'),
    22: t('REPAIR_COMPLETED'),
    21: t('REPAIR_COMPLETED'),
    23: t('NOT_REPARABLE'),
    19: t('INTERVENTION_SHIPPED'),
    20: '',
    27: '',
    29: t('NOT_ARRIVED'),
    30: t('OPEN_INTERVENTION'),
  };

  return status[key];
};

export enum statusCodeCRMOfJobOrder {
  //ATTESA ARRIVO
  PENDING_THE_ARRIVAL = 1,
  //SOTTO PROCESSO
  TO_ASSIGN = 14,
  ASSIGNED = 25,
  UNDER_PROCESSING = 15,
  CHECK_UT = 24,
  TO_BE_SEND_IN_FACTORY = 16,
  PENDING_TO_DISPATCH_FACTORY = 28,
  CO_SUPPLIER = 17,
  NO_FACTORY_TO_BE_DONE_SERVICE = 26,
  RECHECKED = 18,
  //RIPARAZIONE COMPLETATA
  TO_REPLACE = 22,
  COMPLETED = 21,
  //NON RIPARABILE
  NOT_REPARABLE = 23,
  //IN SPEDIZIONE
  INTERVENTION_SHIPPED = 19,
  //
  QUOTE_NOT_ACCEPTED = 20,
  WORKED_ON_FULFILL = 27,
  //NON ARRIVATO
  NOT_ARRIVED = 29,
  //APERTURA INTERVENTO
  OPEN_INTERVENTION = 30,
}
